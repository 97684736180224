<template>
  <div class="content" style="padding-top: 0px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <h4 class="card-title">Candidatos</h4>
            </template>
            <div class="col" style="margin: auto;width: 100%;padding: 10px;border: 1px solid #DAA520;">
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="1" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Nome</p>
                </b-col>
                <b-col class="my-1">
                  <b-form-input
                    id="name"
                    v-model="searchName"
                    type="text"
                  ></b-form-input>
                </b-col>
                <b-col lg="1" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Telefone</p>
                </b-col>
                <b-col class="my-1">
                  <b-form-input
                    id="phone"
                    v-model="searchPhone"
                    type="number"
                  ></b-form-input>
                </b-col>
                <b-col lg="1" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Email</p>
                </b-col>
                <b-col class="my-1">
                  <b-form-input
                    id="email"
                    v-model="searchEmail"
                    type="text"
                  ></b-form-input>
                </b-col>
                <b-col lg="1" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Zona</p>
                </b-col>
                <b-col class="my-1">
                  <b-form-select v-model="searchZone" style="cursor: pointer">
                    <b-form-select-option :value="''">Qualquer</b-form-select-option>
                    <b-form-select-option v-for="zone in zones" :key="zone.id" :value="zone.id">{{zone.name}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col>
                  <b-row style="margin-left:0">
                    <div class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Data de Criação</p>
                    </div>
                    <div class="my-1">
                      <p style="text-align: left; padding: 6px 0;margin-right:5px;margin-left:10px">de</p>
                    </div>
                    <div class="my-1"  style="margin-right:10px;">
                      <b-form-datepicker id="searchStartDate" size="sm" :max="this.searchEndDate" button-only v-model="searchStartDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                    </div>
                    <div style="margin:12px">{{searchStartDate}}</div>
                    <div class="my-1">
                      <p style="text-align: left; padding: 6px 0;margin-right:5px;margin-left:10px">a</p>
                    </div>
                    <div class="my-1">
                      <b-form-datepicker id="searchEndDate" right size="sm" :min="this.searchStartDate" button-only v-model="searchEndDate" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                    </div>
                    <div style="margin:12px">{{searchEndDate}}</div>
                  </b-row>
                </b-col>
                <b-col lg="1" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Estado</p>
                </b-col>
                <b-col lg="3" style="padding: 10px;">
                  <b-form-radio-group v-model="searchInProgress" :disabled="searchStatus != ''">
                    <b-form-radio :value="true">Em curso</b-form-radio>
                    <b-form-radio :value="false">Arquivado</b-form-radio>
                  </b-form-radio-group>
                </b-col>
                <b-col lg="3" class="my-1">
                  <b-form-select v-model="searchStatus" style="cursor: pointer">
                    <b-form-select-option :value="''">Qualquer</b-form-select-option>
                    <b-form-select-option value="PENDING">Pendente</b-form-select-option>
                    <b-form-select-option value="SUBMITED">Submetido</b-form-select-option>
                    <!-- <b-form-select-option value="ADITIONALINFOPENDING">Devolvido</b-form-select-option> -->
                    <!-- <b-form-select-option value="EXPIRED">Expirado</b-form-select-option>
                    <b-form-select-option value="ACCEPTED">Aceite</b-form-select-option>
                    <b-form-select-option value="DECLINED">Rejeitado</b-form-select-option> -->
                    <b-form-select-option value="ARCHIVED">Arquivado</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              <div style="margin:10px; display: flex; justify-content: flex-end">
                <b-button size="md" variant="secondary" @click="toogleSearch()">Procurar</b-button>
              </div>
            </div>
            <div class="table-responsive">
              <div>
                <b-table  class="tables"
                  responsive
                  striped
                  bordered
                  outlined
                  hover
                  small
                  thead-class="cm-bg"
                  sort-icon-left
                  no-border-collapse :items="candidateData" :fields="fields" :busy="isBusy"
                  @sort-changed="sortingChanged" no-local-sorting
                  @row-clicked="(item, index, event) => this.info(item)">
                  <template #table-busy>
                    <div class="text-center text-warning my-1">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>A carregar...</strong>
                    </div>
                  </template>
                  <template #cell(phone)="row">
                    <a :href="'tel:351'+row.item.phone?.replace('+351', '').replace(' ', '')" v-if="row.item.phone != '' && row.item.phone != undefined && row.item.phone != null" target="_blank" class="tel" title="Ligar"><i class="fa fa-phone" title="Ligar"></i>{{row.item.phone}}</a>
                  </template>
                  <template #cell(status)="row">
                    <b-row style="padding: 0; margin: 0;"><div :style="'border: 1px solid;height: 15px;border-radius: 45px;background-color:' + row.item.color+ ';width: 15px;'"></div><b-col style="padding: 0; margin: 0;">{{row.item.status}}</b-col></b-row>
                  </template>
                </b-table>
                <b-row>
                  <b-col lg="8" class="my-1">
                  </b-col>
                  <b-col lg="2" class="my-1">
                    <b-form-group
                      label="Por Página"
                      label-for="per-page-select"
                      label-cols-lg="5"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        @change="handlePageSizeChange($event)"
                        align="right"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="2" class="my-1">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="handlePageChange"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
                <b-modal :id="infoModal.id" size="xl" :title="infoModal.title" ok-only ok-variant="default" ok-title="Fechar" @hide="resetInfoModal">
                  <CandidatesForm :key="infoModal.content.id" :candidateId="infoModal.content.id" :isFormPage="false"></CandidatesForm>
                </b-modal>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import { BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BFormSelectOption    } from 'bootstrap-vue'
import SessionDataService from "src/services/SessionDataService"
import CandidatesOCDataService from "src/services/CandidatesOCDataService"
import CandidatesForm from "src/pages/Candidates/CandidatesForm.vue"
import DeliveryAgentsOCDataService from "src/services/DeliveryAgentsOCDataService"
export default {
  components: {
    CandidatesForm,
    Card,
    BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BFormSelect, BPagination, BRow, BFormSelectOption
  },
  mounted () {
    this.getCurrentLoginInfo();
    this.getZonesByUser();
    this.getCandidatesBySearch();
  },
  data() {
    return {
      fields: [
        {
          key: 'status',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center', thClass: 'text-white'
        },
        {
          key: 'fullName',
          label: 'Nome',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        },
        {
          key: 'phone',
          label: 'Telefone',
          sortable: true,
          tdClass: 'text-center', thClass: 'text-white'
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        },
        {
          key: 'creationDate',
          label: 'Data de Criação',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        },
        {
          key: 'updateDate',
          label: 'Data da Ultima Ação',
          sortable: true,
          tdClass: 'text-left', thClass: 'text-white'
        }
      ],
      items: [],
      candidateData: [],
      isBusy: true,
      sortBy: 'estado',
      sortDesc: true,
      sortDirection: 'asc',

      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },

      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, 20, 100],
      currentUser:'',

      zones: [],

      searchName: '',
      searchPhone: '',
      searchEmail: '',
      searchZone: '',
      searchStartDate: '',
      searchEndDate: '',
      searchStatus: '',
      searchInProgress: true
    };
  },
  methods: {
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getZonesByUser(){
      DeliveryAgentsOCDataService.GetDAZonesByUser({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.zones=response.data.result))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    formatData(){
      this.candidateData = [];
      this.items.forEach(element => {
        var status = "";
        var color = "";
        switch(element.status){
          case "PENDING":
            status = "Pendente";
            color = "#ffeb3b";
            break;
          case "ADITIONALINFOPENDING":
            status = "Devolvido";
            color = "#cddc39";
            break;
          case "SUBMITED":
            status = "Submetido";
            color = "#8bc34a";
            break;
          case "EXPIRED":
            status = "Expirado";
            color = "#ff9800";
            break;
          case "ACCEPTED":
            status = "Aceite";
            color = "#00e209";
            break;
          case "DECLINED":
            status = "Rejeitado";
            color = "#ff5722";
            break;
          default:
            status = element.status;
            color = "black";
            break;
        }
        this.candidateData.push({
          element,
          id: element.id,
          status: status,
          color: color,
          fullName: element.fullName,
          phone: element.phone,
          email: element.email,
          creationDate: this.getDateWithHoursFormated(element.creationDate),
          updateDate: element.updateDate ? this.getDateWithHoursFormated(element.updateDate) : '',
        })
      })
    },
    info(candidate) {
      this.infoModal.title = ''
      this.infoModal.content = candidate
      this.$root.$emit('bv::show::modal', this.infoModal.id, '')
    },
    resetInfoModal() {
      this.getCandidatesBySearch()
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getCandidatesBySearch(){
      CandidatesOCDataService.GetCandidatesByZonesAndSearch({pageNumber: this.currentPage, pageSize: this.perPage, sortOrder: this.sortBy, sortDesc: this.sortDesc, searchName: this.searchName, searchPhone: this.searchPhone, searchEmail: this.searchEmail, searchZone: this.searchZone, searchStartDate: this.searchStartDate, searchEndDate: this.searchEndDate, searchStatus: this.searchStatus, inProgress: this.searchInProgress} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.items=response.data.result.items, this.isBusy=false, this.totalRows = response.data.result.totalCount , this.formatData()))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.getCandidatesBySearch();
    },

    handlePageSizeChange(event) {
      this.pageSize = event;
      this.currentPage = 1;
      this.getCandidatesBySearch();
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.getCandidatesBySearch();
    },

    toogleSearch(){
      this.sortBy = 'estado'
      this.sortDesc =  true
      this.sortDirection =  'asc'
      this.filter =  null
      this.filterOn =  'name'
      this.isBusy = true
      this.currentPage =  1
      this.getCandidatesBySearch()
    },

    getDateWithHoursFormated(date){
      var dt = new Date(date)
      var str =
        dt.getDate().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" +
        (dt.getMonth()+1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + "/" + dt.getFullYear() + " " +
        dt.getHours().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) + ":" +
        dt.getMinutes().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      return str
    }
  }
};
</script>
<style>
</style>
