<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright text-center">
        Versão 3.2.10
      </div>
      <!-- <nav>
        <ul class="footer-menu">
          <li>
            <router-link :to="{path:'/admin/sobre'}">Sobre</router-link>
          </li>
        </ul>
      </nav> -->
      <div class="copyright text-center">
        &copy; {{new Date().getFullYear()}} ComerMelhor. All Rights Reserved. Powered By
        <a href="https://www.inovpower.pt/" target="_blank" class="inovpower">InovPower</a>.
      </div>
    </div>
  </footer>
</template>
<script>
export default {}

</script>
<style>
  .inovpower {
    color: orange;
    background-color: transparent;
    text-decoration: none;
  }
  .inovpower:hover {
  color: orange;
  background-color: transparent;
  text-decoration: underline;
  }
</style>
