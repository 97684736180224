<template>
  <div class="content" style="padding-top: 10px; background-color: #f7f7f7;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card>
            <template slot="header">
              <h4 class="card-title">Gestor de Ações</h4>
            </template>
            <div class="col" style="margin: auto;width: 100%;padding: 10px;border: 1px solid #DAA520;">
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="2" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Tipo de Ação</p>
                </b-col>
                <b-col lg="2" class="my-1">
                  <b-form-input id="action" v-model="action" list="actions"></b-form-input>
                  <datalist id="actions">
                      <option v-for="action in actions" :key="action.id" :value="action.name">{{action.name}}</option>
                  </datalist>
                </b-col>
                <b-col lg="2" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Efetuado por</p>
                </b-col>
                <b-col lg="2" class="my-1">
                  <b-form-input id="user" v-model="user" list="users"></b-form-input>
                  <datalist id="users">
                    <option v-for="user in users" :key="user.id" :value="user.userName">{{user.userName}}</option>
                  </datalist>
                </b-col>
                <b-col lg="2" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Estafeta</p>
                </b-col>
                <b-col lg="2" class="my-1">
                  <b-form-input id="esta" v-model="estafeta" list="estafetas"></b-form-input>
                  <datalist id="estafetas">
                      <option v-for="da in das" :key="da.daId" :value="da.userName">{{da.userName}}</option>
                  </datalist>
                </b-col>
              </b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-col lg="2" class="my-1">
                  <p style="text-align: left; padding: 6px 0;">Cliente</p>
                </b-col>
                <b-col lg="2" class="my-1">
                  <b-form-input
                    id="cli"
                    v-model="cliente"
                    type="text"
                  ></b-form-input>
                </b-col>
                <b-col lg="8">
                  <b-row>
                    <b-col lg="3" class="my-1">
                      <p style="text-align: left; padding: 6px 0;">Data da Ação</p>
                    </b-col>
                    <b-col lg="1.5" class="my-1">
                      <p style="text-align: left; padding: 6px 0;margin-right:10px;">de</p>
                    </b-col>
                    <b-col lg="1.5" class="my-1"  style="margin-right:10px;">
                      <b-form-datepicker id="pedidodate1" size="sm" :max="this.pedidodate2" button-only v-model="pedidodate1" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                      {{pedidodate1}}
                    </b-col>
                    <b-col lg="0.5" class="my-1">
                      <p style="text-align: left; padding: 6px 0;margin-right:10px;">a</p>
                    </b-col>
                    <b-col lg="1.5" class="my-1">
                      <b-form-datepicker id="pedidodate2" size="sm" :min="this.pedidodate1" button-only v-model="pedidodate2" class="mb-2" calendar-width="400px" today-button reset-button close-button label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"></b-form-datepicker>
                      {{pedidodate2}}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div style="margin:10px; display: flex; justify-content: flex-end">
                <b-button size="md" variant="secondary" @click="toogleSearch()">Procurar</b-button>
              </div>
            </div>

            <div class="table-responsive" v-if="search">
              <b-table  class="tables"
                responsive
                striped
                bordered
                outlined
                hover
                small
                sort-icon-left
                no-border-collapse
                thead-class="cm-bg"
                id="table"
                :items="actionData"
                :fields="fields"
                :busy="isBusy"
                @row-clicked="(item, event) => seeClienteInfo(item.poNum, event)">
                <template #table-busy>
                  <div class="text-center text-warning my-1">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>A carregar...</strong>
                  </div>
                </template>
                <template #cell(daUserName)="row">
                    {{row.item.daUserName}}{{(row.item.daId != null? " - ": "")}}{{row.item.daId}}
                </template>
                <!-- <template #row-details="row">
                  <b-row>
                    <b-col lg="3">
                    </b-col>
                    <b-col lg="9">
                      {{ row.item.observations }}
                    </b-col>
                  </b-row>
                </template> -->
              </b-table>
              <b-row>
                <b-col lg="9" class="my-1">
                  <b-form-group
                    label-for="per-page-select"
                    label-cols-lg="11"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="handlePageSizeChange($event)"
                      align="right"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="my-1">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="handlePageChange"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
            <b-modal :id="poInfoModal.id" modal-class="modalClass" size="xl" ok-only ok-variant="default" ok-title="Fechar" @hide="resetPOInfoModal">
              <div>
                <b-tabs content-class="mt-3" v-model="tabIndex" justified>
                  <b-tab title="PO">
                    <template #title>
                        <p class="tab">Pedido</p>
                    </template>
                    <div class="row justify-content-center"  v-if="poInfoModal.content != ''" style="margin: auto;width: 90%;padding: 10px;">
                      <b-row style="margin: auto;width: 90%;border: 1px solid #DAA520;">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">PONum</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.id}}
                          <b-button size="sm" variant="secondary" :pressed="seeHistorico" pill style=" margin-left:20px;padding:5px;border-color: white" @click="seeHistoricoInfo(poInfoModal.content.id)" title="Ver/Esconder Histórico de ações" v-if="this.history != ''">
                            <i class="fa fa-hourglass" style="color: #DAA520; font-size: 1.5em;"></i>
                          </b-button>
                          <b-button size="sm" variant="secondary" :pressed="showPOAlerts" pill style="padding:5px;border-color: white;" @click="seePOAlertsInfo(poInfoModal.content.id)" title="Ver/Esconder Alertas do Pedido"  v-if="this.poAlerts != ''">
                            <i class="nc-icon nc-bell-55" style="color: #DAA520; font-size: 1.8em;-webkit-text-stroke: 1px #DAA520;"></i>
                          </b-button>
                        </strong>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Estado</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <i v-if="poInfoModal.content.estadoState == 8" class="nc-icon nc-check-2" style="color: lightgreen;  font-size: 1.5em;  font-weight: bold;" title="Finalizada"></i>
                          <i v-if="poInfoModal.content.estadoState == 9" class="nc-icon nc-simple-remove" style="color: red;  font-size: 1.5em;  font-weight: bold;" title="Cancelada"></i>
                          <i v-if="poInfoModal.content.estadoState == 1" class="fa fa-phone" style="color: black;  font-size: 1.5em;  font-weight: bold;" title="A tocar no Estafeta"></i>
                          <i v-if="poInfoModal.content.estadoState == 2" class="nc-icon nc-send" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="Á deriva há"><i class="nc-icon nc-circle-09" style="color: black;  font-size: 1.0em;  font-weight: bold;"></i></i>
                          <i v-if="poInfoModal.content.estadoState == 3" class="nc-icon nc-send" style="color: #DAA520;  font-size: 1.5em; font-weight: bold;" title="Envio em"></i>
                          <i v-if="poInfoModal.content.estadoState == 4" class="nc-icon nc-delivery-fast" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="A caminho do Restaurante há"><i class="nc-icon nc-bank" style="color: black;  font-size: 1.0em;  font-weight: bold;"></i></i>
                          <i v-if="poInfoModal.content.estadoState == 5" class="nc-icon nc-bank" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="No Restaurante há"></i>
                          <i v-if="poInfoModal.content.estadoState == 6" class="nc-icon nc-delivery-fast" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="A caminho do Cliente há"><i class="nc-icon nc-single-02" style="color: black;  font-size: 1.0em;  font-weight: bold;"></i></i>
                          <i v-if="poInfoModal.content.estadoState == 7" class="nc-icon nc-circle-09" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="No Cliente há"></i>
                          <strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.estado}}</strong>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 90%;border: 1px solid #DAA520;">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Estafeta</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <a :href="'tel:351'+poInfoModal.content.estafetaPhone?.replace('+351', '').replace(' ', '')" v-if="poInfoModal.content.userNameEstafeta != ''" target="_blank" class="tel"  title="Ligar"><i class="fa fa-phone" title="Ligar"></i><strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.userNameEstafeta}}</strong></a>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0;font-size: 12px;">Restaurante <i class="fa fa-phone" style="cursor: pointer" :title="'Chamadas Automáticas Efetuadas - '+existsCall" v-if="existsCall != ''"></i></p>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <b-popover target="multiphones" triggers="click" placement="bottom">
                            <div class="text-center" v-if="!showOtherPhones">
                              <b-spinner label="Large Spinner" variant="warning"></b-spinner>
                            </div>
                            <div v-if="showOtherPhones">
                              <div v-for="phone in otherPhones" :key="phone.id">
                                <a :href="'tel:351'+phone.phoneNumber?.replace('+351', '').replace(' ', '')" target="_blank" class="tel" title="Ligar"><i class="fa fa-phone" title="Ligar"></i><strong style="text-align: left; font-size: 12px;">{{phone.personTitle}}</strong></a><i class="nc-icon nc-notification-70 complaintIcon" style="font-size: 12px; font-weight: bold; float:right;padding-top:6px;margin-left: 10px" title="Realizar uma chamada automática para o Parceiro" @click="sendAutoCallToPartnerPhone(poInfoModal.content.element.supplierId, phone.phoneNumber)" v-if="!doingCall && !phone.mainContact"></i><i class="fa fa-star" style="color: #DAA520" title="Principal" v-if="phone.mainContact"></i>
                              </div>
                            </div>
                          </b-popover>
                          <a :href="'tel:351'+poInfoModal.content.supplierPhone?.replace('+351', '').replace(' ', '')" v-if="poInfoModal.content.supplierName != ''" target="_blank" class="tel" title="Ligar"><i class="fa fa-phone" title="Ligar"></i><strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.supplierName}}</strong></a>
                        </b-col>
                        <b-col lg="1" class="my-1">
                          <i class="fa fa-phone complaintIcon" title="Outros Telefones" style="font-size:12px;position:absolute;transform: rotate(135deg);padding:5px" id="multiphones" @click="otherPhonesClicked(poInfoModal.content.element.supplierId)"><i class="fa fa-phone" style="position:absolute;left:5px;bottom:5px;padding:5px"></i></i><i class="nc-icon nc-notification-70 complaintIcon" style="font-size: 12px; font-weight: bold; float:right;padding-top:8px" title="Realizar uma chamada automática para o Parceiro" @click="sendAutoCallToPartner(poInfoModal.content.element.supplierId)" v-if="!doingCall"></i>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 90%;border: 1px solid #DAA520;">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Cliente</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <a :href="'tel:351'+poInfoModal.content.element.customerPhone?.replace('+351', '').replace(' ', '')" v-if="poInfoModal.content.element.deliveryCustomerName != ''" target="_blank" class="tel" title="Ligar"><i class="fa fa-phone" title="Ligar"></i><strong style="text-align: right; font-size: 12px;">{{poInfoModal.content.element.deliveryCustomerName}}</strong></a><i title="Pedido realizado pelo Contact-Center" class="nc-icon nc-single-02" style="color: black; font-size: 1.5em; font-weight: bold; float:right;position: relative;margin-top:10px" v-if="poInfoModal.content.element.source == 'CONTACT_CENTER'"><i title="Pedido realizado pelo Contact-Center" class="nc-icon nc-headphones-2" style="color: black; font-size: 0.8em; font-weight: bold;position: absolute;top: -25%;left:10%;"></i></i>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Data Criação</p>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <strong style="text-align: right; font-size: 12px;">{{poInfoModal.content.poDateTime}}</strong>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 90%;border: 1px solid #DAA520;">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Data/Hora Levant</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.datedeliveryTimeWindowStart1}}</strong>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Hora Entrega</p>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <i class="fa fa-bolt" title="Entregar o mais rápido possivel" style="color: red; font-size: 16px" v-if="poInfoModal.content.element.deliverAsap"></i><strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.deliveryTimeWindowEnd1}}</strong>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 90%;border: 1px solid #DAA520;">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px; margin-right: 10px">Valor <i class="fa fa-percent voucherIcon" style="color: white; border-radius: 50%;padding:5px;font-size: 8px" title="Ver Voucher" @click="voucherInfo(voucher, '', '')" v-if="voucher != '' && voucher != null"></i></p>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <a @click="extratoInfo(poInfoModal.content, poInfoModal.id, $event.target)" class="nameline" title="Ver Extrato/Pagamentos" v-if="hasPermissions('Pages.POs.Extrato')">
                            <strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.orderValue}}</strong>
                          </a>
                          <p v-if="!hasPermissions('Pages.POs.Extrato')">
                            <strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.orderValue}}</strong>
                          </p>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <i class="nc-icon nc-credit-card complaintIcon" style="font-size: 1.8em;" title="Ativar Crédito" @click="ativarCreditoInfo(poInfoModal.content, poInfoModal.id, $event.target)"  v-if="poInfoModal.content.element.isCancelled == false && poInfoModal.content.element.isDelivered == false && (poInfoModal.content.element.amountDue > 0 || this.onlinePaymentsValue > 0) && this.currentCreditAvailable > 0 && hasPermissions('Pages.POs.Credito')"></i>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Valor em Divida</p>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.amountDue}}</strong>
                          <i class="fa fa-plus-square complaintIcon" style="font-size: 1.0em; margin: 5px" title="Adicionar Valor em Divida" @click="addAmountDueInfo(poInfoModal.content, poInfoModal.id, $event.target)"  v-if="poInfoModal.content.element.isCancelled == false && poInfoModal.content.element.isDelivered == false && hasPermissions('Pages.POs.AddAmountDue')"></i>
                        </b-col>
                        <b-col lg="2" class="my-1" v-if="this.currentAmountDue > 0 && !poInfoModal.content.element.isDelivered && !poInfoModal.content.element.isCancelled && hasPermissions('Pages.POs.PagamentoMBWAY')">
                          <b-button size="sm" variant="secondary" title="Pedir Pagamento" style="padding:0" @click="mbwayInfo(poInfoModal.content, poInfoModal.id, $event.target)">
                            <img src="img/MBWay.png" style="width:auto;height:22px;">
                          </b-button>
                        </b-col>
                        <b-col lg="2" class="my-1" v-if="poInfoModal.content.element.isDelivered">
                          <DebtComponent :poNum="poInfoModal.content.id" :phone="poInfoModal.content.element.customerPhone"></DebtComponent>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 90%;border: 1px solid #DAA520;">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Distância</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <strong style="text-align: left; font-size: 12px;">{{poInfoModal.content.deliveryDistance}}</strong>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0; font-size: 12px;">Cód. Autorização</p>
                        </b-col>
                        <b-col lg="4" class="my-1" v-if="hasPermissions('Pages.POs.CodigoAut') && !poInfoModal.content.element.isDelivered && !poInfoModal.content.element.isCancelled">
                          <b-popover target="aut-1" triggers="hover" placement="right" v-if="poInfoModal.content.element.isAssignedToDa == true">
                            <template #title>Ver Código de Autorização</template>
                          </b-popover>
                          <div v-if="poInfoModal.content.element.isAssignedToDa == true && seeCode == false">
                            <b-button size="xs" variant="secondary" class="acaobutton" @click="codautInfo(poInfoModal.content, poInfoModal.id, $event.target)" id="aut-1">
                              <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                                <div class="font-icon-detail">
                                  <i class="nc-icon nc-key-25" style="color: #DAA520; font-size: 1.5em;"></i>
                                </div>
                              </div>
                            </b-button>
                          </div>
                          <!-- <strong style="text-align: left; font-size: 12px;" v-if="seeCode" >{{poInfoModal.content.authorizationCode}}</strong> -->
                        </b-col>
                        <b-modal :id="codautInfoModal.id" :title="codautInfoModal.title" :ok-disabled="codaut == '' || codauttext == ''" ok-variant="success" ok-title="Submeter" cancel-title="Cancelar" @ok="toogleSeeCode(codautInfoModal.content,codautInfoModal.content.id)" @hide="resetCodautInfoModal">
                          <b-form-select v-model="codaut" class="a">
                            <b-form-select-option disabled value="">Indique a Razão</b-form-select-option>
                            <b-form-select-option value="O cliente pagou em numerário.">O cliente pagou em numerário.</b-form-select-option>
                            <b-form-select-option value="O estafeta não consegue fechar o pedido.">O estafeta não consegue fechar o pedido.</b-form-select-option>
                            <b-form-select-option value="O Cliente vai pagar no próximo pedido.">O Cliente vai pagar no próximo pedido.</b-form-select-option>
                          </b-form-select>
                          <b-row style="margin-left:5px">Justificação<p style="color: red;font-size: 16px">*</p></b-row>
                          <b-form-textarea
                            id="cod-aut-text"
                            v-model="codauttext"
                            placeholder="Justifique a razão.."
                            required
                          ></b-form-textarea>
                        </b-modal>
                      </b-row>
                      <b-row style="margin: auto;width: 90%;border: 1px solid #DAA520;">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0;font-size: 12px;">Obs. Estafeta</p>
                        </b-col>
                        <b-col lg="9" class="my-1">
                          <p style="white-space: pre-wrap; text-align: left; font-size: 12px;">{{poInfoModal.content.element.obsForDelivery}}</p>
                        </b-col>
                        <b-col lg="1" class="my-1">
                          <div style="float:right" v-if="!poInfoModal.content.element.isDelivered && !poInfoModal.content.element.isCancelled && hasPermissions('Pages.POs.EditarObsDA')">
                            <i class="fa fa-edit edit" title="Editar" @click="obsDAInfo(poInfoModal.content, poInfoModal.id, $event.target)"></i>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 90%;border: 1px solid red;" v-if="poInfoModal.content.estadoState == 9 && history.filter(p => p.acao.id == 3)[0] != null">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0;font-size: 12px;">Cancelado Por</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <strong style="text-align: left; font-size: 12px;">{{history.filter(p => p.acao.id == 3)[0].user.userName}}</strong>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0;font-size: 12px;">Data</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <strong style="text-align: left; font-size: 12px;">{{history.filter(p => p.acao.id == 3)[0].logDateTime}}</strong>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 90%;border: 1px solid red;" v-if="poInfoModal.content.estadoState == 9 && history.filter(p => p.acao.id == 3)[0] != null">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0;font-size: 12px;">Motivo</p>
                        </b-col>
                        <b-col lg="10" class="my-1">
                          <p style="text-align: left; font-size: 12px;">{{history.filter(p => p.acao.id == 3)[0].observations}}</p>
                        </b-col>
                      </b-row>
                      <b-row v-if="poInfoModal.content.steps != ''">
                        <div class="drop-zone" style="width: auto;height:auto;padding: 5px;background-color: white; margin:10px" v-if="poInfoModal.content.steps != ''">
                          <draggable v-model="listDone" :move="checkMove" @change="changeSteps(poInfoModal.content)">
                            <transition-group class="row">
                              <div v-for="element in listDone" :key="element.step.Key"  class="drag-el col" style="width: auto; text-align: center; background-color: white; margin: 0; margin-bottom: 0;">
                                <div v-if="(element.step.Type == 'AcceptAssignment' || element.step.Type == 0) && element.step.IsCompleted == true">
                                  <img src="img/cm_motinhas.png" style="width:130px;height:130px;" title="'O Excelências'" v-if="listDone[1].estado =='default'">
                                  <i class="nc-icon nc-button-play" style="color: green; font-size: 3.2em;padding-top:25px" v-if="listDone[1].estado !='default'"></i>
                                  <h1 style="color: black;font-size: 10px;text-align:center"><i class="fa fa-share" style="color: green;font-size: 0.5em; "></i> {{poInfoModal.content.assignedDateTime}}</h1>
                                </div>
                                <div style="width: auto; height: 100%; text-align: center; background-color: white;border: 1px solid gray; margin: 0; margin-bottom: 0;padding:10px;" v-if="element.estado == 'default'" id="dates-1">
                                  <i class="nc-icon nc-bank" style="color: black; font-size: 3.2em;" v-if="element.step.Type == 'GotoPickupLocation' || element.step.Type == 1"></i>
                                  <i class="nc-icon nc-single-02" style="color: black; font-size: 3.2em;" v-if="element.step.Type == 'GotoDeliveryLocation' || element.step.Type == 3"></i>
                                  <p class="restPhone"  v-if="element.step.Type == 'GotoPickupLocation' || element.step.Type == 1" v-b-popover.hover.top="{html:true, content: popoverRest(element.restaurantePhone)}">{{element.restaurante}}</p>
                                  <div v-for="ponum in element.step.PONums" :key="ponum">
                                    <p style="text-align: center; font-size: 12px; color: black"  v-if="ponum != poInfoModal.content.id">
                                      <a @click="seeClienteInfo(ponum, $event.target)" class="nameline">
                                        {{ponum}}
                                      </a>
                                    </p>
                                    <p style="text-align: center; font-size: 16px; color: #DAA520; font-weight:bold"  v-if="ponum == poInfoModal.content.id">
                                      <a @click="seeClienteInfo(ponum, $event.target)" class="nameline" style="font-weight:bold">
                                        {{ponum}}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div style="width: auto; height: 100%; text-align: center; background-color: white;border: 1px solid #DAA520; margin: 0; margin-bottom: 0;padding:10px;" v-if="element.estado == 'doing'">
                                  <img src="img/cm_motinhas_frente.png" style="width:65px;height:65px;position:absolute;top:-20px;right:-20px;" title="Estafeta está aqui!">
                                  <i class="nc-icon nc-bank" style="color: #DAA520; font-size: 3.2em;" v-if="element.step.Type == 'GotoPickupLocation' || element.step.Type == 1"></i>
                                  <i class="nc-icon nc-single-02" style="color: #DAA520; font-size: 3.2em;" v-if="element.step.Type == 'GotoDeliveryLocation' || element.step.Type == 3"></i>
                                  <p class="restPhone"  v-if="element.step.Type == 'GotoPickupLocation' || element.step.Type == 1" v-b-popover.hover.top="{html:true, content: popoverRest(element.restaurantePhone)}">{{element.restaurante}}</p>
                                  <div v-for="ponum in element.step.PONums" :key="ponum">
                                    <p style="text-align: center; font-size: 12px; color: black"  v-if="ponum != poInfoModal.content.id">
                                      <a @click="seeClienteInfo(ponum, $event.target)" class="nameline">
                                        {{ponum}}
                                      </a>
                                    </p>
                                    <p style="text-align: center; font-size: 16px; color: #DAA520; font-weight:bold"  v-if="ponum == poInfoModal.content.id">
                                      <a @click="seeClienteInfo(ponum, $event.target)" class="nameline"  style="font-weight:bold">
                                        {{ponum}}
                                      </a>
                                    </p>
                                  </div>
                                  <div>
                                    <p style="color: black;font-size: 10px;"><i class="fa fa-share" style="color: green;font-size: 0.5em; "></i> {{element.horaChegada}}</p>
                                    <p style="color: black;font-size: 10px;" v-if="element.horaSaida != ''">{{element.horaSaida}} <i class="fa fa-share" style="color: red;font-size: 0.5em; "></i></p>
                                  </div>
                                </div>
                                <div style="width: auto; height: 100%; text-align: center; background-color: white;border: 1px solid green; margin: 0; margin-bottom: 0;padding:10px;"  v-if="element.estado == 'done' && (element.step.Type != 'AcceptAssignment'  && element.step.Type != 0)">
                                  <i class="nc-icon nc-bank" style="color: green; font-size: 3.2em;" v-if="element.step.Type == 'GotoPickupLocation' || element.step.Type == 1"></i>
                                  <i class="nc-icon nc-single-02" style="color: green; font-size: 3.2em;" v-if="element.step.Type == 'GotoDeliveryLocation' || element.step.Type == 3"></i>
                                  <p class="restPhone"  v-if="element.step.Type == 'GotoPickupLocation' || element.step.Type == 1" v-b-popover.hover.top="{html:true, content: popoverRest(element.restaurantePhone)}">{{element.restaurante}}</p>
                                  <div v-for="ponum in element.step.PONums" :key="ponum">
                                    <p style="text-align: center; font-size: 12px; color: black"  v-if="ponum != poInfoModal.content.id">
                                      <a @click="seeClienteInfo(ponum, $event.target)" class="nameline">
                                        {{ponum}}
                                      </a>
                                    </p>
                                    <p style="text-align: center; font-size: 16px; color: #DAA520; font-weight:bold"  v-if="ponum == poInfoModal.content.id">
                                      <a @click="seeClienteInfo(ponum, $event.target)" class="nameline"  style="font-weight:bold">
                                        {{ponum}}
                                      </a>
                                    </p>
                                  </div>
                                  <div>
                                    <p style="color: black;font-size: 10px;"><i class="fa fa-share" style="color: green;font-size: 0.5em; "></i> {{element.horaChegada}}</p>
                                    <p style="color: black;font-size: 10px;" v-if="element.horaSaida != ''">{{element.horaSaida}} <i class="fa fa-share" style="color: red;font-size: 0.5em; "></i></p>
                                  </div>
                                </div>
                              </div>
                            </transition-group>
                          </draggable>
                        </div>
                        <div  v-if="poInfoModal.content.steps != ''">
                          <b-row style="margin:10px">
                            <div>
                              <p style="font-size:12px;margin-top:10px">Distância: <strong title="Distância a percorrer" style="cursor: help">{{poInfoModal.content.totalDistance}}</strong> km</p>
                            </div>
                            <div>
                              <a :href="mapsLink" target="_blank">
                                <b-button size="sm" variant="secondary" pill style="padding:5px; padding-bottom:2px; border-color: white" title="Ver Mapa">
                                  <i class="nc-icon nc-pin-3" style="color: red; font-size: 1.5em; font-weight:bold;"></i>
                                </b-button>
                              </a>
                            </div>
                            <div v-if="this.sumDistances != ''">
                              <p style="font-size:12px;margin-top:10px"> | <strong title="Distância em separado" style="cursor: help">{{this.sumDistances}}</strong> km</p>
                            </div>
                          </b-row>
                          <b-row style="margin:10px">
                            <div>
                              <p style="font-size:12px;margin-top:10px">Localização Estafeta: <strong>{{poInfoModal.content.daLocationDate.split(" ")[1]}}</strong></p>
                            </div>
                            <div>
                              <a :href="leftMapsLink" target="_blank">
                                <b-button size="sm" variant="secondary" pill style="padding:5px; padding-bottom:2px;border-color: white" title="Ver Mapa com Localização do Estafeta">
                                  <i class="nc-icon nc-pin-3" style="color: black; font-size: 1.5em; font-weight:bold;"></i>
                                </b-button>
                              </a>
                              <i class="fa fa-exclamation-triangle" style="color: red; font-size: 1.5em; font-weight:bold;" title="Desatualizada"  v-if="this.daInTransit && poInfoModal.content.element.isDelivered == false && poInfoModal.content.element.isCancelled == false"></i>
                            </div>
                          </b-row>
                          <b-button size="md" variant="secondary" style="margin:10px; color: #DAA520; border-color:#DAA520; font-weight: bold;" v-if="(JSON.stringify(this.listDoneInitial) !== JSON.stringify(this.listDone)) && this.validateSteps(this.listDone) && hasPermissions('Pages.POs.AlterarOrdem')" @click="alterarOdensInfo(poInfoModal.content, listDone, poInfoModal.id, $event.target)">Alterar
                          </b-button>
                        </div>
                      </b-row>
                      <div class="row justify-content-center"  v-if="poInfoModal.content != ''" style="width: 100%">
                        <div style="margin:10px" v-if="poInfoModal.content.element.isCancelled == false && poInfoModal.content.element.isDelivered == false && poInfoModal.content.element.isPickedup == false && poInfoModal.content.element.assignment != null && hasPermissions('Pages.POs.RetirarDA')">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="retirarInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Retirar Pedido">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-simple-delete" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="poInfoModal.content.element.isCancelled == false && poInfoModal.content.element.isDelivered == false">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="cancelarInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Cancelar Pedido" v-if="poInfoModal.content.element.supplierId != 43074 && hasPermissions('Pages.POs.Cancelar')">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-simple-remove" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                          <b-button size="md" variant="secondary" class="acaobutton" @click="cancelarGiftCardInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Fechar Cartão Presente" v-if="poInfoModal.content.element.supplierId == 43074 && hasPermissions('Pages.POs.CancelarGC')">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-simple-remove" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="poInfoModal.content.element.isCancelled == true && poInfoModal.content.element.isDelivered == false && (this.tod.getTime() == new Date(new Date(poInfoModal.content.element.deliveryTimeWindowStart).setHours(0,0,0,0)).getTime()) && hasPermissions('Pages.POs.ReAtivar')">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="reAtivarInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="ReAtivar Pedido">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-circle" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="poInfoModal.content.element.isPickedup == true && poInfoModal.content.element.isCancelled == false && poInfoModal.content.element.isDelivered == false && hasPermissions('Pages.POs.Fechar')">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="fecharInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Fechar Pedido">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-lock-circle-open" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="(poInfoModal.content.element.isPickedup == true && poInfoModal.content.element.daArrivedAtDeliveryLocationDateTime != null) && hasPermissions('Pages.POs.Reclamacao') && (this.tod.getTime() == new Date(new Date(poInfoModal.content.element.deliveryTimeWindowStart).setHours(0,0,0,0)).getTime() || this.yesterday.getTime() == new Date(new Date(poInfoModal.content.element.deliveryTimeWindowStart).setHours(0,0,0,0)).getTime() || this.currentUserRoles.includes('SUPERUSER'))">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="reclamacaoInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Reclamação">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-paper-2" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="poInfoModal.content.element.isAssignedToDa == false && poInfoModal.content.element.isDelivered == false  && poInfoModal.content.element.isCancelled == false && hasPermissions('Pages.POs.AlterarHorario')">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="alterarTimeWindowInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Alterar Horário">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-time-alarm" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="poInfoModal.content.element.isAssignedToDa == false && poInfoModal.content.element.isCancelled == false && poInfoModal.content.element.isDelivered == false && hasPermissions('Pages.POs.AtribuirDA')">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="atribuicaoMaisInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Atribuir Pedido">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-delivery-fast" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="poInfoModal.content.element.isAssignedToDa == true && poInfoModal.content.element.isDelivered == false && poInfoModal.content.element.isCancelled == false && hasPermissions('Pages.POs.AlterarDA')">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="alterarEstafetaInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Alterar Estafeta">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-refresh-02" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="poInfoModal.content.element.isCancelled == false && poInfoModal.content.element.isAssignedToDa == true && hasPermissions('Pages.POs.AddKm') && (this.tod.getTime() == new Date(new Date(poInfoModal.content.element.deliveryTimeWindowStart).setHours(0,0,0,0)).getTime() || this.yesterday.getTime() == new Date(new Date(poInfoModal.content.element.deliveryTimeWindowStart).setHours(0,0,0,0)).getTime())">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="adicionarKmsInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Ajustar Kms">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-square-pin" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px" v-if="poInfoModal.content.element.isDelivered == false && poInfoModal.content.element.isCancelled == false && hasPermissions('Pages.POs.TxEntrega') && (this.onlinePaymentsValue > 0 || poInfoModal.content.element.amountDue > 0) && this.currentDeliveryFee > 0">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="ajustarDeliveryFeeInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Ajustar Taxa de Entrega">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-tag-content" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                      </div>
                      <StaffPOObservations :poNum="poInfoModal.content.id"></StaffPOObservations>
                      <div class="table-responsive" v-if="this.seeHistorico">
                        <b-table  class="tables"
                          responsive
                          striped
                          bordered
                          outlined
                          hover
                          small
                          no-border-collapse
                          thead-class="cm-bg"
                          id="historyTeable"
                          :items="history"
                          :fields="histFields"
                          v-if="this.history != ''">
                          <template #table-colgroup="scope">
                            <col
                              v-for="field in scope.fields"
                              :key="field.key"
                              :style="{ width: field.key === 'foo' ? '20%' : '20%' }"
                            >
                          </template>
                          <template #row-details="row">
                            <b-row>
                              <b-col lg="3">
                              </b-col>
                              <b-col lg="9">
                                {{ row.item.observations }}
                              </b-col>
                            </b-row>
                          </template>
                        </b-table>
                      </div>
                      <div class="table-responsive" v-if="this.showPOAlerts">
                        <b-table  class="tables"
                          responsive
                          striped
                          bordered
                          outlined
                          hover
                          small
                          no-border-collapse
                          thead-class="cm-bg"
                          id="poAlertsTable"
                          :items="poAlerts"
                          :fields="poAlertsFields"
                          @row-clicked="(item, index, event) => alertDescriptionInfo(item, poInfoModal.content, index, event)">
                          <template #table-colgroup="scope">
                            <col
                              v-for="field in scope.fields"
                              :key="field.key"
                              :style="{ width: field.key === 'foo' ? '25%' : '25%' }"
                            >
                          </template>
                          <template #cell(poNum)="row">
                            <a @click="seeClienteInfo(row.item.poNum, $event.target)" class="nameline">
                              {{row.item.poNum}}
                            </a>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Cliente" @click="clienteTabClicked(poInfoModal.content.element.customerEmail, poInfoModal.content.element.customerPhone)">
                    <template #title>
                      <p class="tab">Cliente</p>
                    </template>
                    <div class="col"  v-if="poInfoModal.content != ''" style="margin: auto;width: 60%;padding: 10px;">
                      <b-row style="margin: auto;width: 100%;padding: 10px;">
                        <b-col lg="5" class="my-1">
                          <i class="nc-icon nc-circle-09" style="color: #DAA520; font-size: 8.2em;"></i>
                        </b-col>
                        <b-col lg="6" class="my-1">
                          <p style="font-size: 20px; padding: 50px 6px; font-weight: bold;">{{poInfoModal.content.element.deliveryCustomerName}}</p>
                        </b-col>
                      </b-row>
                      <b-col lg="4" style="float:right; padding-right: 0">
                        <h6 style="float:right; margin-left:20px" v-if="this.currentNumPedidos != ''">Nº Pedidos: <b-badge class="numPedidosBadge" pill variant="warning" @click="openPedidosByCliente(poInfoModal.content.element.customerEmail, poInfoModal.content.element.customerPhone)">{{this.currentNumPedidos}}</b-badge></h6>
                        <h6 style="float:right;margin-right: 5px; margin-left: 50px;" v-if="this.avgOrderValue != ''">x̄: {{this.avgOrderValue}} €</h6>
                      </b-col>
                      <h6 style="float:left;margin-top:10px"><i class="nc-icon nc-layers-3 complaintIcon" title="Reclamações" @click="openComplaintsByCliente(poInfoModal.content.element.customerEmail, poInfoModal.content.element.customerPhone)"  v-if="this.complaintCliente != ''"></i></h6>
                      <h6 style="float:left;margin-top:10px"><i class="nc-icon nc-bell-55 complaintIcon" title="Alertas" @click="openAlertsByCliente(poInfoModal.content.element.customerPhone)"  v-if="this.alertCliente != ''"></i></h6>
                      <h6 style="float:left;margin-top:10px"><i class="nc-icon nc-email-83 complaintIcon" title="Mensagens SMS" @click="openSMSMessagesByCliente(poInfoModal.content.element.customerPhone)" v-if="this.smsMessagesCliente != ''"></i></h6>
                      <b-row style="margin: auto;width: 100%;border: 1px solid #DAA520;padding: 10px;">
                        <b-col lg="3" class="my-1">
                          <p style="text-align: right; padding: 6px 0; font-size: 12px;">Telefone</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <a :href="'tel:351'+poInfoModal.content.element.customerPhone?.replace('+351', '').replace(' ', '')" v-if="poInfoModal.content.customerPhone != ''" target="_blank" class="tel" title="Ligar"><strong style="text-align: right; font-size: 12px;"><i class="fa fa-phone" title="Ligar"></i> {{poInfoModal.content.customerPhone}}</strong></a>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 100%;border: 1px solid #DAA520;padding: 10px;">
                        <b-col lg="3" class="my-1">
                          <p style="text-align: right; padding: 6px 0; font-size: 12px;">Email</p>
                        </b-col>
                        <b-col lg="4" class="my-1">
                          <strong style="text-align: right; font-size: 12px;">{{poInfoModal.content.element.customerEmail}}</strong>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 100%;border: 1px solid #DAA520;padding: 10px;">
                        <b-col lg="3" class="my-1">
                          <p style="text-align: right; padding: 25px 0; font-size: 12px;"><i class="fa fa-users complaintIcon" title="Informação sobre a Morada" style="font-size: 15px; margin-right: 10px;" @click="openOrdersClientAddressInfo(poInfoModal.content.element.customerPhone, poInfoModal.content.element.deliveryGpsLatitude, poInfoModal.content.element.deliveryGpsLongitude)"></i>Morada
                          </p>
                        </b-col>
                        <b-col lg="8" class="my-1">
                          <strong style="text-align: right; font-size: 12px;">{{poInfoModal.content.element.deliveryStreet}}, {{poInfoModal.content.element.deliveryDoorNum}} {{poInfoModal.content.element.deliveryFloorNum}}</strong><br>
                          <strong style="text-align: right; font-size: 12px;">{{poInfoModal.content.element.deliveryZipCode}} - {{poInfoModal.content.element.deliveryCity}}</strong>
                        </b-col>
                        <b-col lg="1" class="my-1">
                          <div style="float:right; margin:5px" v-if="hasPermissions('Pages.POs.AlterarMorada')">
                            <i class="fa fa-edit edit" title="Editar" @click="clientAdressInfo(poInfoModal.content, poInfoModal.id, $event.target)"></i>
                          </div>
                          <a :href="poMapsLink" target="_blank" style="float:right;">
                            <b-button size="sm" variant="secondary" pill style="padding:5px; padding-bottom:2px; margin-left: 1px; border-color: white" title="Ver Mapa">
                              <i class="nc-icon nc-pin-3" style="color: red; font-size: 1.5em; font-weight:bold;"></i>
                            </b-button>
                          </a>
                          <!-- <div style="float:right; margin:7px">
                            <i class="nc-icon nc-square-pin edit" title="Registar Aviso de Coordenadas Erradas" style="color: orange; font-size: 1.2em; font-weight:bold;" @click.once="postAddressWrongCoords(poInfoModal.content)"></i>
                          </div> -->
                        </b-col>
                      </b-row>
                    </div>
                    <div class="row justify-content-center"  v-if="poInfoModal.content != ''" style="width: 100%">
                      <div style="margin:10px" v-if="hasPermissions('Pages.Alerts.CriarAlerta')">
                        <b-button size="md" variant="secondary" class="acaobutton" @click="alertInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Criar Alerta">
                          <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                            <div class="font-icon-detail">
                              <i class="nc-icon nc-bell-55" style="color: #DAA520; font-size: 1.5em;"></i>
                            </div>
                          </div>
                        </b-button>
                      </div>
                      <div style="margin:10px"  v-if="(this.tod.getTime() <= new Date(new Date(poInfoModal.content.element.deliveryTimeWindowStart).setHours(0,0,0,0)).getTime()) && hasPermissions('Pages.Clients.EnviarSMS')">
                        <b-button size="md" variant="secondary" class="acaobutton" @click="sendSMSToClientInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Enviar Mensagem para o Cliente">
                          <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                            <div class="font-icon-detail">
                              <i class="nc-icon nc-email-83" style="color: #DAA520; font-size: 1.5em;"></i>
                            </div>
                          </div>
                        </b-button>
                      </div>
                      <div style="margin:10px"  v-if="hasPermissions('Pages.POs.CreateVoucher')">
                        <b-button size="md" variant="secondary" class="acaobutton" @click="createVoucherInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Criar Voucher para o Cliente">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="fa fa-percent" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                        </b-button>
                      </div>
                    </div>
                    <div class="table-responsive" v-if="this.showPedidosCliente">
                      <b-table  class="tables"
                        responsive
                        striped
                        bordered
                        outlined
                        hover
                        small
                        no-border-collapse
                        thead-class="cm-bg"
                        id="pedidosClienteTable"
                        :items="pedidosCliente"
                        :fields="pedidosClienteFields">
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ width: (field.key === 'id') ? '7%' : (field.key === 'estado') ? '9%' : (field.key === 'supplierName') ? '19%' : '13%' }"
                          >
                        </template>
                        <template #cell(estado)="row">
                          <i v-if="row.item.estadoState == 8" class="nc-icon nc-check-2" style="color: lightgreen;  font-size: 1.5em;  font-weight: bold;" title="Finalizada"></i>
                          <i v-if="row.item.estadoState == 9" class="nc-icon nc-simple-remove" style="color: red;  font-size: 1.5em;  font-weight: bold;" title="Cancelada"></i>
                          <i v-if="row.item.estadoState == 1" class="fa fa-phone" style="color: black;  font-size: 1.5em;  font-weight: bold;" title="A tocar no Estafeta"></i>
                          <i v-if="row.item.estadoState == 2" class="nc-icon nc-send" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="Á deriva há"><i class="nc-icon nc-circle-09" style="color: black;  font-size: 1.0em;  font-weight: bold;"></i></i>
                          <i v-if="row.item.estadoState == 3" class="nc-icon nc-send" style="color: #DAA520;  font-size: 1.5em; font-weight: bold;" title="Envio em"></i>
                          <i v-if="row.item.estadoState == 4" class="nc-icon nc-delivery-fast" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="A caminho do Restaurante há"><i class="nc-icon nc-bank" style="color: black;  font-size: 1.0em;  font-weight: bold;"></i></i>
                          <i v-if="row.item.estadoState == 5" class="nc-icon nc-bank" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="No Restaurante há"></i>
                          <i v-if="row.item.estadoState == 6" class="nc-icon nc-delivery-fast" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="A caminho do Cliente há"><i class="nc-icon nc-single-02" style="color: black;  font-size: 1.0em;  font-weight: bold;"></i></i>
                          <i v-if="row.item.estadoState == 7" class="nc-icon nc-circle-09" style="color: #DAA520;  font-size: 1.5em;  font-weight: bold;" title="No Cliente há"></i>
                          {{ row.item.estado}}
                        </template>
                        <template #cell(id)="row">
                          <a @click="seeClienteInfo(row.item.id, $event.target)" class="nameline">
                            {{row.item.id}}
                          </a>
                        </template>
                        <template #cell(datedeliveryTimeWindowEnd1)="row">
                          <i class="fa fa-bolt" title="Entregar o mais rápido possivel" style="color: red; font-size: 16px" v-if="row.item.element.deliverAsap"></i>{{row.item.datedeliveryTimeWindowEnd1}}
                        </template>
                        <template #cell(supplierName)="row">
                          <div class="row" style="margin: 0"><VoucherIcon :poNum="row.item.id" style="margin-right: 5px"></VoucherIcon>{{row.item.supplierName}}</div>
                        </template>
                      </b-table>
                    </div>
                    <div v-if="this.ordersClientAddressInfo == '' && this.showOrdersClientAddressInfo">
                      Não existe informação sobre outros clientes nesta morada.
                    </div>
                    <div class="table-responsive" v-if="this.showOrdersClientAddressInfo && this.ordersClientAddressInfo != ''">
                      <b-table  class="tables"
                        responsive
                        striped
                        bordered
                        outlined
                        hover
                        small
                        no-border-collapse
                        thead-class="cm-bg"
                        id="ordersClientAddressInfoTable"
                        :items="ordersClientAddressInfo"
                        :fields="ordersClientAddressInfoFields">
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ width: field.key === 'foo' ? '15%' : '15%' }"
                          >
                        </template>
                        <template #cell(deliveryCustomerName)="row">
                          <a @click="seeClienteInfo(row.item.lastPO.poNum, $event.target)" class="nameline">
                            {{row.item.deliveryCustomerName}}
                          </a>
                        </template>
                        <template #cell(lastOrder)="row">
                          {{getDateWithHoursAndSecondsFormated(row.item.lastOrder)}}
                        </template>
                        <template #cell(avgOrderValueByCliente)="row">
                          {{row.item.avgOrderValueByCliente}} €
                        </template>
                      </b-table>
                    </div>
                    <div class="table-responsive" v-if="this.showComplaintCliente">
                      <b-table  class="tables"
                        responsive
                        striped
                        bordered
                        outlined
                        hover
                        small
                        no-border-collapse
                        thead-class="cm-bg"
                        id="complaintClienteTable"
                        :items="complaintCliente"
                        :fields="complaintClienteFields"
                        @row-clicked="(item, index, event) => creditUsedInfo(item, index, event)">
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ width: field.key === 'foo' ? '15%' : '15%' }"
                          >
                        </template>
                        <template #cell(poNum)="row">
                          <a @click="seeClienteInfo(row.item.poNum, $event.target)" class="nameline">
                            {{row.item.poNum}}
                          </a>
                        </template>
                        <template #row-details="row">
                          <b-row>
                            <b-col lg="2">
                            </b-col>
                            <b-col lg="10">
                              {{ row.item.observations }}
                            </b-col>
                          </b-row>
                        </template>
                      </b-table>
                    </div>
                    <div class="table-responsive" v-if="this.showAlertCliente">
                      <b-table  class="tables"
                        responsive
                        striped
                        bordered
                        outlined
                        hover
                        small
                        no-border-collapse
                        thead-class="cm-bg"
                        id="alertClienteTable"
                        :items="alertCliente"
                        :fields="alertClienteFields"
                        @row-clicked="(item, index, event) => alertDescriptionInfo(item, poInfoModal.content, index, event)">
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ width: field.key === 'foo' ? '25%' : '25%' }"
                          >
                        </template>
                      </b-table>
                    </div>
                    <div class="table-responsive" v-if="this.showSMSMessagesCliente">
                      <b-table  class="tables"
                        responsive
                        striped
                        bordered
                        outlined
                        hover
                        small
                        no-border-collapse
                        thead-class="cm-bg"
                        id="smsMessagesClienteTable"
                        :items="smsMessagesCliente"
                        :fields="smsMessagesClienteFields">
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ width: field.key === 'foo' ? '15%' : '15%' }"
                          >
                        </template>
                        <template #cell(created)="row">
                          {{getDateWithHoursAndSecondsFormated(row.item.created)}}
                        </template>
                      </b-table>
                    </div>
                  </b-tab>
                  <b-tab title="Pedido">
                    <template #title>
                      <p class="tab">Produtos</p>
                    </template>
                    <div class="row justify-content-center"  v-if="poInfoModal.content != ''" style="margin: auto;width: 90%;padding: 10px;">
                      <b-row class="row justify-content-center" style="margin: auto;width: 100%;padding: 10px;">
                        <p style="white-space: pre; font-size:95%">{{poInfoModal.content.itemsDescription}}</p>
                        <p style="font-size:95%">#Código Levant: {{poInfoModal.content.element.validationCode}}</p>
                      </b-row>
                      <div class="row justify-content-center"  v-if="poInfoModal.content != ''" style="width: 100%">
                        <div style="margin:10px"  v-if="poInfoModal.content.element.isDelivered == false && poInfoModal.content.element.isCancelled == false && hasPermissions('Pages.POs.AlterarProdutos')">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="alterarPedidoInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Alterar Produtos">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <p style="color: #DAA520; font-size: 20px;">+/-</p>
                              </div>
                            </div>
                          </b-button>
                        </div>
                        <div style="margin:10px"  v-if="poInfoModal.content.element.isPickedup == false && poInfoModal.content.element.isCancelled == false">
                          <b-button size="md" variant="secondary" class="acaobutton" @click="sendPODescriptionInfo(poInfoModal.content, poInfoModal.id, $event.target)" title="Enviar Pedido por SMS">
                            <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                              <div class="font-icon-detail">
                                <i class="nc-icon nc-email-83" style="color: #DAA520; font-size: 1.5em;"></i>
                              </div>
                            </div>
                          </b-button>
                        </div>
                      </div>
                      <b-overlay id="overlay" :show="show" variant="transparent" rounded="lg" spinner-variant="warning">
                      </b-overlay>
                      <img :src="image" v-if="this.image!=''" style="width: 50%; height:50%; border-radius: 8px;" alt="Base64 encoded image" />
                      <b-row style="margin: auto;width: 100%;padding: 10px;">
                        <div style="position: absolute;right: 50px;" v-if="poInfoModal.content.element.isPickedup">
                          <b-button size="sm" variant="secondary" @click="getReceipt(poInfoModal.content.id)" pill style="margin:10px;padding:5px; padding-bottom:2px; margin-left: 1px; border-color: white" title="Ver Fatura">
                            <i class="nc-icon nc-single-copy-04" style="color: #DAA520; font-size: 3.5em; font-weight:bold;"></i>
                          </b-button>
                        </div>
                        <a :href="restLink" target="_blank" style="position: absolute;right: 0px;">
                          <b-button size="sm" variant="secondary" pill style="margin:10px;padding:5px; padding-bottom:2px; margin-left: 1px; border-color: white" title="Ver Ementa Restaurante">
                            <i class="nc-icon nc-bank" style="color: #DAA520; font-size: 3.5em; font-weight:bold;"></i>
                          </b-button>
                        </a>
                      </b-row>
                      <!-- <b-row style="margin: auto;width: 100%;border: 1px solid #DAA520;">
                        <b-col lg="2" class="my-1">
                          <p style="text-align: left; padding: 6px 0;">Nº Pedido</p>
                        </b-col>
                        <b-col lg="1" class="my-1">
                          <strong style="text-align: left; font-size: 18px;">{{poInfoModal.pedido.order_id}}</strong>
                        </b-col>
                        <b-col lg="1" class="my-1">
                          <p style="text-align: left; padding: 6px 0;">NIF</p>
                        </b-col>
                        <b-col lg="2" class="my-1">
                          <strong style="text-align: left; font-size: 18px;">{{poInfoModal.pedido.clientNIF}}</strong>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <p style="text-align: left; padding: 6px 0;">Horário Levantamento</p>
                        </b-col>
                        <b-col lg="3" class="my-1">
                          <strong style="text-align: left; font-size: 18px;">{{poInfoModal.pedido.orderDAPickupDate}}</strong>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 100%;border: 1px solid #DAA520;">
                        <b-col lg="12" class="my-1" v-for="menu in poInfoModal.pedido.orderData.menus" :key="menu.menuName">
                          <i class="fa fa-minus iconhover" title="Remover"></i>
                          <strong style="text-align: right; font-size: 18px;">{{menu.menuName}}</strong><strong style="text-align: right; font-size: 18px;" v-if="menu.menuPrice != 0"> €{{menu.menuPrice}}</strong>
                          <b-col lg="12" class="my-1" v-for="submenu in menu.subMenus" :key="submenu.subMenuTitle">
                            <i class="fa fa-minus iconhover" title="Remover"></i>
                            <strong style="text-align: right; font-size: 14px;">{{submenu.subMenuTitle}}</strong><strong style="text-align: right; font-size: 14px;"> €{{submenu.subMenuPrice}}</strong>
                          </b-col>
                        </b-col>
                        <b-col lg="12" class="my-1">
                          <p style="text-align: right; font-size: 18px;">Subtotal: €{{poInfoModal.pedido.orderData.subTotalValue}}</p>
                          <p style="text-align: right; font-size: 18px;">Taxa de Entrega: €{{poInfoModal.pedido.orderData.deliveryFee}}</p>
                          <p style="text-align: right; font-size: 18px;">Total: €{{poInfoModal.pedido.orderData.totalValue}}</p>
                        </b-col>
                      </b-row>
                      <b-row style="margin: auto;width: 100%;border: 1px solid #DAA520;">
                        <b-col lg="12" class="my-1">
                          <p style="text-align: left; padding: 6px 0;">Observações para o Restaurante</p>
                        </b-col>
                        <b-col lg="12" class="my-1">
                          <strong style="text-align: left; font-size: 18px;">{{poInfoModal.pedido.restaurantObservations}}</strong>
                        </b-col>
                      </b-row> -->
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </b-modal>
            <b-modal :id="cancelarModal.id" :title="cancelarModal.title" :ok-disabled="observation == ''" ok-variant="success" ok-title="Submeter" cancel-title="Cancelar" @hide="resetCancelarModal" @ok="postCancelar(cancelarModal.content, cancelar, observation)">
              <div v-if="cancelarModal.content != ''">
                <b-form-select v-model="cancelar" class="a" v-if="cancelarModal.content.element.isAssignedToDa">
                  <b-form-select-option disabled value="">Pagar ao Estafeta?</b-form-select-option>
                  <b-form-select-option value=true>Pagar</b-form-select-option>
                  <b-form-select-option value=false>Não Pagar</b-form-select-option>
                </b-form-select>
              </div>
              <b-form-select v-model="observation" class="a">
                <b-form-select-option disabled value=''>Selecione o motivo</b-form-select-option>
                <b-form-select-option value="Desistência do Cliente">Desistência do Cliente</b-form-select-option>
                <b-form-select-option value="Restaurante encerrado">Restaurante encerrado</b-form-select-option>
                <b-form-select-option value="O Restaurante não tem o prato escolhido">O Restaurante não tem o prato escolhido</b-form-select-option>
                <b-form-select-option value="Não validação de morada do cliente, sendo 1º Pedido">Não validação de morada do cliente, sendo 1º Pedido</b-form-select-option>
              </b-form-select>
            </b-modal>
            <b-modal :id="cancelarGiftCardModal.id" :title="cancelarGiftCardModal.title" ok-variant="success" ok-title="Submeter" cancel-title="Cancelar" @hide="resetCancelarGiftCardModal" @ok="postCancelarGiftCardModal(cancelarGiftCardModal.content)">
            </b-modal>
            <b-modal :id="retirarModal.id" :title="retirarModal.title" :ok-disabled="observation == ''" ok-variant="success" ok-title="Retirar" cancel-title="Cancelar" @hide="resetRetirarModal" @ok="postRetirar(retirarModal.content, observation)">
              <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações sobre a ação.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="reAtivarModal.id" :title="reAtivarModal.title" :ok-disabled="observation == ''" ok-variant="success" ok-title="ReAtivar" cancel-title="Cancelar" @hide="resetReAtivarModal" @ok="postReAtivar(reAtivarModal.content, observation)">
              <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações sobre a ação.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="fecharModal.id" :title="fecharModal.title" :ok-disabled="observation == ''" ok-variant="success" ok-title="Fechar" cancel-title="Cancelar" @hide="resetFecharModal" @ok="postFechar(fecharModal.content, observation)">
              <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações sobre a ação.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="reclamacaoModal.id" :title="reclamacaoModal.title" :ok-disabled="(newDeliver && (reclamacao == '' || responsability == '')) || (observation == '' || observation.length < 10) || (devolution == 'Devolution' && ((devolutionValue == 0 && !txDeliver) || devolutionType == '') || (txDeliver && responsability == '') || (this.txDeliverValueState == false) || (this.devolutionValueState == false))" ok-variant="success" ok-title="Submeter" cancel-title="Cancelar" @hide="resetReclamacaoModal" @ok="postReclamacao(reclamacaoModal.content, reclamacao, observation)">
              <b-row><p style="margin: 15px;font-size:14px"><strong>Nova Entrega?</strong></p><b-form-checkbox v-model="newDeliver" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
              <div v-if="newDeliver" style="margin: auto;width: 100%;border: 1px solid gray; padding:10px">
                <b-row><p style="margin: 15px;font-size:14px"><strong>Com devolução do pedido anterior ao Restaurante?</strong></p><b-form-checkbox v-model="withPartnerDevolution" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
                <b-row><p style="margin: 15px;font-size:14px"><strong>Afetar a nova entrega ao mesmo estafeta?</strong></p><b-form-checkbox v-model="sameDA" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
                <b-row style="margin-left:0px"><strong>Informação para o Estafeta</strong><p style="color: red;font-size: 16px">*</p></b-row>
                <b-form-input
                  id="reclamacao-input"
                  v-model="reclamacao"
                  required
                ></b-form-input>
                <b-row style="margin-left:0px">
                  <strong>Responsabilidade</strong><p style="color: red;font-size: 16px">*</p>
                  <b-form-select v-model="responsability" style="margin-right: 15px">
                    <b-form-select-option disabled value=""></b-form-select-option>
                    <b-form-select-option value="Supplier">Restaurante</b-form-select-option>
                    <b-form-select-option value="ComerMelhor">ComerMelhor</b-form-select-option>
                  </b-form-select>
                </b-row>
                <b-row v-if="responsability == 'ComerMelhor'"><p style="margin: 15px;font-size:14px"><strong>Replicar pedido?</strong></p><b-form-checkbox v-model="isReplica" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
              </div>
              <div v-if="!newDeliver"  style="margin: auto;width: 100%;border: 1px solid gray; padding:10px">
                <b-row style="margin-left:0px">
                  <strong>Ação:</strong>
                  <b-form-radio-group
                    v-model="devolution"
                    class="mt-0"
                    style="margin-left:5px"
                  >
                    <b-form-radio value="Devolution" v-if="!this.existsDevolution">Devolução</b-form-radio>
                    <b-form-radio value="Register">Registo</b-form-radio>
                  </b-form-radio-group>
                </b-row>
                <div v-if="devolution == 'Devolution'">
                  <strong>Valor da Devolução</strong> (MAX. {{this.round((this.currentOrderValue-this.currentDeliveryFee))}}€)
                  <b-form-input
                    v-model="devolutionValue"
                    @input.native="changeDevolutionValue"
                    :state="devolutionValueState"
                    required
                    type="number"
                    min=0
                    step="0.01"
                    style="text-align: right;"
                  ></b-form-input>
                  <b-row><p style="margin: 15px;font-size:14px"><strong>Devolução da Taxa de Entrega?</strong> ({{this.currentDeliveryFee}}€)</p><b-form-checkbox v-model="txDeliver" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
                  <b-row style="margin-left:0px" v-if="txDeliver">
                    <strong>Responsabilidade:</strong><p style="color: red;font-size: 16px">*</p>
                    <b-form-select v-model="responsability" style="margin-right: 15px">
                      <b-form-select-option disabled value=""></b-form-select-option>
                      <b-form-select-option value="Supplier">Restaurante</b-form-select-option>
                      <b-form-select-option value="ComerMelhor">ComerMelhor</b-form-select-option>
                    </b-form-select>
                    <strong>Valor da Taxa de Entrega</strong> (MAX. {{this.currentDeliveryFee}}€)
                    <b-form-input
                      v-model="txDeliverValue"
                      @input.native="changeTxDeliverValue"
                      :state="txDeliverValueState"
                      required
                      type="number"
                      min=0
                      step="0.01"
                      style="text-align: right;margin-right:15px"
                    ></b-form-input>
                  </b-row>
                  <b-row style="margin-left:0px">
                    <strong>Método de Devolução</strong><p style="color: red;font-size: 16px">*</p>
                    <b-form-select v-model="devolutionType" style="margin-right: 15px;">
                      <b-form-select-option disabled value=""></b-form-select-option>
                      <b-form-select-option value="CreditSupplier">Crédito neste Restaurante</b-form-select-option>
                      <b-form-select-option value="Credit">Crédito qualquer Restaurante</b-form-select-option>
                      <b-form-select-option value="OnlineRefund" v-if="this.onlinePaymentsValue > 0">Devolver Online</b-form-select-option>
                    </b-form-select>
                  </b-row>
                </div>
              </div>
              <b-row style="margin-left:0px"><strong>Observações da Ação</strong><p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações sobre a ação.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="alterarPedidoModal.id" :title="alterarPedidoModal.title" :ok-disabled="alterarPedido == '' || alterarPedido == 0 || observation == ''" ok-variant="success" ok-title="Alterar" cancel-title="Cancelar" @hide="resetAlterarPedidoModal" @ok="postAlterarPedido(alterarPedidoModal.content, alterarPedido, observation)">
              <div>
                <p>Valor Atual: <strong>{{alterarPedidoModal.content.orderValue}}</strong></p>
              </div>
              Diferença
              <b-form-input
                id="alterarPedido-input"
                v-model="alterarPedido"
                @input.native="changeAlterarPedido"
                required
                type="number"
                :min="parseFloat(currentOrderValue) * (-1)"
                step="0.01"
                style="text-align: right;"
              ></b-form-input>
              Valor Total
              <b-form-input
                id="alterarPedidoTotal-input"
                v-model="alterarPedidoTotal"
                @input.native="changeAlterarPedidoTotal"
                type="number"
                min="0"
                step="0.01"
                style="text-align: right;"
              ></b-form-input>
              <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações sobre a ação.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="ativarCreditoModal.id" size="lg" :title="ativarCreditoModal.title" :ok-disabled="ativarCredito == '' || ativarCredito == 0" ok-variant="success" ok-title="Ativar" cancel-title="Cancelar" @hide="resetAtivarCreditoModal" @ok="postAtivarCredito(ativarCreditoModal.content, ativarCredito, observation)">
              Valor Em Divida: <strong>{{this.currentAmountDue}}€</strong><br>
              Valor Pago Online: <strong>{{this.onlinePaymentsValue}}€</strong>
              Crédito Disponivel: <strong>{{this.currentCreditAvailable}}€</strong>
              <h6 style="float:right"><i class="nc-icon nc-layers-3 complaintIcon" title="Reclamações" @click="openComplaintsByCliente(poInfoModal.content.element.customerEmail, poInfoModal.content.element.customerPhone)"></i></h6>
              <b-form-input
                id="ativarCredito-input"
                v-model="ativarCredito"
                @input.native="changeAtivarCredito()"
                required
                type="number"
                min="0"
                :max="this.currentCreditAvailable > (this.currentAmountDue + this.onlinePaymentsValue)? this.currentCreditAvailable : (this.currentAmountDue + this.onlinePaymentsValue)"
                step="0.01"
                style="text-align: right;"
              ></b-form-input>
              <br>
              <div class="table-responsive"  v-if="this.showComplaintCliente">
                <b-table  class="tables"
                  responsive
                  striped
                  bordered
                  outlined
                  hover
                  small
                  no-border-collapse
                  thead-class="cm-bg"
                  id="complaintClienteTable"
                  :items="complaintCliente"
                  :fields="complaintClienteFields2"
                  @row-clicked="(item, index, event) => creditUsedInfo(item, index, event)">
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'foo' ? '15%' : '15%' }"
                    >
                  </template>
                  <template #cell(poNum)="row">
                    <a @click="seeClienteInfo(row.item.poNum, $event.target)" class="nameline">
                      {{row.item.poNum}}
                    </a>
                  </template>
                  <template #row-details="row">
                    <b-row>
                      <b-col lg="2">
                      </b-col>
                      <b-col lg="10">
                        {{ row.item.observations }}
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
              </div>
            </b-modal>
            <b-modal :id="addAmountDueModal.id" size="md" :title="addAmountDueModal.title" :ok-disabled="addAmountDue == '' || addAmountDue == 0 || observation == ''" ok-variant="success" ok-title="Adicionar" cancel-title="Cancelar" @hide="resetAddAmountDueModal" @ok="postAddAmountDue(addAmountDueModal.content, addAmountDue, observation)">
              <b-form-input
                id="addAmountDue-input"
                v-model="addAmountDue"
                @input.native="changeAddAmountDue()"
                required
                type="number"
                min="0"
                step="0.01"
                style="text-align: right;"
              ></b-form-input>
              <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações sobre a ação.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="alterarTimeWindowModal.id" :title="alterarTimeWindowModal.title" :ok-disabled="alterarTimeWindow == '' || alterarTimeWindow == 0" ok-variant="success" ok-title="Alterar" cancel-title="Cancelar" @hide="resetAlterarTimeWindowModal" @ok="postAlterarTimeWindow(alterarTimeWindowModal.content, alterarTimeWindow, observation)">
              <div>
                <p>Horário Atual: <strong>{{this.currentDeliveryTimeWindowEndDate}}</strong></p>
              </div>
              Minutos
              <b-form-input
                id="alterarTimeWindowMin-input"
                v-model="alterarTimeWindowMin"
                @input.native="changeAlterarTimeWindowMHD"
                required
                type="number"
                style="text-align: right;"
              ></b-form-input>
              Horas
              <b-form-input
                id="alterarTimeWindowHora-input"
                v-model="alterarTimeWindowHora"
                @input.native="changeAlterarTimeWindowMHD"
                type="number"
                style="text-align: right;"
              ></b-form-input>
              Dias
              <b-form-input
                id="alterarTimeWindowDia-input"
                v-model="alterarTimeWindowDia"
                @input.native="changeAlterarTimeWindowMHD"
                type="number"
                style="text-align: right;"
              ></b-form-input>
              <p>Diferença em minutos: {{this.alterarTimeWindow}}</p>
              <p>Nova Data: {{this.newDateAlterarTimeWindow}}</p>
            </b-modal>
            <b-modal :id="atribuicaoMaisModal.id" :title="atribuicaoMaisModal.title" :ok-disabled="atribuicaoMais == ''" ok-variant="success" ok-title="Atribuir" cancel-title="Cancelar" @hide="resetAtribuicaoMaisModal" @ok="postAtribuirMaisPedidos(atribuicaoMaisModal.content, atribuicaoMais, !newAssign, observation)">
              <b-form-select v-model="atribuicaoMais" class="a">
                <b-form-select-option disabled value="">Escolha o Estafeta</b-form-select-option>
                <b-form-select-option v-for="da in dasOnline" :key="da.id" :value="da">{{da.userName}}</b-form-select-option>
              </b-form-select>
              <b-row><p style="margin: 15px;font-size:14px">Juntar ao(s) Pedido(s) em Curso?</p><b-form-checkbox v-model="newAssign" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
            </b-modal>
            <b-modal :id="alterarEstafetaModal.id" :title="alterarEstafetaModal.title" :ok-disabled="alterarEstafeta == '' || observation == ''" ok-variant="success" ok-title="Alterar" cancel-title="Cancelar" @hide="resetAlterarEstafetaModal" @ok="postAlterarEstafeta(alterarEstafetaModal.content, alterarEstafeta, observation)">
              <b-form-select v-model="alterarEstafeta" class="a">
                <b-form-select-option disabled value="">Escolha o Estafeta</b-form-select-option>
                <b-form-select-option v-for="da in dasOnline" :key="da.id" :value="da">{{da.userName}}</b-form-select-option>
              </b-form-select>
              <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações sobre a ação.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="adicionarKmsModal.id" :title="adicionarKmsModal.title" :ok-disabled="adicionarKms == '' || adicionarKms == 0" ok-variant="success" ok-title="Adicionar" cancel-title="Cancelar" @hide="resetAdicionarKmsModal" @ok="postAdicionarKms(adicionarKmsModal.content, adicionarKms, observation)">
              <div>
                <p>Distância Atual: <strong>{{adicionarKmsModal.content.totalDistance}}</strong></p>
              </div>
              Diferença
              <b-form-input
                id="adicionarKms-input"
                v-model="adicionarKms"
                @input.native="changeAdicionarKms"
                required
                :min="parseFloat(currentDistance) * (-1)"
                type="number"
                step="0.01"
                style="text-align: right;"
              ></b-form-input>
              Distância Total
              <b-form-input
                id="adicionarKmsTotal-input"
                v-model="adicionarKmsTotal"
                @input.native="changeAdicionarKmsTotal"
                min="0"
                type="number"
                step="0.01"
                style="text-align: right;"
              ></b-form-input>
            </b-modal>
            <b-modal :id="ajustarDeliveryFeeModal.id" :title="ajustarDeliveryFeeModal.title" :ok-disabled="this.txDeliverValueState == false || txDeliverValue == '' || txDeliverValue == 0 || observation == ''"  ok-variant="success" ok-title="Ajustar" cancel-title="Cancelar" @hide="resetAjustarDeliveryFeeModal" @ok="postAjustarDeliveryFee(ajustarDeliveryFeeModal.content, observation)">
              <div>
                <p>Taxa de Entrega: <strong>{{this.round(this.currentDeliveryFee - this.txDeliverValue)}}</strong></p>
              </div>
              Devolver
              <b-form-input
                id="ajustxdel-input"
                v-model="txDeliverValue"
                @input.native="changeTxDeliverValue"
                :state="txDeliverValueState"
                min="0"
                type="number"
                step="0.01"
                style="text-align: right;"
              ></b-form-input>
              <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações sobre a ação.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="alterarOrdensModal.id" :title="alterarOrdensModal.title" :ok-disabled="observation == ''" ok-variant="success" ok-title="Alterar" cancel-title="Cancelar" @hide="resetAlterarOdensModal" @ok="alterarSteps(alterarOrdensModal.po, alterarOrdensModal.content, observation)">
                <b-row style="margin-left:5px">Observações da Ação<p style="color: red;font-size: 16px">*</p></b-row>
                <b-form-textarea
                  id="observation-input"
                  v-model="observation"
                  placeholder="Observações sobre a ação.."
                ></b-form-textarea>
            </b-modal>
            <b-modal :id="creditUsedModal.id" :title="creditUsedModal.title" size="md" ok-only ok-variant="secundary" ok-title="Fechar" @hide="resetCreditUsedModal">
              <div class="table-responsive" v-if="(creditUsed != '' && creditRetrieved) || !creditRetrieved">
                <b-table  class="tables"
                  responsive
                  striped
                  bordered
                  outlined
                  hover
                  small
                  no-border-collapse
                  thead-class="cm-bg"
                  id="creditUsedTable"
                  :busy="isBusyCreditUsed"
                  :items="creditUsed"
                  :fields="creditUsedFields">
                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{ width: field.key === 'foo' ? '35%' : '35%' }"
                    >
                  </template>
                  <template #cell(poNum)="row">
                    <a @click="seeClienteInfo(row.item.poNum, $event.target)" class="nameline">
                      {{row.item.poNum}}
                    </a>
                  </template>
                </b-table>
              </div>
              <div v-if="creditUsed == '' && creditRetrieved">
                Não foi utilizado nenhum crédito desta Reclamação.
              </div>
              <div v-if="creditUsedModal.content.element != null">
                <div style="float:right" v-if="creditUsedModal.content.element.devolutionType != 'OnlineRefund' && creditUsedModal.content.element.totalCredit == creditUsedModal.content.element.creditAvailable && !creditUsedModal.content.element.newDelivery && hasPermissions('Pages.Actions')">
                  <!-- <b-button size="sm" variant="warning" @click="complaintEditModalInfo(creditUsedModal.content)">Editar Reclamação</b-button> -->
                  <b-button size="sm" variant="danger" @click="complaintDeleteModalInfo(creditUsedModal.content)">Eliminar Reclamação</b-button>
                </div>
              </div>
            </b-modal>
            <b-modal :id="obsDAModal.id" :title="obsDAModal.title" :ok-disabled="observation == '' || observation == lastObsDA" ok-variant="success" ok-title="Gravar" cancel-title="Cancelar" @hide="resetObsDAModal" @ok="postObsDA(obsDAModal.content, lastObsDA, observation)">
              <b-row style="margin-left:5px">Observações para o Estafeta<p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="observation-input"
                v-model="observation"
                placeholder="Observações para o Estafeta.."
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="clientAdressModal.id" :title="clientAdressModal.title" size="lg" :ok-disabled="rua == '' || porta == '' || postal == '' || localidade == '' || addressUpdated == false || clientAdressModal.location.lat == '' || clientAdressModal.location.lng == ''" ok-variant="success" ok-title="Gravar" cancel-title="Cancelar" @hide="resetClientAdressModal" @ok="postClientAdress(clientAdressModal.content, rua, porta, andar, postal, localidade, observation, clientAdressModal.location, addressUpdated, changeAmounts, changeAddressGlobally)">
              <b-row>
                <b-col>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="4" class="my-1">
                      <p style="text-align: right; padding: 6px 0;">Rua<span style="color: red;font-size: 16px">*</span></p>
                    </b-col>
                    <b-col lg="5" class="my-1">
                      <b-form-input
                        id="rua-input"
                        v-model="rua"
                        type="text"
                      ></b-form-input>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="4" class="my-1">
                      <p style="text-align: right; padding: 6px 0;">Nº Porta<span style="color: red;font-size: 16px">*</span></p>
                    </b-col>
                    <b-col lg="5" class="my-1">
                      <b-form-input
                        id="porta-input"
                        v-model="porta"
                        type="text"
                      ></b-form-input>
                    </b-col>
                    <b-col></b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="4" class="my-1">
                      <p style="text-align: right; padding: 6px 0;">Nº Andar</p>
                    </b-col>
                    <b-col lg="5" class="my-1">
                      <b-form-input
                        id="andar-input"
                        v-model="andar"
                        type="text"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-row style="margin-left:5px">Distância adicional: <strong> {{this.kmsDif}} </strong> Km</b-row>
                      <b-row style="margin-left:5px">Montante adicional: <strong> {{this.amountDif}} </strong> €</b-row>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="4" class="my-1">
                      <p style="text-align: right; padding: 6px 0;">Código Postal<span style="color: red;font-size: 16px">*</span></p>
                    </b-col>
                    <b-col lg="5" class="my-1">
                      <b-form-input
                        id="postal-input"
                        v-model="postal"
                        type="text"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-row><p style="margin-left:20px;font-size:14px">Atualizar valores?</p><p style="margin-right:5px;color: red;font-size: 14px">*</p>Não<b-form-checkbox v-model="changeAmounts" name="check-button-changeAmounts" switch style="margin-left:5px"></b-form-checkbox>Sim</b-row>
                      <b-row><p style="margin-left:20px;font-size:14px">Só nesta entrega?</p><p style="margin-right:5px;color: red;font-size: 14px">*</p>Não<b-form-checkbox v-model="changeAddressGlobally" name="check-button-changeAddressGlobally" switch style="margin-left:5px"></b-form-checkbox>Sim</b-row>
                    </b-col>
                  </b-row>
                  <b-row style="margin: auto;width: 100%;">
                    <b-col lg="4" class="my-1">
                      <p style="text-align: right; padding: 6px 0;">Localidade<span style="color: red;font-size: 16px">*</span></p>
                    </b-col>
                    <b-col lg="5" class="my-1">
                      <b-form-input
                        id="localidade-input"
                        v-model="localidade"
                        type="text"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-button size="sm" variant="secondary" @click="getCoordsByAddress(clientAdressModal.content, rua, porta, andar, postal, localidade)" style="margin: 5%" :disabled="rua == '' || porta == '' || postal == '' || localidade == ''">Atualizar Mapa</b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <AddressMap :key="clientAdressModal.location" :location="clientAdressModal.location"></AddressMap>
                </b-col>
              </b-row>
            </b-modal>
            <b-modal :id="changeAdressModal.id" :title="changeAdressModal.title" ok-only ok-variant="secundary" ok-title="Fechar" @hide="resetChangeAdressModal">
              <b-row style="margin-left:5px">Distância adicional: <strong>{{this.kmsAux}}</strong> Km</b-row>
              <b-row style="margin-left:5px">Montante adicional: <strong>{{this.amountAux}}</strong> €</b-row>
            </b-modal>
            <b-modal :id="mbwayModal.id" :title="mbwayModal.title" :ok-disabled="telMB == '' || telMB.match(/^\d{9}$/) == null" ok-variant="success" ok-title="Pedir" cancel-title="Cancelar" @hide="resetmbwayModal" @ok="postMBWAYRequest(mbwayModal.content, telMB)">
              <b-row style="margin: auto;width: 100%;">Valor em divida: <strong>{{this.currentAmountDue}}</strong> €</b-row>
              <b-row style="margin: auto;width: 100%;">Nº Telemóvel a efetuar Pagamento</b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-form-input
                  id="tel-input"
                  v-model="telMB"
                  type="text"
                ></b-form-input>
              </b-row>
              <div v-if="this.phonesPayments != null">
                <div v-for="phone in phonesPayments" :key="phone">
                  <div v-if="phone != telMB" class="choosePhones" @click="chooseMBWAYPhone(phone)">
                    <i class="fa fa-phone" style="color: black;  font-size: 1.1em;  font-weight: bold; margin-right: 10px;margin-left: 10px"></i><strong>{{phone}}</strong>
                  </div>
                </div>
              </div>
            </b-modal>
            <b-modal :id="refundModal.id" :title="refundModal.title" ok-only ok-variant="secundary" ok-title="Fechar" @hide="resetRefundModal">
              <b-row style="margin-left:5px">Devolvido Online: <strong>{{this.refunded}}</strong> €</b-row>
              <b-row style="margin-left:5px">Ainda falta devolver: <strong>{{this.refundDue}}</strong> €</b-row>
            </b-modal>
            <b-modal :id="extratoModal.id" :title="extratoModal.title" size="xl" ok-only ok-variant="secundary" ok-title="Fechar" @hide="resetExtratoModal">
              <b-tabs content-class="mt-3" v-model="tabIndexExtrato" justified>
                <b-tab title="Extrato">
                  <template #title>
                      <p class="tab">Extrato do Pedido</p>
                  </template>
                  <div class="table-responsive justify-content-center" v-if="(extrato != '' && extratoRetrieved) || !extratoRetrieved" style="margin: auto;width: 90%;padding: 10px;">
                    <b-table  class="tables"
                      responsive
                      striped
                      bordered
                      outlined
                      hover
                      small
                      no-border-collapse
                      thead-class="cm-bg"
                      id="extratoTable"
                      :busy="isBusyExtrato"
                      :items="extrato"
                      :fields="extratoFields"
                      :tbody-tr-class="rowClass">
                      <!-- <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{ width: field.key === 'foo' ? '15%' : '15%' }"
                        >
                      </template> -->
                      <template #cell(lineDate)="row">
                        {{getDateWithHoursAndSecondsFormated(row.item.lineDate)}}
                      </template>
                      <template #cell(subTotal)="row">
                        {{row.item.subTotal != 0? round(row.item.subTotal)+'€': ''}}
                      </template>
                      <template #cell(deliveryFee)="row">
                        {{row.item.deliveryFee != 0? round(row.item.deliveryFee)+'€': ''}}
                      </template>
                      <template #cell(total)="row">
                        {{row.item.total != 0? round(row.item.total)+'€': ''}}
                      </template>
                      <template #cell(amountDue)="row">
                        {{round(row.item.amountDue)}}
                      </template>
                      <template #cell(paymentValue)="row">
                        {{row.item.paymentValue != 0? round(row.item.paymentValue)+'€ / '+row.item.paymentType: ''}}
                      </template>
                    </b-table>
                  </div>
                  <div v-if="extrato == '' && extratoRetrieved">
                    Não existe extrato para este pedido.
                  </div>
                </b-tab>
                <b-tab title="Pagamentos">
                  <template #title>
                      <p class="tab">Pagamentos Online</p>
                  </template>
                  <div class="table-responsive justify-content-center" v-if="(onlinePayments != '' && paymentsRetrieved) || !paymentsRetrieved" style="margin: auto;width: 90%;padding: 10px;">
                    <b-table  class="tables"
                      responsive
                      striped
                      bordered
                      outlined
                      hover
                      small
                      no-border-collapse
                      thead-class="cm-bg"
                      id="onlinePaymentsTable"
                      :busy="isBusyOnlinePayments"
                      :items="onlinePayments"
                      :fields="onlinePaymentsFields">
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{ width: field.key === 'foo' ? '20%' : '20%' }"
                        >
                      </template>
                    </b-table>
                  </div>
                  <div v-if="onlinePayments == '' && paymentsRetrieved">
                    Não foi efetuado nenhum pagamento online.
                  </div>
                </b-tab>
              </b-tabs>
            </b-modal>
            <b-modal :id="alertModal.id" :title="alertModal.title" :ok-disabled="description == ''" ok-variant="success" ok-title="Criar" cancel-title="Cancelar" @hide="resetAlertModal" @ok="postAlert(alertModal.content, clientSupplier, recurrent, description)">
              <b-row><p style="margin-left:20px;font-size:16px">Associar apenas a este Restaurante?</p><p style="margin-right:5px;color: red;font-size: 16px">*</p>Não<b-form-checkbox v-model="clientSupplier" name="check-button" switch style="margin-left:5px"></b-form-checkbox>Sim</b-row>
              <b-row><p style="margin-left:20px;font-size:16px">Alertar apenas no próximo pedido?</p><p style="margin-right:5px;color: red;font-size: 16px">*</p>Não<b-form-checkbox v-model="recurrent" name="check-button" switch style="margin-left:5px"></b-form-checkbox>Sim</b-row>
              <b-row><p style="margin-left:20px;font-size:16px">Descrição</p><p style="color: red;font-size: 16px">*</p></b-row>
              <b-form-textarea
                id="description-input"
                v-model="description"
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="alertDescriptionModal.id" :title="alertDescriptionModal.title" ok-variant="secondary" ok-title="Fechar" ok-only @hide="resetAlertDescriptionModal">
              <b-row style="margin: auto;padding: 10px;border: 1px solid #878787c9;border-radius: 8px;">
                <p v-html="alertDescriptionModal.content.reason" style="color: black;font-size: 14px;padding: 2px; white-space: pre-wrap"></p>
              </b-row>
              <!-- <b-row style="margin: auto;width: 80%;border: 1px solid #DAA520;"><p style="font-size:16px">{{alertDescriptionModal.content.reason}}</p></b-row> -->
              <div style="float:right" v-if="(alertDescriptionModal.content.alertTypeId == 1 || alertDescriptionModal.content.alertTypeId == 2) && (alertDescriptionModal.content.completedDate == '' || alertDescriptionModal.content.recurrent == 'Sim') && hasPermissions('Pages.Actions')">
                <b-button size="sm" variant="warning" @click="alertEditInfo(alertDescriptionModal.content, alertDescriptionModal.po)">Editar Alerta</b-button>
                <b-button size="sm" variant="danger" @click="alertDeleteInfo(alertDescriptionModal.content.id, alertDescriptionModal.po)">Eliminar Alerta</b-button>
              </div>
            </b-modal>
            <b-modal :id="voucherModal.id" :title="voucherModal.title" ok-only ok-variant="secondary" ok-title="Fechar" @hide="resetVoucherModal">
              <div class="text-center" style="margin: auto;width: 90%;border: 2px solid #DAA520; padding:10px">
                <p style="font-size:16px">{{voucherModal.voucher}}</p>
                <p style="font-size:10px;margin-left:20px">{{voucherModal.date}}</p>
              </div>
            </b-modal>

            <b-modal :id="alertDeleteModal.id" :title="alertDeleteModal.title" ok-variant="danger" ok-title="Sim" cancel-title="Não" @hide="resetAlertDeleteModal" @ok="postAlertDelete(alertDeleteModal.content, alertDeleteModal.po)">
            </b-modal>
            <b-modal :id="alertEditModal.id" :title="alertEditModal.title" :ok-disabled="description == ''" ok-variant="warning" ok-title="Editar" cancel-title="Cancelar" @hide="resetAlertEditModal" @ok="postAlertEdit(alertEditModal.content.id, clientSupplier, recurrent, description, alertEditModal.po.element.customerPhone)">
              <b-row><p style="margin-left:20px;font-size:16px">Associar apenas a este Restaurante?</p><p style="margin-right:5px;color: red;font-size: 16px">*</p>Não<b-form-checkbox v-model="clientSupplier" name="check-button" switch style="margin-left:5px"></b-form-checkbox>Sim</b-row>
              <b-row><p style="margin-left:20px;font-size:16px">Alertar apenas no próximo pedido?</p><p style="margin-right:5px;color: red;font-size: 16px">*</p>Não<b-form-checkbox v-model="recurrent" name="check-button" switch style="margin-left:5px"></b-form-checkbox>Sim</b-row>
              <b-row><p style="margin-left:20px;font-size:16px">Descrição</p><p style="color: red;font-size: 16px">*</p></b-row>
              <b-row><p v-html="oldReason" style="margin-left:20px;font-size:12px; white-space: pre-wrap;text-decoration: line-through;"></p></b-row>
              <b-form-textarea
                id="description-input"
                v-model="description"
              ></b-form-textarea>
            </b-modal>
            <b-modal :id="complaintDeleteModal.id" :title="complaintDeleteModal.title" ok-variant="danger" ok-title="Sim" cancel-title="Não" @hide="resetComplaintDeleteModal" @ok="postComplaintDelete(complaintDeleteModal.content)">
            </b-modal>
            <b-modal :id="complaintEditModal.id" :title="complaintEditModal.title" :ok-disabled="this.txDeliverValueState == false || this.devolutionValueState == false" ok-variant="warning" ok-title="Editar" cancel-title="Cancelar" @hide="resetComplaintEditModal" @ok="postComplaintEdit()">
              <b-row><p style="margin: 15px;font-size:14px"><strong>Nova Entrega?</strong></p><b-form-checkbox v-model="newDeliver" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
              <div v-if="newDeliver" style="margin: auto;width: 100%;border: 1px solid gray; padding:10px">
                <b-row><p style="margin: 15px;font-size:14px"><strong>Com devolução do pedido anterior ao Restaurante?</strong></p><b-form-checkbox v-model="withPartnerDevolution" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
                <b-row><p style="margin: 15px;font-size:14px"><strong>Afetar a nova entrega ao mesmo estafeta?</strong></p><b-form-checkbox v-model="sameDA" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
                <b-row style="margin-left:0px"><strong>Informação para o Estafeta</strong><p style="color: red;font-size: 16px">*</p></b-row>
                <b-form-input
                  id="reclamacao-input"
                  v-model="reclamacao"
                  required
                ></b-form-input>
                <b-row style="margin-left:0px">
                  <strong>Responsabilidade</strong><p style="color: red;font-size: 16px">*</p>
                  <b-form-select v-model="responsability" style="margin-right: 15px">
                    <b-form-select-option disabled value=""></b-form-select-option>
                    <b-form-select-option value="Supplier">Restaurante</b-form-select-option>
                    <b-form-select-option value="ComerMelhor">ComerMelhor</b-form-select-option>
                  </b-form-select>
                </b-row>
                <b-row v-if="responsability == 'ComerMelhor'"><p style="margin: 15px;font-size:14px"><strong>Replicar pedido?</strong></p><b-form-checkbox v-model="isReplica" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
              </div>
              <div v-if="!newDeliver"  style="margin: auto;width: 100%;border: 1px solid gray; padding:10px">
                <b-row style="margin-left:0px">
                  <strong>Ação:</strong>
                  <b-form-radio-group
                    v-model="devolution"
                    class="mt-0"
                    style="margin-left:5px"
                  >
                    <b-form-radio value="Devolution" v-if="!this.existsDevolution">Devolução</b-form-radio>
                    <b-form-radio value="Register">Registo</b-form-radio>
                  </b-form-radio-group>
                </b-row>
                <div v-if="devolution == 'Devolution'">
                  <strong>Valor da Devolução</strong> (MAX. {{this.round((this.currentOrderValue-this.currentDeliveryFee))}}€)
                  <b-form-input
                    v-model="devolutionValue"
                    @input.native="changeDevolutionValue"
                    :state="devolutionValueState"
                    required
                    type="number"
                    min=0
                    step="0.01"
                    style="text-align: right;"
                  ></b-form-input>
                  <b-row><p style="margin: 15px;font-size:14px"><strong>Devolução da Taxa de Entrega?</strong> ({{this.currentDeliveryFee}}€)</p><b-form-checkbox v-model="txDeliver" name="check-button" switch style="margin:15px"></b-form-checkbox></b-row>
                  <b-row style="margin-left:0px" v-if="txDeliver">
                    <strong>Responsabilidade:</strong><p style="color: red;font-size: 16px">*</p>
                    <b-form-select v-model="responsability" style="margin-right: 15px">
                      <b-form-select-option disabled value=""></b-form-select-option>
                      <b-form-select-option value="Supplier">Restaurante</b-form-select-option>
                      <b-form-select-option value="ComerMelhor">ComerMelhor</b-form-select-option>
                    </b-form-select>
                    <strong>Valor da Taxa de Entrega</strong> (MAX. {{this.currentDeliveryFee}}€)
                    <b-form-input
                      v-model="txDeliverValue"
                      @input.native="changeTxDeliverValue"
                      :state="txDeliverValueState"
                      required
                      type="number"
                      min=0
                      step="0.01"
                      style="text-align: right;margin-right:15px"
                    ></b-form-input>
                  </b-row>
                  <b-row style="margin-left:0px">
                    <strong>Método de Devolução</strong><p style="color: red;font-size: 16px">*</p>
                    <b-form-select v-model="devolutionType" style="margin-right: 15px;">
                      <b-form-select-option disabled value=""></b-form-select-option>
                      <b-form-select-option value="CreditSupplier">Crédito neste Restaurante</b-form-select-option>
                      <b-form-select-option value="Credit">Crédito qualquer Restaurante</b-form-select-option>
                      <b-form-select-option value="OnlineRefund" v-if="this.onlinePaymentsValue > 0">Devolver Online</b-form-select-option>
                    </b-form-select>
                  </b-row>
                </div>
              </div>
            </b-modal>
            <b-modal :id="sendPODescriptionModal.id" :title="sendPODescriptionModal.title" :ok-disabled="telPartner.startsWith('2') || telPartner.startsWith('+3512') || telPartner.startsWith('3512') || telPartner == '' || poDesc == ''" ok-variant="success" ok-title="Enviar" cancel-title="Cancelar" @hide="resetSendPODescriptionModal" @ok="postSendPODescription(sendPODescriptionModal.content, telPartner, poDesc)">
              <b-row style="margin: auto;width: 100%;">Nº Telemóvel do Parceiro</b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-form-select v-model="telPartnerSelectBox" @change="telPartnerSelectBoxChanged" :state="telState">
                  <b-form-select-option v-for="phone in otherPhones" :key="phone.id" :value="phone.phoneNumber">{{phone.personTitle}} - {{phone.phoneNumber}}</b-form-select-option>
                  <b-form-select-option value="">Escolher Outro</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="telPartner-input-feedback">
                  O Nº Telemóvel não pode ser um número fixo.
                </b-form-invalid-feedback>
              </b-row>
              <b-row style="margin: auto;width: 100%;" v-if="telPartnerSelectBox == ''">
                <b-form-input
                  id="telPartner-input"
                  v-model="telPartner"
                  type="number"
                  :state="telState"
                  @change="verifyTel"
                ></b-form-input>
              </b-row>
              <b-row style="margin: auto;width: 100%;">Mensagem</b-row>
              <b-row style="margin: auto;width: 100%;border: 1px solid gray;">
                <p style="white-space: pre-wrap; font-size:90%;margin: 10px">{{this.poDesc}}</p>
              </b-row>
            </b-modal>
            <b-modal :id="sendSMSToClientModal.id" :title="sendSMSToClientModal.title" :ok-disabled="telClient.startsWith('2') || telClient.startsWith('+3512') || telClient.startsWith('3512') || telClient == '' || smsToClientText == ''" ok-variant="success" ok-title="Enviar" cancel-title="Cancelar" @hide="resetSendSMSToClientModal" @ok="postSendSMSToClient(sendSMSToClientModal.content, telClient, smsToClientText)">
              <b-row style="margin: auto;width: 100%;">Nº Telemóvel do Cliente</b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-form-input readonly
                  id="telClient-input"
                  v-model="telClient"
                  type="number"
                  :state="telState"
                  @change="verifyTel"
                ></b-form-input>
                <b-form-invalid-feedback id="telClient-input-feedback">
                  O Nº Telemóvel não pode ser um número fixo.
                </b-form-invalid-feedback>
              </b-row>
              <b-row style="margin: auto;width: 100%;">Tipo de Mensagem</b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-form-select v-model="smsToClientType" @change="smsTypeChanged($event, sendSMSToClientModal.content)">
                  <b-form-select-option disabled value=""></b-form-select-option>
                  <b-form-select-option value="Pedido Atrasado" v-if="sendSMSToClientModal.content != '' ? (sendSMSToClientModal.content.element.isDelivered == false && sendSMSToClientModal.content.element.isCancelled == false) : false">Pedido Atrasado</b-form-select-option>
                  <b-form-select-option value="Pedido saiu do Restaurante" v-if="sendSMSToClientModal.content != '' ? (sendSMSToClientModal.content.element.isDelivered == false && sendSMSToClientModal.content.element.isCancelled == false &&  sendSMSToClientModal.content.element.isPickedup == true) : false">Pedido saiu do Restaurante</b-form-select-option>
                  <b-form-select-option value="Informacao da entrega" v-if="sendSMSToClientModal.content != '' ? (sendSMSToClientModal.content.element.isDelivered == false && sendSMSToClientModal.content.element.isCancelled == false &&  sendSMSToClientModal.content.element.isPickedup == true) : false">Informação da entrega</b-form-select-option>
                  <b-form-select-option value="Tentativa de Contacto" v-if="sendSMSToClientModal.content != '' ? (sendSMSToClientModal.content.element.isDelivered == false && sendSMSToClientModal.content.element.isCancelled == false) : false">Tentativa de Contacto</b-form-select-option>
                  <b-form-select-option value="Outro">Outro</b-form-select-option>
                </b-form-select>
              </b-row>
              <b-row style="margin: auto;width: 100%;" v-if="this.smsToClientType != ''">Mensagem</b-row>
              <b-row style="margin: auto;width: 100%;" v-if="this.smsToClientType != ''">
                <b-form-textarea
                  id="smsClientText-text"
                  v-model="smsToClientText"
                  style="height:150px"
                ></b-form-textarea>
              </b-row>
            </b-modal>
            <b-modal :id="createVoucherModal.id" :title="createVoucherModal.title" :ok-disabled="voucherValue == ''" ok-variant="success" ok-title="Criar" cancel-title="Cancelar" @hide="resetCreateVoucherModal" @ok="postCreateVoucher(createVoucherModal.content, voucherValue)">
              <b-row style="margin: auto;width: 100%;">Valor do Voucher</b-row>
              <b-row style="margin: auto;width: 100%;">
                <b-form-input
                  id="voucherValue-input"
                  v-model="voucherValue"
                  type="number"
                  min=0
                  step="0.01"
                  style="text-align: right;margin-right:15px"
                ></b-form-input>
              </b-row>
              <b-row style="margin: auto;width: 100%;font-size: 10px">{{createVoucherModal.message1}}{{voucherValue}}{{createVoucherModal.message2}}</b-row>
            </b-modal>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import { BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BTr ,BTh, BFormSelect, BPagination, BTabs, BTab, BRow, BPopover, BTooltip, BFormTextarea, BFormSelectOption    } from 'bootstrap-vue'
import ActionDataService from "src/services/ActionDataService"
import DeliveryAgentsDataService from "src/services/DeliveryAgentsDataService"
import LogAcaoDataService from "src/services/LogAcaoDataService"
import SessionDataService from "src/services/SessionDataService"
import UserDataService from "src/services/UserDataService"
import draggable from 'vuedraggable'
import PurchaseOrderDataService from "src/services/PurchaseOrderDataService"
import PurchaseOrdersOCDataService from "src/services/PurchaseOrdersOCDataService"
import DeliveryAssignmentDataService from "src/services/DeliveryAssignmentDataService"
import RestauranteAssignedToZoneDataService from "src/services/RestauranteAssignedToZoneDataService"
import ComplaintDataService from "src/services/ComplaintDataService"
import CallDataService from "src/services/CallDataService"
import CreditComplaintDataService from "src/services/CreditComplaintDataService"
import TransactionDataService from "src/services/TransactionDataService"
import ExtratoDataService from "src/services/ExtratoDataService"
import AlertDataService from "src/services/AlertDataService"
import DiscountDataService from "src/services/DiscountDataService"
import StoreFrontDataService from "src/services/StoreFrontDataService"
import DebtComponent from "src/components/global/DebtComponent.vue"
import StaffPOObservations from "src/components/global/StaffPOObservations.vue"
import VoucherIcon from "src/components/global/VoucherIcon.vue"
import AddressMap from 'src/pages/Maps/AddressMap.vue'
export default {
  components: {
    DebtComponent,
    StaffPOObservations,
    VoucherIcon,
    AddressMap,
    LTable,
    Card,
    BTable, BCol, BInputGroup, BSpinner, BButton, BInputGroupAppend, BFormInput, BFormGroup, BModal, BCard, BFormCheckbox, BFormCheckboxGroup, BTr ,BTh, BFormSelect, BPagination, BTabs, BTab, BRow, BPopover, draggable, BTooltip, BFormTextarea, BFormSelectOption
  },

  mounted () {
    this.getCurrentLoginInfo()
    this.getAllActiveSuppliers()
    this.getDAs()
    this.getActions()
    this.getUsers()
    var today = new Date()
    this.pedidodate2 = today.getFullYear()+ "-" + (today.getMonth()+1) + "-" + today.getDate()
    today.setDate(today.getDate() - 7)
    this.pedidodate1 = today.getFullYear()+ "-" + (today.getMonth()+1) + "-" + today.getDate()
    this.tod = new Date()
    this.tod.setHours(0,0,0,0)
    this.yesterday = new Date()
    this.yesterday.setDate(today.getDate() - 1)
    this.yesterday.setHours(0,0,0,0)
  },

  data() {
    return {
      timer: null,
      fields: [
        {
          key: 'logDateTime',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'user.userName',
          label: 'Efetuada Por',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'poNum',
          label: 'PO',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'daUserName',
          label: 'Estafeta',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'acao.name',
          label: 'Ação',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'previous',
          label: 'Antes',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'parameters',
          label: 'Depois',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'observations',
          label: 'Observações',
          tdClass: 'text-left',
          thClass: 'text-white'
        }
      ],
      items: [],
      actionData: [],
      isBusy: true,
      sortBy: 'estafeta_horaRest',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: 'id',
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,

      das: [],
      actions: [],
      users: [],
      search: false,

      pedidodate1: '',
      pedidodate2: '',
      estafeta: '',
      cliente: '',
      user: '',
      action: '',

      histFields: [
        {
          key: 'logDateTime',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'user.userName',
          label: 'Efetuada Por',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'acao.name',
          label: 'Ação',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'previous',
          label: 'Antes',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'parameters',
          label: 'Depois',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      pedidosClienteFields: [
        {
          key: 'estado',
          label: 'Estado',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'id',
          label: 'PO',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'userNameEstafeta',
          label: 'Estafeta',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'datedeliveryTimeWindowEnd1',
          label: 'Data Entrega',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'supplierName',
          label: 'Restaurante',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'orderValue',
          label: 'Valor',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'desvioDelivery',
          label: 'Desvio Entrega',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'realizado',
          label: 'Realizado',
          tdClass: 'text-left',
          thClass: 'text-white'
        }
      ],
      ordersClientAddressInfoFields: [
        {
          key: 'deliveryCustomerName',
          label: 'Cliente',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'nrOrders',
          label: 'Nº Pedidos',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'lastOrder',
          label: 'Data Último Pedido',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'avgOrderValueByCliente',
          label: 'Valor Médio',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'address',
          label: 'Morada',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      complaintClienteFields: [
        {
          key: 'complaintDateTime',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'poNum',
          label: 'PO',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'newDelivery',
          label: 'Nova Entrega',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'supplierName',
          label: 'Restaurante',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'devolutionValue',
          label: 'Valor',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'txDeliveryValue',
          label: 'Tx Entrega',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'totalCredit',
          label: 'Total Crédito',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'creditAvailable',
          label: 'Disponivel',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'devolutionType',
          label: 'Método Devolução',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'responsability',
          label: 'Resp. Tx Entrega',
          tdClass: 'text-left',
          thClass: 'text-white'
        }
      ],
      complaintClienteFields2: [
        {
          key: 'complaintDateTime',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'poNum',
          label: 'PO',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'newDelivery',
          label: 'Nova Entrega',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'supplierName',
          label: 'Restaurante',
          tdClass: 'text-left',
          thClass: 'text-white'
        },
        {
          key: 'totalCredit',
          label: 'Total',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'creditAvailable',
          label: 'Disponivel',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'devolutionType',
          label: 'Método Devolução',
          tdClass: 'text-left',
          thClass: 'text-white'
        }
      ],
      creditUsedFields: [
        {
          key: 'creditComplaintDateTime',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'poNum',
          label: 'PO',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'creditUsed',
          label: 'Crédito Utilizado',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'state',
          label: 'Estado',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      extratoFields: [
        {
          key: 'lineDate',
          label: 'Data/Hora',
          tdClass: 'text-center',
          thClass: 'text-white',
          thStyle: { width: "18%" }
        },
        {
          key: 'source',
          label: 'Canal',
          tdClass: 'text-center',
          thClass: 'text-white',
          thStyle: { width: "10%" }
        },
        {
          key: 'lineType',
          label: 'Tipo Movimento',
          tdClass: 'text-center',
          thClass: 'text-white',
          thStyle: { width: "10%" }
        },
        {
          key: 'subTotal',
          label: 'Valor do Restaurante',
          tdClass: 'text-right',
          thClass: 'text-white',
          thStyle: { width: "9%" }
        },
        {
          key: 'deliveryFee',
          label: 'Taxa de Entrega',
          tdClass: 'text-right',
          thClass: 'text-white',
          thStyle: { width: "9%" }
        },
        {
          key: 'total',
          label: 'Total',
          tdClass: 'text-right',
          thClass: 'text-white',
          thStyle: { width: "9%" }
        },
        {
          key: 'amountDue',
          label: 'Valor em Divida',
          tdClass: 'text-right',
          thClass: 'text-white',
          thStyle: { width: "9%" }
        },
        {
          key: 'paymentOption',
          label: 'Opção Pagamento',
          tdClass: 'text-center',
          thClass: 'text-white',
          thStyle: { width: "9%" }
        },
        {
          key: 'paymentValue',
          label: 'Pago Por',
          tdClass: 'text-right',
          thClass: 'text-white',
          thStyle: { width: "17%" }
        }
      ],
      onlinePaymentsFields: [
        {
          key: 'transactionDate',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'paymentType',
          label: 'Método Pagamento',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'virtualAccountId',
          label: 'Conta',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'transactionType',
          label: 'Tipo',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'value',
          label: 'Valor',
          tdClass: 'text-right',
          thClass: 'text-white'
        },
        {
          key: 'status',
          label: 'Estado',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'source',
          label: 'Origem',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      alertClienteFields: [
        {
          key: 'alertDate',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'createdBy',
          label: 'Criado Por',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'alertType',
          label: 'Tipo Alerta',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'recurrent',
          label: 'Recorrente?',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'poNum',
          label: 'PO',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'delay',
          label: 'Atraso',
          tdClass: 'text-center',
          thClass: 'text-white'
        },,
        {
          key: 'supplierName',
          label: 'Restaurante',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'completedDate',
          label: 'Data Fecho',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'completedBy',
          label: 'Fechado Por',
          tdClass: 'text-center',
          thClass: 'text-white'
        }
      ],
      poAlertsFields: [
        {
          key: 'alertDate',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'alertType',
          label: 'Tipo Alerta',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'workingUser',
          label: 'Responsável',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'completedDate',
          label: 'Data Fecho',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
      ],
      smsMessagesClienteFields: [
        {
          key: 'created',
          label: 'Data',
          tdClass: 'text-center',
          thClass: 'text-white'
        },
        {
          key: 'text',
          label: 'Mensagem',
          tdClass: 'text-left',
          thClass: 'text-white'
        }
      ],
      tod: new Date(),
      yesterday: new Date(),
      items: [],
      poData: [],
      isBusy: true,
      items2: [],
      poData2: [],
      isBusy2: true,
      sortBy: 'estafeta_horaRest',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: 'id',
      tabIndex: 0,
      das:[],
      dasOnline:[],
      est: '11',
      estDescr: '',
      estState: '',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      poInfoModal: {
        id: 'poInfo-modal',
        title: '',
        content: '',
        pedido: '',
      },
      // atribuicaoModal: {
      //   id: 'atribuicao-modal',
      //   title: '',
      //   content: ''
      // },
      // atribuicao: '',
      cancelarModal: {
        id: 'cancelar-modal',
        title: '',
        content: ''
      },
      cancelar: '',
      cancelarGiftCardModal: {
        id: 'cancelarGiftCard-modal',
        title: '',
        content: ''
      },
      retirarModal: {
        id: 'retirar-modal',
        title: '',
        content: ''
      },
      reAtivarModal: {
        id: 'reAtivar-modal',
        title: '',
        content: ''
      },
      fecharModal: {
        id: 'fechar-modal',
        title: '',
        content: ''
      },
      reclamacaoModal: {
        id: 'reclamacao-modal',
        title: '',
        content: ''
      },
      reclamacao: '',
      newDeliver: true,
      withPartnerDevolution: false,
      isReplica: false,
      sameDA: false,
      responsability: '',
      devolution: 'Register',
      txDeliver: false,
      txDeliverValue: 0,
      txDeliverValueState: null,
      devolutionValue: 0,
      devolutionValueState: null,
      devolutionType: '',
      existsDevolution: false,
      alterarPedidoModal: {
        id: 'alterarPedido-modal',
        title: '',
        content: ''
      },
      alterarPedido: '',
      alterarPedidoTotal: '',
      ativarCreditoModal: {
        id: 'ativarCredito-modal',
        title: '',
        content: ''
      },
      ativarCredito: '',
      addAmountDueModal: {
        id: 'addAmountDue-modal',
        title: '',
        content: ''
      },
      addAmountDue: '',
      alterarTimeWindowModal: {
        id: 'alterarTimeWindow-modal',
        title: '',
        content: ''
      },
      alterarTimeWindow: 0,
      alterarTimeWindowMin: '',
      alterarTimeWindowHora: '',
      alterarTimeWindowDia: '',
      newDateAlterarTimeWindow: '',
      atribuicaoMaisModal: {
        id: 'atribuicaoMais-modal',
        title: '',
        content: ''
      },
      atribuicaoMais: '',
      newAssign: true,
      alterarEstafetaModal: {
        id: 'alterarEstafeta-modal',
        title: '',
        content: ''
      },
      alterarEstafeta: '',
      adicionarKmsModal: {
        id: 'adicionarKms-modal',
        title: '',
        content: ''
      },
      adicionarKms: '',
      adicionarKmsTotal: '',
      ajustarDeliveryFeeModal: {
        id: 'ajustarDeliveryFee-modal',
        title: '',
        content: ''
      },
      codautInfoModal:{
        id: 'codaut-modal',
        title: '',
        content: ''
      },
      alterarOrdensModal: {
        id: 'alterarOrdens-modal',
        title: '',
        content: '',
        po: ''
      },
      creditUsedModal: {
        id: 'creditUsed-modal',
        title: '',
        content: ''
      },
      creditUsed: [],
      creditRetrieved: false,
      extratoModal: {
        id: 'extrato-modal',
        title: '',
        content: ''
      },
      onlinePayments: [],
      extrato: [],
      tabIndexExtrato: 0,
      isBusyExtrato: true,
      extratoRetrieved: false,
      phonesPayments: null,
      isBusyOnlinePayments: true,
      paymentsRetrieved: false,
      voucherModal: {
        id: 'voucher-modal',
        title: '',
        content: '',
        voucher: '',
        date: ''
      },
      obsDAModal: {
        id: 'obsDA-modal',
        title: '',
        content: ''
      },
      lastObsDA: '',
      clientAdressModal: {
        id: 'clientAdress-modal',
        title: '',
        content: '',
        location: {
          lat: 0,
          lng: 0
        }
      },
      rua: '',
      porta: '',
      andar: '',
      postal: '',
      localidade: '',
      addressUpdated: false,
      changeAdressModal: {
        id: 'changeAdress-modal',
        title: '',
        content: ''
      },
      kmsAux: 0,
      amountAux: 0,
      kmsDif: 0,
      amountDif: 0,
      changeAmounts: true,
      changeAddressGlobally: false,
      refundModal: {
        id: 'refund-modal',
        title: '',
        content: ''
      },
      refunded: 0,
      refundDue: 0,
      isBusyCreditUsed: true,
      mbwayModal: {
        id: 'mbway-modal',
        title: '',
        content: ''
      },
      telMB: '',
      observation: '',
      alertModal: {
        id: 'alert-modal',
        title: '',
        content: ''
      },
      description: '',
      clientSupplier: false,
      recurrent: true,
      alertDescriptionModal: {
        id: 'alertDesc-modal',
        title: '',
        content: '',
        po: ''
      },
      sendPODescriptionModal: {
        id: 'sendPODescription-modal',
        title: '',
        content: ''
      },
      telPartner: '',
      telPartnerSelectBox: '',
      poDesc: '',
      sendSMSToClientModal: {
        id: 'sendSMSToClient-modal',
        title: '',
        content: ''
      },
      telClient: '',
      createVoucherModal: {
        id: 'createVoucher-modal',
        title: '',
        content: '',
        message1: '',
        message2: ''
      },
      voucherValue: '',
      smsToClientText: '',
      smsToClientType: '',
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      codaut: '',
      codauttext: '',
      seeCode: false,
      listDone:[],
      listDoneInitial: [],
      lastUpdated: '',
      seeHistorico: false,
      history: [],
      showPedidosCliente: false,
      pedidosCliente: [],
      showOrdersClientAddressInfo: false,
      ordersClientAddressInfo: [],
      showComplaintCliente: false,
      complaintCliente: [],
      showAlertCliente: false,
      alertCliente: [],
      showSMSMessagesCliente: false,
      smsMessagesCliente: [],
      showPOAlerts: false,
      poAlerts: [],
      mapsLink: "https://www.google.pt/maps/dir/41.210403%09-8.764331/41.210403,-8.564331/@41.2905862,-8.7421699,12",
      poMapsLink: "https://www.google.pt/maps/dir/41.210403%09-8.764331/41.210403,-8.564331/@41.2905862,-8.7421699,12",
      leftMapsLink: "https://www.google.pt/maps/dir/41.210403%09-8.764331/41.210403,-8.564331/@41.2905862,-8.7421699,12",
      restLink: '',
      fatuLink: '',
      pedidoTest: {
        "order_id": 1234,
        "user_id": 78,
        "clientName": "Pedro",
        "clientNIF": 111111111,
        "orderDAPickupDate": "2021-03-26T13:18:06.957+00:00",
        "restaurantObservations": "Teste",
        "orderData": "{\"menus\":[{\"menuName\":\"Hamburguer Só Bitaites\",\"menuPrice\":7.5},{\"menuName\":\"Vitela assada no forno\",\"menuPrice\":0,\"subMenus\":[{\"subMenuTitle\":\"1/2 Dose\",\"subMenuPrice\":13.25}]}],\"subTotalValue\":20.75,\"deliveryFee\":6.66,\"totalValue\":27.41}",
        "id": 2
      },
      pedidoTestDone: {},
      currentUser: {},
      currentUserRoles: [],
      currentUserPermissions: [],
      currentOrderValue: '',
      currentDistance: '',
      currentAmountDue: '',
      currentDeliveryFee: '',
      currentDeliveryTimeWindowEnd: '',
      currentDeliveryTimeWindowEndDate: '',
      currentNumPedidos: '',
      avgOrderValue: '',
      currentCreditAvailable: 0,
      onlinePaymentsValue: 0,
      search: false,
      pedidodate1: '',
      pedidodate2: '',
      estadoSelected: '',
      pedido: '',
      ponum:'',
      restaurante: '',
      cliente: '',
      estafeta: '',
      pagamento: '',
      valor1: '',
      valor2: '',
      image: '',
      show: false,

      voucher: '',
      voucherId: '',

      suppliers: [],

      alertDeleteModal: {
        id: 'alertDelete-modal',
        title: '',
        content: '',
        po: ''
      },
      alertEditModal: {
        id: 'alertEdit-modal',
        title: '',
        content: '',
        po: ''
      },
      oldReason: '',
      complaintDeleteModal: {
        id: 'complaintDelete-modal',
        title: '',
        content: ''
      },
      complaintEditModal: {
        id: 'complaintEdit-modal',
        title: '',
        content: ''
      },

      existsCall: '',
      sumDistances: '',

      otherPhones: '',
      showOtherPhones: false,

      doingCall: false,

      telState: null,

      daInTransit: false,
    };
  },
  methods: {
    getLogsAcaoByPesquisa(){
      var acId = ''
      var ac = this.actions.filter(a => a.name == this.action)[0];
      if(ac != null){
        acId = ac.id
      }
      var daId = ''
      var da = this.das.filter(d => d.userName == this.estafeta)[0];
      if(da != null){
        daId = da.daId
      }
      var usId = ''
      var us = this.users.filter(u => u.userName == this.user)[0];
      if(us != null){
        usId = us.id
      }
      LogAcaoDataService.GetLogAcaoByPesquisa({acaoId: acId, userId: usId, daId: daId, client: this.cliente, searchDataI: this.pedidodate1, searchDataF: this.pedidodate2, pageIndex: this.currentPage, pageSize: this.perPage} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.actionData = this.formatHistory(response.data.result.items), this.isBusy=false, this.totalRows = response.data.result.totalCount))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getDAs(){
      DeliveryAgentsDataService.GetAllDeliveryAgents({pageNumber: 1, pageSize: 1000, sortOrder: "userName"} , { headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => (this.das = response.data.items))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getActions(){
      ActionDataService.GetAllAcao({MaxResultCount: 1000} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.actions = response.data.result.items))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getUsers(){
      UserDataService.GetAllUsers({MaxResultCount: 1000} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.users = response.data.result.items))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatHistory(hist){
      var newHist = []
      hist.forEach(element => {
        var date = new Date(element.logDateTime)
        var hour = String(date.getHours()).padStart(2, '0');
        var min = String(date.getMinutes()).padStart(2, '0');
        var day = String(date.getDate()).padStart(2, '0');
        var mon = String(date.getMonth()+1).padStart(2, '0');
        var year = String(date.getFullYear());
        date = day + "/" + mon + "/" + year + " " + hour + ":" + min;
        newHist.push({
          acao: element.acao,
          user: element.user,
          previous: element.previous,
          parameters: element.parameters,
          poNum: element.poNum,
          daId: element.daId,
          daUserName: element.daUserName,
          logDateTime: date,
          observations: element.observations,
          _showDetails: true
        })
      })
      return newHist
    },
    toogleSearch(){
      this.search = true
      this.postSearch()
    },
    postSearch(){
      this.actionData = []
      this.isBusy = true
      this.getLogsAcaoByPesquisa()
    },
    handlePageChange(value) {
      this.isBusy = true
      this.currentPage = value;
      this.getLogsAcaoByPesquisa()
    },
    handlePageSizeChange(event) {
      this.isBusy = true
      this.pageSize = event;
      this.currentPage = 1;
      this.getLogsAcaoByPesquisa()
    },
    sortingChanged(ctx) {
      this.isBusy = true
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.getLogsAcaoByPesquisa()
    },

    getDAsFromSupplier(latitudeSupplier, longitudeSupplier){
      DeliveryAgentsDataService.GetAllDeliveryAgentsOnlineFromSupplier({latitudeSupplier: latitudeSupplier, longitudeSupplier: longitudeSupplier} , { headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => (this.dasOnline = response.data))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    info(item, index, button) {
      this.infoModal.title = ``;
      this.infoModal.po =  `PONum: ${item.id}`+`\nEstado: ${item.estado}`+`\nEstafeta: ${item.nomeEstafeta}`+`\nRestaurante: ${item.supplierName}`+`\nData Criação: ${item.element.poDateTime}`+`\nDistância: ${item.km}`+`\nHora Levantamento: ${item.deliveryTimeWindowStart1}`+`\nHora Entrega: ${item.deliveryTimeWindowEnd1}`+`\nValor: ${item.orderValue}`+`\nValor em Divida: ${item.element.amountDue}`+`\nAuthoratization Code: ${item.element.authorizationCode}`;
      this.infoModal.content = item;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    poInfo(item, index, button) {
      this.tabIndex = 0
      this.seeHistorico = false
      this.showPOAlerts = false
      if(item.steps != null && item.steps != ''){
        this.formatAssignmentMiddle(item, item.element.assignmentId, item.steps)
        if(item.element.assignment.numOrders > 1){
          this.sumAssignmentDistances(item.id)
        }
      }
      this.currentOrderValue = this.round(item.element.orderValue)
      this.currentAmountDue = this.round(item.element.amountDue)
      this.currentDeliveryFee = this.round(item.element.deliveryFee)
      this.alterarPedidoTotal = this.round(item.element.orderValue)
      this.adicionarKmsTotal = this.round(item.totalDistance)
      this.currentDistance = this.round(item.totalDistance)
      Promise.all([
        this.existsCallByPoNum(item.id),
        this.getDeliveryFeeFromLogs(item.id),
        this.getCreditAvailableByCliente(item.element.customerPhone, item.element.supplierId),
        this.getPaymentsValueByPO(item.orderId),
        this.gethistory(item.id),
        this.getVoucherByPO(item.id),
        this.getPOAlerts(item.id)
      ])
      this.poInfoModal.title = ``
      this.poInfoModal.content = item
      this.poInfoModal.pedido = this.pedidoTestDone
      var str = '/'+ item.element.pickupGpsLatitude+","+item.element.pickupGpsLongitude+"/"+item.element.deliveryGpsLatitude+","+item.element.deliveryGpsLongitude
      this.poMapsLink = "https://www.google.pt/maps/dir"+str+"/data=!3m1!4b1!4m4!4m3!2m1!2b1!3e0"
      // this.restLink = "https://www.comermelhor.pt/wp-admin/post.php?post="+item.element.supplierId+"&action=edit"
      this.restLink = "https://rest.comermelhor.pt/restaurants/"+item.element.supplierId
      this.fatuLink = "https://comermelhordaappweb-prod.azurewebsites.net/api/v1/PurchaseOrders/"+item.element.poNum+"/SupplierReceipt"
      this.$root.$emit('bv::show::modal', this.poInfoModal.id, button)
    },
    cancelarInfo(item, index, button) {
      this.cancelarModal.title = `Certeza que pretende cancelar o pedido ${item.id}?`
      this.cancelarModal.content = item
      this.$root.$emit('bv::show::modal', this.cancelarModal.id, button)
    },
    cancelarGiftCardInfo(item, index, button) {
      this.cancelarGiftCardModal.title = `Certeza que pretende cancelar o pedido ${item.id}?`
      this.cancelarGiftCardModal.content = item
      this.$root.$emit('bv::show::modal', this.cancelarGiftCardModal.id, button)
    },
    retirarInfo(item, index, button) {
      this.retirarModal.title = `Certeza que pretende retirar o estafeta do pedido ${item.id}?`
      this.retirarModal.content = item
      this.$root.$emit('bv::show::modal', this.retirarModal.id, button)
    },
    reAtivarInfo(item, index, button) {
      this.reAtivarModal.title = `Certeza que pretende reAtivar o pedido ${item.id}?`
      this.reAtivarModal.content = item
      this.$root.$emit('bv::show::modal', this.reAtivarModal.id, button)
    },
    fecharInfo(item, index, button) {
      this.fecharModal.title = `Certeza que pretende forçar o fecho do pedido ${item.id}?`
      this.fecharModal.content = item
      this.$root.$emit('bv::show::modal', this.fecharModal.id, button)
    },
    reclamacaoInfo(item, index, button) {
      this.reclamacaoModal.title = `Reclamação no pedido ${item.id}`
      this.reclamacaoModal.content = item
      this.txDeliverValue = this.currentDeliveryFee
      this.existsDevolutionByPoNum(item.id)
      this.$root.$emit('bv::show::modal', this.reclamacaoModal.id, button)
    },
    alterarPedidoInfo(item, index, button) {
      this.alterarPedidoModal.title = `Alteração nos produtos do pedido ${item.id}`
      this.alterarPedidoModal.content = item
      this.$root.$emit('bv::show::modal', this.alterarPedidoModal.id, button)
    },
    ativarCreditoInfo(item, index, button) {
      this.ativarCreditoModal.title = `Ativar Credito no pedido ${item.id}`
      this.ativarCreditoModal.content = item
      this.$root.$emit('bv::show::modal', this.ativarCreditoModal.id, button)
    },
    addAmountDueInfo(item, index, button) {
      this.addAmountDueModal.title = `Adicionar Valor em Divida no pedido ${item.id}`
      this.addAmountDueModal.content = item
      this.$root.$emit('bv::show::modal', this.addAmountDueModal.id, button)
    },
    alterarTimeWindowInfo(item, index, button) {
      this.alterarTimeWindowModal.title = `Alterar horário do pedido ${item.id}`
      this.alterarTimeWindowModal.content = item
      this.currentDeliveryTimeWindowEnd = item.element.deliveryTimeWindowEnd
      const date = new Date(this.currentDeliveryTimeWindowEnd)
      this.currentDeliveryTimeWindowEndDate = String(date.getDate()).padStart(2, '0') + "/" + String((date.getMonth()+1)).padStart(2, '0') + "/" + date.getFullYear() + " " + item.deliveryTimeWindowEnd1
      this.$root.$emit('bv::show::modal', this.alterarTimeWindowModal.id, button)
    },
    atribuicaoMaisInfo(item, index, button) {
      this.getDAsFromSupplier(item.element.pickupGpsLatitude, item.element.pickupGpsLongitude)
      this.atribuicaoMaisModal.title = `Atribuir o pedido ${item.id} a Estafeta`
      this.atribuicaoMaisModal.content = item
      this.$root.$emit('bv::show::modal', this.atribuicaoMaisModal.id, button)
    },
    alterarEstafetaInfo(item, index, button) {
      this.getDAsFromSupplier(item.element.pickupGpsLatitude, item.element.pickupGpsLongitude)
      this.alterarEstafetaModal.title = `Alterar Estafeta`
      this.alterarEstafetaModal.content = item
      this.$root.$emit('bv::show::modal', this.alterarEstafetaModal.id, button)
    },
    adicionarKmsInfo(item, index, button) {
      this.adicionarKmsModal.title = `Ajustar Kms`
      this.adicionarKmsModal.content = item
      this.$root.$emit('bv::show::modal', this.adicionarKmsModal.id, button)
    },
    ajustarDeliveryFeeInfo(item, index, button) {
      this.ajustarDeliveryFeeModal.title = `Ajustar Taxa de Entrega`
      this.ajustarDeliveryFeeModal.content = item
      // this.txDeliverValue = this.currentDeliveryFee
      this.$root.$emit('bv::show::modal', this.ajustarDeliveryFeeModal.id, button)
    },
    codautInfo(item, index, button) {
      this.codautInfoModal.title = 'Qual é a razão?'
      this.codautInfoModal.content = item
      this.$root.$emit('bv::show::modal', this.codautInfoModal.id, button)
    },
    alterarOdensInfo(po, item, index, button) {
      this.alterarOrdensModal.title = 'Tem a certeza que pretende alterar a ordem dos pedidos?'
      this.alterarOrdensModal.content = item
      this.alterarOrdensModal.po = po
      this.$root.$emit('bv::show::modal', this.alterarOrdensModal.id, button)
    },
    resetAlterarOdensModal() {
      this.alterarOrdensModal.title = ''
      this.alterarOrdensModal.content = ''
      this.alterarOrdensModal.po = ''
      this.observation = ''
    },
    resetAlterarEstafetaModal() {
      this.alterarEstafetaModal.title = ''
      this.alterarEstafetaModal.content = ''
      this.alterarEstafeta = ''
      this.observation = ''
    },
    resetAdicionarKmsModal() {
      this.adicionarKmsModal.title = ''
      this.adicionarKmsModal.content = ''
      this.adicionarKms = ''
      this.adicionarKmsTotal = ''
      this.observation = ''
    },
    resetAjustarDeliveryFeeModal() {
      this.ajustarDeliveryFeeModal.title = ''
      this.ajustarDeliveryFeeModal.content = ''
      this.txDeliverValue = 0
      this.txDeliverValueState = null
      this.observation = ''
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
      this.infoModal.po = ''
      this.infoModal.isAssignedToDA = ''
    },
    resetPOInfoModal() {
      this.toogleSearch()
      this.poInfoModal.title = ''
      this.poInfoModal.content = ''
      this.poInfoModal.pedido =  ''
      this.seeCode = false
      this.codaut = ''
      this.codauttext = ''
      this.currentOrderValue = ''
      this.currentAmountDue = ''
      this.currentDeliveryFee = ''
      this.currentNumPedidos = ''
      this.avgOrderValue = ''
      this.seeHistorico = false
      this.showPOAlerts = false
      this.history = []
      this.poAlerts = []
      this.showPedidosCliente = false
      this.pedidosCliente = []
      this.showOrdersClientAddressInfo = false
      this.ordersClientAddressInfo = []
      this.showComplaintCliente = false
      this.complaintCliente = []
      this.showAlertCliente = false
      this.alertCliente = []
      this.showSMSMessagesCliente = false
      this.smsMessagesCliente = []
      this.listDone = []
      this.mapsLink = ''
      this.poMapsLink = ''
      this.leftMapsLink = ''
      this.restLink = ''
      this.fatuLink = ''
      this.currentCreditAvailable = 0
      this.onlinePaymentsValue = 0
      this.image = ''
      this.voucher = ''
      this.voucherId = ''
      this.existsCall = ''
      this.sumDistances = ''
      this.otherPhones = ''
      this.showOtherPhones = false
      this.doingCall = false
      this.daInTransit = false
    },
    resetCancelarModal() {
      this.cancelarModal.title = ''
      this.cancelarModal.content = ''
      this.cancelar = ''
      this.observation = ''
    },
    resetCancelarGiftCardModal() {
      this.cancelarGiftCardModal.title = ''
      this.cancelarGiftCardModal.content = ''
    },
    resetRetirarModal() {
      this.retirarModal.title = ''
      this.retirarModal.content = ''
      this.observation = ''
    },
    resetReAtivarModal() {
      this.reAtivarModal.title = ''
      this.reAtivarModal.content = ''
      this.observation = ''
    },
    resetFecharModal() {
      this.fecharModal.title = ''
      this.fecharModal.content = ''
      this.observation = ''
    },
    resetReclamacaoModal() {
      this.reclamacaoModal.title = ''
      this.reclamacaoModal.content = ''
      this.reclamacao = ''
      this.observation = ''
      this.newDeliver = true,
      this.withPartnerDevolution = false,
      this.isReplica = false,
      this.sameDA = false,
      this.responsability = '',
      this.devolution = 'Register',
      this.txDeliver = false,
      this.txDeliverValue = 0,
      this.txDeliverValueState = null
      this.devolutionValue = 0,
      this.devolutionValueState = null
      this.devolutionType = ''
      this.existsDevolution = false
    },
    resetAlterarPedidoModal() {
      this.alterarPedidoModal.title = ''
      this.alterarPedidoModal.content = ''
      this.alterarPedido = ''
      this.alterarPedidoTotal = this.currentOrderValue
      this.observation = ''
    },
    resetAtivarCreditoModal() {
      this.ativarCreditoModal.title = ''
      this.ativarCreditoModal.content = ''
      this.ativarCredito = ''
      this.observation = ''
      this.showComplaintCliente = false
      this.complaintCliente = []
    },
    resetAddAmountDueModal() {
      this.addAmountDueModal.title = ''
      this.addAmountDueModal.content = ''
      this.addAmountDue = ''
      this.observation = ''
    },
    resetAlterarTimeWindowModal() {
      this.alterarTimeWindowModal.title = ''
      this.alterarTimeWindowModal.content = ''
      this.alterarTimeWindow = 0
      this.alterarTimeWindowMin = ''
      this.alterarTimeWindowHora = ''
      this.alterarTimeWindowDia = ''
      this.newDateAlterarTimeWindow = ''
      this.currentDeliveryTimeWindowEnd = ''
      this.currentDeliveryTimeWindowEndDate = ''
      this.observation = ''
    },
    resetAtribuicaoMaisModal() {
      this.atribuicaoMaisModal.title = ''
      this.atribuicaoMaisModal.content = ''
      this.atribuicaoMais = ''
      this.observation = ''
      this.newAssign = true
    },
    resetCodautInfoModal() {
      this.codautInfoModal.title = ''
      this.codautInfoModal.content = ''
      this.codaut = ''
      this.codauttext = ''
    },
    creditUsedInfo(item, index, button) {
        this.creditUsedModal.title = 'Crédito Utilizado'
        this.creditUsedModal.content = item
        this.getCreditUsedByComplaint(item.id)
        this.$root.$emit('bv::show::modal', this.creditUsedModal.id, button)
    },
    resetCreditUsedModal() {
      this.creditUsedModal.title = ''
      this.creditUsedModal.content = ''
      this.creditUsed = []
      this.creditRetrieved = false
    },
    extratoInfo(item, index, button) {
      this.extratoModal.title = ''
      this.extratoModal.content = item
      this.getOnlinePayments(item.orderId)
      this.getExtrato(item.id)
      this.$root.$emit('bv::show::modal', this.extratoModal.id, button)
    },
    resetExtratoModal() {
      this.extratoModal.title = ''
      this.extratoModal.content = ''
      this.onlinePayments = []
      this.paymentsRetrieved = false
      this.isBusyOnlinePayments = true
      this.extrato = []
      this.extratoRetrieved = false
      this.isBusyExtrato = true
      this.tabIndexExtrato = 0
    },
    voucherInfo(item1, index, button) {
      this.voucherModal.title = 'Voucher'
      this.voucherModal.content = item1
      this.voucherModal.voucher = item1.value + (item1.voucherType_id == 1? '% ': '€ ') + (item1.voucherDiscountType_id == 1? 'na ': 'no') + item1.voucherDiscountType
      this.voucherModal.date = 'Voucher válido até ' + this.getDateFormated(item1.endDate)
      this.$root.$emit('bv::show::modal', this.voucherModal.id, button)
    },
    resetVoucherModal() {
      this.voucherModal.title = ''
      this.voucherModal.content = ''
      this.voucherModal.voucher = ''
      this.voucherModal.date = ''
    },
    obsDAInfo(item, index, button) {
        this.obsDAModal.title = 'Editar Observações para o Estafeta'
        this.obsDAModal.content = item
        this.observation = item.element.obsForDelivery
        this.lastObsDA = item.element.obsForDelivery
        this.$root.$emit('bv::show::modal', this.obsDAModal.id, button)
    },
    resetObsDAModal() {
      this.alterarOrdensModal.title = ''
      this.alterarOrdensModal.content = ''
      this.observation = ''
      this.lastObsDA = ''
    },
    clientAdressInfo(item, index, button) {
      this.clientAdressModal.title = 'Editar Morada do Cliente'
      this.clientAdressModal.content = item
      this.clientAdressModal.location.lat = item.element.deliveryGpsLatitude
      this.clientAdressModal.location.lng = item.element.deliveryGpsLongitude
      this.rua = item.element.deliveryStreet
      this.porta = item.element.deliveryDoorNum
      this.andar = item.element.deliveryFloorNum
      this.postal = item.element.deliveryZipCode
      this.localidade = item.element.deliveryCity
      this.$root.$emit('bv::show::modal', this.clientAdressModal.id, button)
    },
    resetClientAdressModal() {
      this.clientAdressModal.title = ''
      this.clientAdressModal.content = ''
      this.clientAdressModal.location.lat = 0
      this.clientAdressModal.location.lng = 0
      this.rua = '',
      this.porta = '',
      this.andar = '',
      this.postal = '',
      this.localidade = '',
      this.observation = '',
      this.addressUpdated = false
      this.kmsDif = 0
      this.amountDif = 0
      this.changeAmounts = true
      this.changeAddressGlobally = false
    },
    getCoordsByAddress(po, rua, porta, andar, postal, localidade){
      var address = rua + ' ' + porta + ', ' + postal + ' ' + localidade + ", Portugal";

      var geocoder = new google.maps.Geocoder();
      geocoder.geocode( { 'address': address}, (response, status) => {

        if (status == google.maps.GeocoderStatus.OK) {
          var latitude = response[0].geometry.location.lat();
          var longitude = response[0].geometry.location.lng();

          console.log(latitude + ' - ' + longitude)
          this.clientAdressModal.location.lat = latitude
          this.clientAdressModal.location.lng = longitude
          this.addressUpdated = true
          this.getChangeAdressInfo(po, rua, porta, andar, postal, localidade, this.clientAdressModal.location)
        }
      });
    },
    changeAdressInfo(item, index, button) {
      this.changeAdressModal.title = 'Informações da alteração de morada'
      this.changeAdressModal.content = item
      this.$root.$emit('bv::show::modal', this.changeAdressModal.id, button)
    },
    resetChangeAdressModal() {
      this.changeAdressModal.title = ''
      this.changeAdressModal.content = ''
      this.kmsAux = 0
      this.amountAux = 0
    },
    refundInfo(item, index, button) {
      this.refundModal.title = 'Informações da devolução'
      this.refundModal.content = item
      this.$root.$emit('bv::show::modal', this.refundModal.id, button)
    },
    resetRefundModal() {
      this.refundModal.title = ''
      this.refundModal.content = ''
      this.refunded = 0
      this.refundDue = 0
    },
    mbwayInfo(item, index, button) {
      this.mbwayModal.title = 'Pedir Pagamento MBWAY'
      this.mbwayModal.content = item
      this.telMB = item.element.customerPhone
      this.getPhonesByPayments(item.element.customerPhone)
      this.$root.$emit('bv::show::modal', this.mbwayModal.id, button)
    },
    resetmbwayModal() {
      this.mbwayModal.title = ''
      this.mbwayModal.content = ''
      this.telMB = ''
      this.phonesPayments = null
    },
    alertInfo(item, index, button) {
      this.alertModal.title = 'Criar Alerta'
      this.alertModal.content = item
      this.$root.$emit('bv::show::modal', this.alertModal.id, button)
    },
    resetAlertModal() {
      this.alertModal.title = ''
      this.alertModal.content = ''
      this.description = ''
      this.clientSupplier = false
      this.recurrent = true
    },
    alertDescriptionInfo(item, po, index, button) {
      this.alertDescriptionModal.title = 'Descrição do Alerta'
      this.alertDescriptionModal.content = item
      this.alertDescriptionModal.po = po
      this.$root.$emit('bv::show::modal', this.alertDescriptionModal.id, button)
    },
    resetAlertDescriptionModal() {
      this.alertDescriptionModal.title = ''
      this.alertDescriptionModal.content = ''
      this.alertDescriptionModal.po = ''
    },

    alertDeleteInfo(item, po, index, button) {
      this.alertDeleteModal.title = `Tem a certeza que pretende eliminar o alerta?`
      this.alertDeleteModal.content = item
      this.alertDeleteModal.po = po
      this.$root.$emit('bv::show::modal', this.alertDeleteModal.id, button)
    },
    resetAlertDeleteModal() {
      this.alertDeleteModal.title = ''
      this.alertDeleteModal.content = ''
      this.alertDeleteModal.po = ''
    },
    alertEditInfo(item, po, index, button) {
      this.alertEditModal.title = `Editar alerta`
      this.alertEditModal.content = item
      this.alertEditModal.po = po

      this.oldReason = item.reason
      this.description = item.reason.split("</strong>")[1]
      this.clientSupplier = item.alertTypeId == 2? true : false,
      this.recurrent = item.recurrent == 'Sim'? false: true,
      this.$root.$emit('bv::show::modal', this.alertEditModal.id, button)
    },
    resetAlertEditModal() {
      this.alertEditModal.title = ''
      this.alertEditModal.content = ''
      this.alertEditModal.po = ''
      this.oldReason = ''

      this.description = ''
      this.clientSupplier = false
      this.recurrent = true
    },
    complaintDeleteModalInfo(item, index, button) {
      this.complaintDeleteModal.title = `Tem a certeza que pretende eliminar a reclamação?`
      this.complaintDeleteModal.content = item
      this.$root.$emit('bv::show::modal', this.complaintDeleteModal.id, button)
    },
    resetComplaintDeleteModal() {
      this.complaintDeleteModal.title = ''
      this.complaintDeleteModal.content = ''
    },
    complaintEditModalInfo(item, index, button) {
      this.complaintEditModal.title = `Editar reclamação`
      this.complaintEditModal.content = item

      this.reclamacao = item.element.obsDA
      this.observation = item.element.observations
      this.newDeliver = item.element.newDelivery,
      this.withPartnerDevolution = item.element.withPartnerDevolution,
      this.isReplica = item.element.isReplica,
      this.sameDA = item.element.sameDA,
      this.responsability = item.element.responsability,
      this.devolution = item.element.devolution? 'Devolution':'Register',
      this.txDeliver = item.element.devolutionTxDelivery,
      this.txDeliverValue = item.element.txDeliveryValue,
      this.devolutionValue = (Math.round(((parseFloat(item.element.totalCredit) - parseFloat(item.element.txDeliverValue)) + Number.EPSILON) * 100) / 100),
      this.devolutionType = item.element.devolutionType
      this.existsDevolution = false

      this.$root.$emit('bv::show::modal', this.complaintEditModal.id, button)
    },
    resetComplaintEditModal() {
      this.complaintEditModal.title = ''
      this.complaintEditModal.content = ''

      this.reclamacao = ''
      this.observation = ''
      this.newDeliver = true,
      this.withPartnerDevolution = false,
      this.isReplica = false,
      this.sameDA = false,
      this.responsability = '',
      this.devolution = 'Register',
      this.txDeliver = false,
      this.txDeliverValue = 0,
      this.txDeliverValueState = null
      this.devolutionValue = 0,
      this.devolutionValueState = null
      this.devolutionType = ''
      this.existsDevolution = false
    },
    sendPODescriptionInfo(item, index, button) {
      this.sendPODescriptionModal.title = 'Enviar Pedido para o Parceiro por Mensagem'
      this.sendPODescriptionModal.content = item
      if(this.otherPhones == ''){
        this.getPartnerContacts(item.element.supplierId)
      }
      this.telPartner = (item.element.supplierPhone).replace(/\s+/g, '').replace("+351", '')
      this.telPartnerSelectBox = (item.element.supplierPhone).replace(/\s+/g, '').replace("+351", '')
      this.poDesc = (item.itemsDescription).replaceAll("-/-", item.id).replaceAll("é", "e").replaceAll("è", "e").replaceAll("ê", "e").replaceAll("í", "i").replaceAll("ì", "i").replaceAll("ó", "o").replaceAll("ò", "o").replaceAll("õ", "o").replaceAll("á", "a").replaceAll("à", "a").replaceAll("ã", "a").replaceAll("ú", "u").replaceAll("ù", "u").replaceAll("ç", "c").replaceAll("»", ">").replaceAll("nº", "").replaceAll("É", "E").replaceAll("È", "E").replaceAll("Ê", "E").replaceAll("Í", "I").replaceAll("Ì", "I").replaceAll("Ó", "O").replaceAll("Ò", "O").replaceAll("Õ", "O").replaceAll("Á", "A").replaceAll("À", "A").replaceAll("Ã", "A").replaceAll("Ú", "U").replaceAll("Ù", "U").replaceAll("Ç", "C") + '\n#Codigo Levant: '+item.element.validationCode;
      this.$root.$emit('bv::show::modal', this.sendPODescriptionModal.id, button)
    },
    resetSendPODescriptionModal() {
      this.sendPODescriptionModal.title = ''
      this.sendPODescriptionModal.content = ''
      this.telPartner = ''
      this.telPartnerSelectBox = '',
      this.poDesc = ''
      this.telState = null
    },
    sendSMSToClientInfo(item, index, button) {
      this.sendSMSToClientModal.title = 'Enviar Mensagem para o Cliente'
      this.sendSMSToClientModal.content = item
      this.telClient = (item.element.customerPhone).replace(/\s+/g, '').replace("+351", '')
      this.$root.$emit('bv::show::modal', this.sendSMSToClientModal.id, button)
    },
    resetSendSMSToClientModal() {
      this.sendSMSToClientModal.title = ''
      this.sendSMSToClientModal.content = ''
      this.telClient = ''
      this.smsToClientText = ''
      this.smsToClientType = ''
    },
    createVoucherInfo(item, index, button) {
      this.createVoucherModal.title = 'Criar Voucher para o Cliente'
      this.createVoucherModal.content = item
      var today = new Date()
      var nextMonth = today.setMonth(today.getMonth() + 1)
      this.createVoucherModal.message1 = "Olá Sr(a) "+ item.element.deliveryCustomerName + ", enviamos esta mensagem com oferta de um voucher de "
      this.createVoucherModal.message2 = "€ a usar até ao dia "+ this.getDateFormated(nextMonth) +". Cod: XXX"
      this.$root.$emit('bv::show::modal', this.createVoucherModal.id, button)
    },
    resetCreateVoucherModal() {
      this.createVoucherModal.title = ''
      this.createVoucherModal.content = ''
      this.createVoucherModal.message1 = ''
      this.createVoucherModal.message2 = ''
      this.voucherValue = 0
    },

    formatData(from){
      // this.formatPedido(this.pedidoTest);
      var list = [];
      this.items.forEach(element => {
        var now = new Date();
        if(element.isDelivered || element.isCancelled){
          if(element.isDelivered){
            this.est = '';
            this.estState = 8;
            this.estDescr = 'Finalizada';
          }else if(element.isCancelled){
            this.est = '';
            this.estState = 9;
            this.estDescr = 'Cancelada';
          }
        } else{
          if(element.assignment == null){
            var dt = new Date(element.deliveryTimeWindowStart);
            var dtAssign = new Date(dt.getTime() - 20*60000);
            var dif = (Math.round(((now-dtAssign)/(1000*60) + Number.EPSILON)))
            if(dif > 0){
              this.est = " "+ Math.round((Math.abs(now-dtAssign)/(1000*60) + Number.EPSILON)) + " min";
              this.estState = 2;
              this.estDescr = 'Á deriva há'+ Math.round((Math.abs(now-dtAssign)/(1000*60) + Number.EPSILON)) + " min";
            } else {
              this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON)))-20 + " min";
              this.estState = 3;
              this.estDescr = 'Envio em '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON)))-20 + " min";
            }
          } else{
            if(!element.isPickedup){
              if(element.daArrivedAtPickupLocationDateTime == null){
                if(element.assignedDateTime == null){
                  this.est = " ";
                  this.estState = 1;
                  this.estDescr = "A tocar no Estafeta";
                } else {
                  var dt = new Date(element.assignedDateTime);
                  this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                  this.estState = 4;
                  this.estDescr = '>>Rest há ' + (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                }
              }else if(element.daArrivedAtPickupLocationDateTime != null){
                var dt = new Date(element.daArrivedAtPickupLocationDateTime);
                this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                this.estState = 5;
                this.estDescr = 'Rest há '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              }
            }else if(!element.isDelivered){
              if(element.daArrivedAtDeliveryLocationDateTime == null){
                var dt = new Date(element.pickedupDateTime);
                this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                this.estState = 6;
                this.estDescr = '>>Cliente há '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              }else if(element.daArrivedAtDeliveryLocationDateTime != null){
                var dt = new Date(element.daArrivedAtDeliveryLocationDateTime);
                this.est = " "+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                this.estState = 7;
                this.estDescr = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              }
            } else{
              this.est = '111';
              this.estState = '111';
            }
          }
        }
        var DateEntregaInicial = new Date(element.deliveryTimeWindowEnd)
        var DateEntrega = new Date(DateEntregaInicial.getTime() + 20*60000);
        var DateAssigned = new Date(element.assignedDateTime)
        var DatePO = new Date(element.poDateTime)
        var DateRest = new Date(element.deliveryTimeWindowStart)
        if(element.itemsDescription == null){
          element.itemsDescription = ' '
        }
        var alert = false
        if(new Date() > new Date(DateEntregaInicial.getTime() + 22*60000) && this.estState < 7)
        {
          alert = true
        }
        var desvioDelivery = ''
        var desvio = 0
        if(element.isDelivered){
          var deliveryDate = new Date(element.daArrivedAtDeliveryLocationDateTime)
          if(deliveryDate > DateEntrega){
            desvio = (Math.round((Math.abs(deliveryDate-DateEntrega)/(1000*60) + Number.EPSILON)))
            desvioDelivery = "+"+ desvio + " min"
          }else if(deliveryDate < DateEntregaInicial){
            desvio = (Math.round((Math.abs(DateEntregaInicial-deliveryDate)/(1000*60) + Number.EPSILON)))
            desvioDelivery = "-"+ desvio + " min"
          }
        }
        if(element.assignment != null){
          var nomeEst = ''
          var userNameEst = ''
          var estafetaPhone = ''
          var daLocationDate = 'Sem Estafeta'
          if(element.assignment.da != null){
            nomeEst = element.assignment.da.fullName
            userNameEst = element.assignment.da.userName + " - " + element.assignment.da.daId
            estafetaPhone = element.assignment.da.phone
            if(element.assignment.da.locationUpdatedDate != null){
              var DALocationDate = new Date(element.assignment.da.locationUpdatedDate)
              daLocationDate = String(DALocationDate.getDate()).padStart(2, '0') + "/" + String(DALocationDate.getMonth()+1).padStart(2, '0') + "/" + String(DALocationDate.getFullYear()) + " " +String(DALocationDate.getHours()).padStart(2, '0') + ":" + String(DALocationDate.getMinutes()).padStart(2, '0')
            }else{
              daLocationDate = 'Sem Localização do Estafeta'
            }
          }
          list.push({
            element,
            estado: this.est,
            estDescr: this.estDescr,
            estadoState: this.estState,
            id: element.poNum,
            orderId: element.orderId,
            nomeEstafeta: nomeEst,
            userNameEstafeta: userNameEst,
            estafetaPhone: estafetaPhone,
            deliveryCustomerName: element.deliveryCustomerName,
            customerPhone: element.customerPhone.substring(0,2) + " " + element.customerPhone.substring(2,5) + " " + element.customerPhone.substring(5,7) + " " + element.customerPhone.substring(7,9),
            supplierName: element.supplierName,
            supplierPhone: element.supplierPhone,
            deliveryCity: element.deliveryCity,
            deliveryDistance: this.round(element.deliveryDistance),
            assignedDateTime: String(DateAssigned.getHours()).padStart(2, '0') + ":" + String(DateAssigned.getMinutes()).padStart(2, '0'),
            poDateTime: String(DatePO.getDate()).padStart(2, '0') + "/" + String(DatePO.getMonth()+1).padStart(2, '0') + "/" + String(DatePO.getFullYear()) + " " +String(DatePO.getHours()).padStart(2, '0') + ":" + String(DatePO.getMinutes()).padStart(2, '0'),
            datedeliveryTimeWindowStart1: String(DateRest.getDate()).padStart(2, '0') + "/" + String(DateRest.getMonth()+1).padStart(2, '0') + "/" + String(DateRest.getFullYear()) + " " +String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
            deliveryTimeWindowStart1: String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
            datedeliveryTimeWindowEnd1: String(DateEntregaInicial.getDate()).padStart(2, '0') + "/" + String(DateEntregaInicial.getMonth()+1).padStart(2, '0') + "/" + String(DateEntregaInicial.getFullYear()) + " " +String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0'),
            deliveryTimeWindowEnd1: String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0'),
            orderValue: this.round(element.orderValue) + "€" + " / " + element.paymentType,
            amountDue: this.round(element.amountDue) + "€",
            steps: JSON.parse(element.assignment.steps),
            totalDistance: this.round(element.assignment.totalDistance),
            assignmentPOs: element.assignment.POs,
            authorizationCode: element.authorizationCode,
            itemsDescription: element.itemsDescription,
            daLocationDate: daLocationDate,
            realizado: element.source == "STORE_FRONT"? "Web" : (element.source == "CONTACT_CENTER"? "Contact-Center" : (element.source == "OPS_CENTRAL"? "Central Operações" : (element.source == "STORE_FRONT_APP_ANDROID"? "App Android" : (element.source == "STORE_FRONT_APP_IOS"? "App IOS" : "")))),
            alert: alert,
            desvioDelivery: (element.deliverAsap && desvio <= 0)? '' : desvioDelivery,
            // _rowVariant: alert? 'danger': null
            _cellVariants: { deliveryTimeWindowEnd1: alert? 'danger': null}
          })
        }else{
          list.push({
            element,
            id: element.poNum,
            estado: this.est,
            estDescr: this.estDescr,
            estadoState: this.estState,
            orderId: element.orderId,
            nomeEstafeta: "",
            userNameEstafeta: "",
            deliveryCustomerName: element.deliveryCustomerName,
            customerPhone: element.customerPhone.substring(0,2) + " " + element.customerPhone.substring(2,5) + " " + element.customerPhone.substring(5,7) + " " + element.customerPhone.substring(7,9),
            supplierName: element.supplierName,
            supplierPhone: element.supplierPhone,
            deliveryCity: element.deliveryCity,
            deliveryDistance: this.round(element.deliveryDistance),
            assignedDateTime: '',
            poDateTime: String(DatePO.getDate()).padStart(2, '0') + "/" + String(DatePO.getMonth()+1).padStart(2, '0') + "/" + String(DatePO.getFullYear()) + " " +String(DatePO.getHours()).padStart(2, '0') + ":" + String(DatePO.getMinutes()).padStart(2, '0'),
            datedeliveryTimeWindowStart1: String(DateRest.getDate()).padStart(2, '0') + "/" + String(DateRest.getMonth()+1).padStart(2, '0') + "/" + String(DateRest.getFullYear()) + " " +String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
            deliveryTimeWindowStart1: String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
            datedeliveryTimeWindowEnd1: String(DateEntregaInicial.getDate()).padStart(2, '0') + "/" + String(DateEntregaInicial.getMonth()+1).padStart(2, '0') + "/" + String(DateEntregaInicial.getFullYear()) + " " +String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0'),
            deliveryTimeWindowEnd1: String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0'),
            orderValue: this.round(element.orderValue) + "€" + " / " + element.paymentType,
            amountDue:this.round(element.amountDue) + "€",
            totalDistance: '',
            steps: '',
            assignmentPOs:[],
            authorizationCode: element.authorizationCode,
            itemsDescription: element.itemsDescription,
            daLocationDate: daLocationDate,
            realizado: element.source == "STORE_FRONT"? "Web" : (element.source == "CONTACT_CENTER"? "Contact-Center" : (element.source == "OPS_CENTRAL"? "Central Operações" : (element.source == "STORE_FRONT_APP_ANDROID"? "App Android" : (element.source == "STORE_FRONT_APP_IOS"? "App IOS" : "")))),
            alert: alert,
            desvioDelivery: (element.deliverAsap && desvio <= 0)? '' : desvioDelivery,
            // _rowVariant: alert? 'danger': null
            _cellVariants: { deliveryTimeWindowEnd1: alert? 'danger': null}
          })
        }
      })
      if(from == 'pedidosCliente'){
        this.pedidosCliente = list
      } else{
        this.poData = list
      }
    },

    formatPOData(element){
      // this.formatPedido(this.pedidoTest);
      var newPo = {}
      var now = new Date();
      if(element.isDelivered || element.isCancelled){
        if(element.isDelivered){
          this.est = '';
          this.estState = 8;
          this.estDescr = 'Finalizada';
        }else if(element.isCancelled){
          this.est = '';
          this.estState = 9;
          this.estDescr = 'Cancelada';
        }
      } else{
        if(element.assignment == null){
          var dt = new Date(element.deliveryTimeWindowStart);
          var dtAssign = new Date(dt.getTime() - 20*60000);
          var dif = (Math.round(((now-dtAssign)/(1000*60) + Number.EPSILON)))
          if(dif > 0){
            this.est = " "+ Math.round((Math.abs(now-dtAssign)/(1000*60) + Number.EPSILON)) + " min";
            this.estState = 2;
            this.estDescr = 'Á deriva há'+ Math.round((Math.abs(now-dtAssign)/(1000*60) + Number.EPSILON)) + " min";
          } else {
            this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON)))-20 + " min";
            this.estState = 3;
            this.estDescr = 'Envio em '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON)))-20 + " min";
          }
        } else{
          if(!element.isPickedup){
            if(element.daArrivedAtPickupLocationDateTime == null){
              if(element.assignedDateTime == null){
                this.est = " ";
                this.estState = 1;
                this.estDescr = "A tocar no Estafeta";
              } else {
                var dt = new Date(element.assignedDateTime);
                this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
                this.estState = 4;
                this.estDescr = '>>Rest há ' + (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              }
            }else if(element.daArrivedAtPickupLocationDateTime != null){
              var dt = new Date(element.daArrivedAtPickupLocationDateTime);
              this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              this.estState = 5;
              this.estDescr = 'Rest há '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
            }
          }else if(!element.isDelivered){
            if(element.daArrivedAtDeliveryLocationDateTime == null){
              var dt = new Date(element.pickedupDateTime);
              this.est = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              this.estState = 6;
              this.estDescr = '>>Cliente há '+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
            }else if(element.daArrivedAtDeliveryLocationDateTime != null){
              var dt = new Date(element.daArrivedAtDeliveryLocationDateTime);
              this.est = " "+ (Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
              this.estState = 7;
              this.estDescr = " "+(Math.round((Math.abs(now-dt)/(1000*60) + Number.EPSILON))) + " min";
            }
          } else{
            this.est = '111';
            this.estState = '111';
          }
        }
      }
      var DateEntregaInicial = new Date(element.deliveryTimeWindowEnd)
      var DateEntrega = new Date(DateEntregaInicial.getTime() + 20*60000);
      var DateAssigned = new Date(element.assignedDateTime)
      var DatePO = new Date(element.poDateTime)
      var DateRest = new Date(element.deliveryTimeWindowStart)
      if(element.itemsDescription == null){
        element.itemsDescription = ' '
      }
      var alert = false
      if(new Date() > new Date(DateEntregaInicial.getTime() + 22*60000) && this.estState < 7)
      {
        alert = true
      }
      if(element.assignment != null){
        var nomeEst = ''
        var userNameEst = ''
        var estafetaPhone = ''
        var daLocationDate = 'Sem Estafeta'
        if(element.assignment.da != null){
          nomeEst = element.assignment.da.fullName
          userNameEst = element.assignment.da.userName + " - " + element.assignment.da.daId
          estafetaPhone = element.assignment.da.phone
          if(element.assignment.da.locationUpdatedDate != null){
            var DALocationDate = new Date(element.assignment.da.locationUpdatedDate)
            daLocationDate = String(DALocationDate.getDate()).padStart(2, '0') + "/" + String(DALocationDate.getMonth()+1).padStart(2, '0') + "/" + String(DALocationDate.getFullYear()) + " " +String(DALocationDate.getHours()).padStart(2, '0') + ":" + String(DALocationDate.getMinutes()).padStart(2, '0')
          }else{
            daLocationDate = 'Sem Localização do Estafeta'
          }
        }
        newPo = {
          element,
          estado: this.est,
          estDescr: this.estDescr,
          estadoState: this.estState,
          id: element.poNum,
          orderId: element.orderId,
          nomeEstafeta: nomeEst,
          userNameEstafeta: userNameEst,
          estafetaPhone: estafetaPhone,
          customerPhone: element.customerPhone.substring(0,2) + " " + element.customerPhone.substring(2,5) + " " + element.customerPhone.substring(5,7) + " " + element.customerPhone.substring(7,9),
          supplierName: element.supplierName,
          supplierPhone: element.supplierPhone,
          deliveryCity: element.deliveryCity,
          deliveryDistance: this.round(element.deliveryDistance),
          assignedDateTime: String(DateAssigned.getHours()).padStart(2, '0') + ":" + String(DateAssigned.getMinutes()).padStart(2, '0'),
          poDateTime: String(DatePO.getDate()).padStart(2, '0') + "/" + String(DatePO.getMonth()+1).padStart(2, '0') + "/" + String(DatePO.getFullYear()) + " " +String(DatePO.getHours()).padStart(2, '0') + ":" + String(DatePO.getMinutes()).padStart(2, '0'),
          datedeliveryTimeWindowStart1: String(DateRest.getDate()).padStart(2, '0') + "/" + String(DateRest.getMonth()+1).padStart(2, '0') + "/" + String(DateRest.getFullYear()) + " " +String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
          deliveryTimeWindowStart1: String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
          datedeliveryTimeWindowEnd1: String(DateEntregaInicial.getDate()).padStart(2, '0') + "/" + String(DateEntregaInicial.getMonth()+1).padStart(2, '0') + "/" + String(DateEntregaInicial.getFullYear()) + " " +String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0'),
          deliveryTimeWindowEnd1: String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0'),
          orderValue: this.round(element.orderValue) + "€" + " / " + element.paymentType,
          amountDue: this.round(element.amountDue) + "€",
          totalDistance: this.round(element.assignment.totalDistance),
          steps: JSON.parse(element.assignment.steps),
          assignmentPOs: element.assignment.POs,
          authorizationCode: element.authorizationCode,
          itemsDescription: element.itemsDescription,
          daLocationDate: daLocationDate,
          realizado: element.source == "STORE_FRONT"? "Web" : (element.source == "CONTACT_CENTER"? "Contact-Center" : (element.source == "OPS_CENTRAL"? "Central Operações" : (element.source == "STORE_FRONT_APP_ANDROID"? "App Android" : (element.source == "STORE_FRONT_APP_IOS"? "App IOS" : "")))),
          alert: alert,
          // _rowVariant: alert? 'danger': null
          _cellVariants: { deliveryTimeWindowEnd1: alert? 'danger': null}
        }
      }else{
        newPo = {
          element,
          id: element.poNum,
          estado: this.est,
          estDescr: this.estDescr,
          estadoState: this.estState,
          orderId: element.orderId,
          nomeEstafeta: "",
          userNameEstafeta: "",
          customerPhone: element.customerPhone.substring(0,2) + " " + element.customerPhone.substring(2,5) + " " + element.customerPhone.substring(5,7) + " " + element.customerPhone.substring(7,9),
          supplierName: element.supplierName,
          supplierPhone: element.supplierPhone,
          deliveryCity: element.deliveryCity,
          deliveryDistance: this.round(element.deliveryDistance),
          assignedDateTime: '',
          poDateTime: String(DatePO.getDate()).padStart(2, '0') + "/" + String(DatePO.getMonth()+1).padStart(2, '0') + "/" + String(DatePO.getFullYear()) + " " +String(DatePO.getHours()).padStart(2, '0') + ":" + String(DatePO.getMinutes()).padStart(2, '0'),
          datedeliveryTimeWindowStart1: String(DateRest.getDate()).padStart(2, '0') + "/" + String(DateRest.getMonth()+1).padStart(2, '0') + "/" + String(DateRest.getFullYear()) + " " +String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
          deliveryTimeWindowStart1: String(DateRest.getHours()).padStart(2, '0') + ":" + String(DateRest.getMinutes()).padStart(2, '0'),
          datedeliveryTimeWindowEnd1: String(DateEntregaInicial.getDate()).padStart(2, '0') + "/" + String(DateEntregaInicial.getMonth()+1).padStart(2, '0') + "/" + String(DateEntregaInicial.getFullYear()) + " " +String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0'),
          deliveryTimeWindowEnd1: String(DateEntregaInicial.getHours()).padStart(2, '0') + ":" + String(DateEntregaInicial.getMinutes()).padStart(2, '0') + " - " + String(DateEntrega.getHours()).padStart(2, '0') + ":" + String(DateEntrega.getMinutes()).padStart(2, '0'),
          orderValue: this.round(element.orderValue) + "€" + " / " + element.paymentType,
          amountDue: this.round(element.amountDue) + "€",
          totalDistance: '',
          steps: '',
          assignmentPOs:[],
          authorizationCode: element.authorizationCode,
          itemsDescription: element.itemsDescription,
          daLocationDate: daLocationDate,
          realizado: element.source == "STORE_FRONT"? "Web" : (element.source == "CONTACT_CENTER"? "Contact-Center" : (element.source == "OPS_CENTRAL"? "Central Operações" : (element.source == "STORE_FRONT_APP_ANDROID"? "App Android" : (element.source == "STORE_FRONT_APP_IOS"? "App IOS" : "")))),
          alert: alert,
          // _rowVariant: alert? 'danger': null
          _cellVariants: { deliveryTimeWindowEnd1: alert? 'danger': null}
        }
      }
      return newPo;
    },
    formatAssignmentMiddle(po, assignId, stepsList){
      DeliveryAssignmentDataService.GetPOsFromDeliveryAssignmentId(assignId, { headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => (this.formatAssignment(po, response.data, stepsList)))
          .catch(error => {
            console.log(error)
          })
          .finally()
    },
    formatAssignment(po, posList, stepsList){
      var newSteps = []
      var daIsDoing = false
      stepsList.forEach((step,index) => {
        var pos = []
        var st = ''
        var horaChegada = ''
        var horaSaida = ''
        var groupedStep = []
        var aux = 0

        if(step.Type == 'AcceptAssignment' || step.Type == 0){
          st = 'done'
          newSteps.push({
            step,
            estado: st,
            horaChegada: horaChegada,
            groupedStep: [step]
          })
        }
        if(step.Type == 'GotoPickupLocation' || step.Type == 1){
          groupedStep.push(step)
          step.PONums.forEach((ponum, indexpo) => {
            var poo = this.formatPOData(posList.find(po => po.poNum == ponum));
            pos.push(poo)
            if(!poo.element.isPickedup){
              aux = 1
            }
            groupedStep.push(stepsList[index+indexpo+1])
          })
          if(step.IsCompleted){
            const highestPickup = pos.reduce((previous, current) => {
              return (new Date(current.element.pickedupDateTime) > new Date(previous.element.pickedupDateTime) && current.element.pickedupDateTime != null) ? current : previous;
            });
            const lowestPickup = pos.reduce((previous, current) => {
              return (new Date(current.element.daArrivedAtPickupLocationDateTime) < new Date(previous.element.daArrivedAtPickupLocationDateTime) && current.element.daArrivedAtPickupLocationDateTime != null) ? current : previous;
            });
            if(aux == 0){
              st = 'done'
              horaChegada = new Date(lowestPickup.element.daArrivedAtPickupLocationDateTime)
              horaChegada = String(horaChegada.getHours()).padStart(2, '0') + ":" + String(horaChegada.getMinutes()).padStart(2, '0')
              horaSaida = new Date(highestPickup.element.pickedupDateTime)
              horaSaida = String(horaSaida.getHours()).padStart(2, '0') + ":" + String(horaSaida.getMinutes()).padStart(2, '0')
            }else{
              st = 'doing'
              horaChegada = new Date(lowestPickup.element.daArrivedAtPickupLocationDateTime)
              horaChegada = String(horaChegada.getHours()).padStart(2, '0') + ":" + String(horaChegada.getMinutes()).padStart(2, '0')
              daIsDoing = true
            }
          }else{
            st = 'default'
          }
          newSteps.push({
            step,
            restaurante: pos[0].supplierName,
            restaurantePhone: pos[0].supplierPhone,
            estado: st,
            horaChegada: horaChegada,
            horaSaida: horaSaida,
            groupedStep: groupedStep
          })
        }else if(step.Type == 'GotoDeliveryLocation' || step.Type == 3){
          groupedStep.push(step)
          step.PONums.forEach((ponum, indexpo) => {
            var poo = this.formatPOData(posList.find(po => po.poNum == ponum));
            pos.push(poo)
            if(!poo.element.isDelivered){
              aux = 1
            }
            groupedStep.push(stepsList[index+indexpo+1])
          })
          if(step.IsCompleted){
            const highestDelivery = pos.reduce((previous, current) => {
              return (new Date(current.element.deliveredDateTime) > new Date(previous.element.deliveredDateTime) && current.element.deliveredDateTime != null) ? current : previous;
            });
            const lowestDelivery = pos.reduce((previous, current) => {
              return (new Date(current.element.daArrivedAtDeliveryLocationDateTime) < new Date(previous.element.daArrivedAtDeliveryLocationDateTime) && current.element.daArrivedAtDeliveryLocationDateTime != null) ? current : previous;
            });
            if(aux == 0){
              st = 'done'
              horaChegada = new Date(lowestDelivery.element.daArrivedAtDeliveryLocationDateTime)
              horaChegada = String(horaChegada.getHours()).padStart(2, '0') + ":" + String(horaChegada.getMinutes()).padStart(2, '0')
              horaSaida = new Date(highestDelivery.element.deliveredDateTime)
              horaSaida = String(horaSaida.getHours()).padStart(2, '0') + ":" + String(horaSaida.getMinutes()).padStart(2, '0')
            }else{
              st = 'doing'
              horaChegada = new Date(lowestDelivery.element.daArrivedAtDeliveryLocationDateTime)
              horaChegada = String(horaChegada.getHours()).padStart(2, '0') + ":" + String(horaChegada.getMinutes()).padStart(2, '0')
              daIsDoing = true
            }
          }else{
            st = 'default'
          }
          newSteps.push({
            step,
            estado: st,
            horaChegada: horaChegada,
            horaSaida: horaSaida,
            groupedStep: groupedStep
          })
        }
      })
      var daLocationDate = new Date(po.element.assignment.da.locationUpdatedDate)
      if(!daIsDoing && (new Date() > new Date(daLocationDate.getTime() + 2*60000))){
        this.daInTransit = true
      }
      this.listDone = newSteps
      this.listDoneInitial = this.listDone
      this.googleMapsLink(po, this.listDone)
    },
    validateSteps(steps){
      var deliver = []
      var count = 0
      var dones = 0
      if(steps.length == 0){
        return false;
      }
      if(steps[0].step.Type != 'AcceptAssignment' && steps[0].step.Type != 0){
        return false;
      }
      for(var i = steps.length-1; i > 0; i--){
        // if(steps[i].step.Type == 'Deliver'){
        //   deliver.push(steps[i].step.PONums[0])
        if(steps[i].step.Type == 'GotoDeliveryLocation' || steps[i].step.Type == 3){
          steps[i].step.PONums.forEach(element => deliver.push(element))
        } else if(steps[i].step.Type == 'GotoPickupLocation' || steps[i].step.Type == 1){
          steps[i].step.PONums.forEach(ponum => {
            if(!deliver.includes(ponum)){
              count = count + 1
            }
          })
        }
        if(steps[i].estado == 'done'){
          dones = dones + 1
        }else if(dones != 0){
          count = count + 1
        }
      }
      if(count > 0){
        return false
      }else{
        return true;
      }
    },
    checkMove(evt){
      return (evt.draggedContext.element.estado!=='done')
    },
    alterarSteps(po, steps, observation){
      var newSteps = []
      steps.forEach((element, index) => {
        if(steps.length - 1 > index){
          if(steps[index+1].step.Type == element.step.Type && steps[index+1].step.Location.lat == element.step.Location.lat && steps[index+1].step.Location.lng == element.step.Location.lng){
            element.step.Key = element.step.Key +","+ steps[index+1].step.PONums[0]
            element.step.StepGroupKey = element.step.StepGroupKey +","+ steps[index+1].step.PONums[0]
            element.step.PONums = element.step.PONums.concat(steps[index+1].step.PONums)
            steps[index+1].groupedStep.shift()
            element.groupedStep[0] = element.step
            element.groupedStep = element.groupedStep.concat(steps[index+1].groupedStep)
            steps.splice(index+1, 1)
          }
        }
        element.groupedStep.forEach(st => {
          newSteps.push(st)
        })
      })
      this.postAlterarOrdens(po, newSteps, observation)
    },
    getCurrentLoginInfo(){
      SessionDataService.GetCurrentLoginInformations({ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => {
          (this.currentUser = response.data.result.user);
          if(this.currentUser == null){
            this.$router.push({ path: '/login', query: { returnUrl: this.$route.path } })
          }else{
            this.getCurrentUserRoles()
            this.getCurrentUserPermissions()
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getCurrentUserRoles(){
      UserDataService.GetCurrentUserInfo({Id: this.currentUser.id} , {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentUserRoles = response.data.result.roleNames))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getCurrentUserPermissions(){
      UserDataService.GetCurrentUserPermissions({Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentUserPermissions = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    hasPermissions(element){
      if(this.currentUserPermissions.includes(element)){
        return true;
      }else{
        return false;
      }
    },
    postLogAcao(acaoId, userId, previous, parameters, poNum, obs, daId, custPhone, custName, daName, suppId, suppName){
      var today = new Date();
      var body = {
        acaoId: acaoId,
        userId: userId,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: obs,
        daId: daId,
        customerPhone: custPhone,
        customerName: custName,
        daUserName: daName,
        supplierId: suppId,
        supplierName: suppName
      }
      LogAcaoDataService.CreateLogAcao(body,{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then()
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    postLogAuthorizationCode(po, userId, reason, justification, poNum){
      var today = new Date()
      var logAuthorizationCode = {
        userId: userId,
        reason: reason,
        justification: justification,
        poNum: poNum,
        logDateTime: today
      }
      var today = new Date();
      var logAcao = {
        acaoId: 14,
        userId: userId,
        previous: '',
        parameters: reason,
        poNum: poNum,
        logDateTime: today,
        observations: justification,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoLogAuthorizationCode(logAcao, logAuthorizationCode, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(this.$notifications.notify(
          {
            message: `<span>Código enviado por SMS com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    postCancelar(po, pagarDA, observation){
      const poNum = po.id;
      var parameters = ''
      if(pagarDA == ''){
        pagarDA = false
      }
      if(pagarDA == true){
        parameters = "Pagar ao estafeta"
      }else {
        parameters = "Não pagar ao estafeta"
      }
      var isCZ = false;
      if(this.currentUserRoles.includes("COORDENADORZONA")){
        isCZ = true
      }
      var today = new Date();
      var body = {
        acaoId: 3,
        userId: this.currentUser.id,
        previous: '',
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoCancelarPedido(body,{poNum: poNum, pagarDA: pagarDA, isCZ: isCZ}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Pedido Cancelado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, ''), (response.data.result.refunded == 0 && response.data.result.remainingAmountToRefund == 0)? null :(this.refunded=response.data.result.refunded > 0?this.round(response.data.result.refunded) : this.round(0), this.refundDue = response.data.result.remainingAmountToRefund>0? this.round(response.data.result.remainingAmountToRefund):this.round(0), this.refundInfo('', this.refundInfo.id, ''))
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postCancelarGiftCardModal(po){
      const poNum = po.id;
      var parameters = 'Cartão Presente'
      var today = new Date();
      var body = {
        acaoId: 3,
        userId: this.currentUser.id,
        previous: '',
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: '',
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoCancelarPedidoGiftCard(body,{poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => ( this.$notifications.notify(
          {
            message: `<span>Cartão Presente Fechado com Sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postRetirar(po, observation){
      var poNum = po.id;
      const previous = po.userNameEstafeta
      var today = new Date();
      var body = {
        acaoId: 2,
        userId: this.currentUser.id,
        previous: previous,
        parameters: '',
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoRetirarPedido(body,{assignedDate: po.element.assignedDateTime != null? true : false, poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Pedido Retirado ao Estafeta com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro! " + error.response.data.error.message,
            type: "error",
            timer: 3000
          })
          this.seeClienteInfo(poNum, '')
        })
        .finally(() => this.errored = false)
    },
    postReAtivar(po, observation){
      const poNum = po.id;
      var today = new Date();
      var body = {
        acaoId: 4,
        userId: this.currentUser.id,
        previous: '',
        parameters: '',
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoReAtivarPedido(body, {poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Pedido ReAtivado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postFechar(po, observation){
      const poNum = po.id;
      var today = new Date();
      var body = {
        acaoId: 5,
        userId: this.currentUser.id,
        previous: '',
        parameters: '',
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoForcarFechoPedido(body,{poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Pedido Concluído com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postReclamacao(po, obs, observation){
      const poNum = po.id;
      var today = new Date();
      var body = {
        newDelivery: this.newDeliver,
        withPartnerDevolution: this.withPartnerDevolution,
        sameDA: this.sameDA,
        obsDA: this.newDeliver? this.reclamacao : '',
        devolution: this.devolution == 'Devolution'? true : false,
        register: this.devolution == 'Devolution'? false : true,
        devolutionValue: this.devolution == 'Devolution'? this.devolutionValue : 0,
        devolutionTxDelivery: this.newDeliver ? true : (this.devolution == 'Devolution'? this.txDeliver : false),
        txDeliveryValue: ((this.devolution == 'Devolution' && this.txDeliver) || this.newDeliver)? this.txDeliverValue : 0,
        devolutionType: this.devolution == 'Devolution'? this.devolutionType : null,
        responsability: (this.newDeliver || this.txDeliver )? this.responsability : null,
        poNum: poNum,
        totalCredit:  this.devolutionType == "OnlineRefund"? 0 :(Math.round(((parseFloat(this.devolution == 'Devolution'? this.devolutionValue : 0) + parseFloat((this.devolution == 'Devolution' && this.txDeliver )? this.txDeliverValue : 0)) + Number.EPSILON) * 100) / 100) ,
        creditAvailable:  this.devolutionType == "OnlineRefund"? 0 :(Math.round(((parseFloat(this.devolution == 'Devolution'? this.devolutionValue : 0) + parseFloat((this.devolution == 'Devolution' && this.txDeliver )? this.txDeliverValue : 0)) + Number.EPSILON) * 100) / 100) ,
        observations: this.observation,
        complaintDateTime: today,
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        supplierId: po.element.supplierId,
        supplierName: po.element.supplierName,
        isReplica: this.isReplica
      }
      const parameters = "Nova Entrega? " + (this.newDeliver == true? "Sim" : "Não") + (this.withPartnerDevolution == true? " | Com retorno ao parceiro" : "") + (this.sameDA == true? " | Mesmo Estafeta" : "") + (this.isReplica == true? " | Com cópia do Pedido" : "")  + " | Devolução? " + (this.devolution == 'Devolution'? "Sim" : "Não") + (this.devolution == 'Devolution'?   " | Valor: " + (parseFloat(body.devolutionValue) + parseFloat(body.txDeliveryValue)) : "") ;
      var today = new Date();
      var body1 = {
        acaoId: 6,
        userId: this.currentUser.id,
        previous: '',
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoReclamacao(body1, body, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Reclamação criada!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, ''), this.devolutionType == "OnlineRefund"? (this.refunded=response.data.result.refunded > 0?this.round(response.data.result.refunded) : this.round(0), this.refundDue = response.data.result.remainingAmountToRefund>0? this.round(response.data.result.remainingAmountToRefund):this.round(0), this.refundInfo('', this.refundInfo.id, '')) : null
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAlterarPedido(po, valorDiff, observation){
      const poNum = po.id;
      var amountDue = po.element.amountDue;
      const previous = this.round(po.element.orderValue) + "€";
      const parameters = this.round((parseFloat(po.element.orderValue) + parseFloat(valorDiff))) + "€";
      var not = {
            message: `<span>Alteração dos Produtos com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }
      var today = new Date();
      var body = {
        acaoId: 7,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoAcrescentarRetirarProduto(body,{poNum: poNum, valorDiff: valorDiff, amountDue: amountDue}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(not), this.seeClienteInfo(poNum, ''), (valorDiff > 0 || (response.data.result.refunded == 0 && response.data.result.remainingAmountToRefund == 0))? null : (this.refunded=response.data.result.refunded > 0?this.round(response.data.result.refunded) : this.round(0), this.refundDue = response.data.result.remainingAmountToRefund>0? this.round(response.data.result.remainingAmountToRefund):this.round(0), this.refundInfo('', this.refundInfo.id, ''))))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAtivarCredito(po, valorCred, observation){
      var vCred = 0
      if(valorCred != ''){
        vCred = parseFloat(valorCred)
      }
      const poNum = po.id;
      const previous = this.round(po.element.amountDue) + "€";
      const parameters = this.round((parseFloat(po.element.amountDue) - parseFloat(vCred))) + "€";
      var today = new Date();
      var body = {
        acaoId: 8,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoAtivarCredito(body,{poNum: poNum, valorCred: vCred}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Credito Ativado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '') // this.postCreditActivated(po.element.customerPhone, po.element.supplierId, vCred, poNum),
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAddAmountDue(po, valorAdd, observation){
      var vAdd = 0
      if(valorAdd != ''){
        vAdd = parseFloat(valorAdd)
      }
      const poNum = po.id;
      const previous = this.round(po.element.amountDue) + "€";
      const parameters = this.round((parseFloat(po.element.amountDue) + parseFloat(vAdd))) + "€";
      var today = new Date();
      var body = {
        acaoId: 26,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoAddAmountDue(body,{poNum: poNum, valorAdd: vAdd}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Adicionado valor em divida com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAlterarTimeWindow(po, minutos, observation){
      const poNum = po.id;
      const previous = po.deliveryTimeWindowStart1 + " - [" + po.deliveryTimeWindowEnd1 + "]"
      var deliveryDate = new Date(po.element.deliveryTimeWindowEnd)
      var newDeliveryDate = new Date(deliveryDate.getTime() + parseFloat(minutos)*60000);
      var newDeliveryDate2 = new Date(newDeliveryDate.getTime() + 20*60000);
      var newPickupDate = new Date(newDeliveryDate.getTime() - 15*60000);
      var parameters =  String(newPickupDate.getHours()).padStart(2, '0') + ":" + String(newPickupDate.getMinutes()).padStart(2, '0') + " - [" + String(newDeliveryDate.getHours()).padStart(2, '0') + ":" + String(newDeliveryDate.getMinutes()).padStart(2, '0') + " - " + String(newDeliveryDate2.getHours()).padStart(2, '0') + ":" + String(newDeliveryDate2.getMinutes()).padStart(2, '0') + "]";
      var today = new Date();
      var body = {
        acaoId: 9,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoAlterarHorario(body,{poNum: poNum, minutos: minutos}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Horário Alterado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAtribuirMaisPedidos(po, da, newAssign, observation){
      const poNum = po.id;
      const daId = da.daId;
      const parameters = da.userName;
      var today = new Date();
      var body = {
        acaoId: 10,
        userId: this.currentUser.id,
        previous: '',
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoAtribuirMaisPedidosEstafeta(body,{poNum: poNum, daId: daId, newAssign: newAssign}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Pedido Atribuido com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAlterarEstafeta(po, daNew, observation){
      const poNum = po.id;
      const assignId = po.element.assignmentId
      const daIdOld = po.element.assignment.daId;
      const daIdNew = daNew.daId;
      const previous = po.userNameEstafeta;
      const parameters = daNew.userName;
      var today = new Date();
      var body = {
        acaoId: 11,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoTrocarEstafeta(body,{assignId: assignId, daIdNew: daIdNew}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Estafeta Alterado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAdicionarKms(po, kms, observation){
      const poNum = po.id;
      const assignId = po.element.assignmentId;
      const previous = '';
      const parameters = parseFloat(kms) + "km";
      var today = new Date();
      var body = {
        acaoId: 12,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoAjustarKms(body,{assignId: assignId, kms: kms}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Kms ajustados com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postAjustarDeliveryFee(po, observation){
      var df = 0;
      if(this.txDeliverValue != ''){
        df = this.txDeliverValue
      }
      const poNum = po.id;
      const previous = this.currentDeliveryFee;
      const parameters = this.round(this.currentDeliveryFee - df);
      var today = new Date();
      var deliveryFeeDif = df;
      var body = {
        acaoId: 25,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoAjustarDeliveryFee(body,{poNum: poNum, deliveryFeeDif: deliveryFeeDif}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Taxa de Entrega ajustada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)

    },
    postAlterarOrdens(po, nSteps, observation){
      const poNum = po.id;
      const assignId = po.element.assignmentId;
      const newSteps = JSON.stringify(nSteps)
      var previous = this.stepOldStringtoLog(this.listDoneInitial)
      var parameters = this.stepStringtoLog(nSteps)
      var today = new Date();
      var body = {
        acaoId: 13,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoAlterarSteps(body,{assignId: assignId, newSteps: newSteps}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Ordem Alterada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postObsDA(po, oldObs, newObs){
      const poNum = po.id;
      const previous = oldObs
      const parameters = newObs
      var today = new Date();
      var body = {
        acaoId: 19,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: '',
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoChangeObsDA(body,{poNum: poNum, obsDA: newObs}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Observações Alteradas com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    getChangeAdressInfo(po, street, door, floor, zipcode, city, location){
      const poNum = po.id;
      LogAcaoDataService.GetChangeAdressInfo({poNum: poNum, street: street, door: door, floor: floor, zipcode: zipcode, city: city, lat: location.lat, lng: location.lng}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.kmsDif=response.data.result.difKm > 0?this.round(response.data.result.difKm) : this.round(0), this.amountDif = response.data.result.amountAcres>0? this.round(response.data.result.amountAcres):this.round(0)))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    getClientDistanceInfo(po){
      const poNum = po.id;
      let time = 0;
      LogAcaoDataService.GetClientDistanceInfo({poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => {time = response.data.result, this.smsToClientText = 'Ola Sr(a) '+po.element.deliveryCustomerName+', informamos que o seu pedido do '+po.element.supplierName+' estara na morada indicada dentro de ' + time + ' minutos. Obrigada. Para + info: 221451010'} )
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    postClientAdress(po, street, door, floor, zipcode, city, observation, location, addressUpdated, changeAmounts, changeAddressGlobally){
      // if(!addressUpdated){
      //   this.getCoordsByAddress(street, door, floor, zipcode, city)
      // }
      const poNum = po.id;
      const previous = po.element.deliveryStreet + " " + po.element.deliveryDoorNum + " " + po.element.deliveryFloorNum + " " + po.element.deliveryZipCode + " " + po.element.deliveryCity + " Coords: " + po.element.deliveryGpsLatitude + ", "+ po.element.deliveryGpsLongitude
      const parameters = street + " " + door + " " + floor + " " + zipcode + " " + city + (addressUpdated? (' Coords: '+ location.lat + ", "+ location.lng) : '') + " - " + (changeAmounts? "Valor Alterado": "Valor Não Alterado") + " - " + (!changeAddressGlobally? "Nova morada" : "Apenas nesta entrega")
      var today = new Date();
      var body = {
        acaoId: 20,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: observation,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoChangeAdress(body,{poNum: poNum, street: street, door: door, floor: floor, zipcode: zipcode, city: city, lat: (addressUpdated? location.lat:null), lng: (addressUpdated? location.lng:null), changeAmounts: changeAmounts, changeAddressGlobally: !changeAddressGlobally}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Morada do cliente Alterada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, ''), this.kmsAux=response.data.result.difKm > 0?this.round(response.data.result.difKm) : this.round(0), this.amountAux = response.data.result.amountAcres>0? this.round(response.data.result.amountAcres):this.round(0), this.changeAdressInfo('', this.changeAdressInfo.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postMBWAYRequest(po, phone){
      const poNum = po.id;
      const previous = ""
      const parameters = this.currentAmountDue + "€"
      var today = new Date();
      var body = {
        acaoId: 21,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: phone,
        daId: po.element.assignment != null? po.element.assignment.da.daId : '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: po.element.assignment != null? po.element.assignment.da.userName : '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoMBWAYRequest(body,{poNum: poNum, phone: phone}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Pedido de pagamento enviado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postSendPODescription(po, telPartner, poDesc){
      const poNum = po.id;
      const previous = ""
      const parameters = telPartner
      var today = new Date();
      var body = {
        acaoId: 28,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: '',
        daId: null,
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: null,
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.SendPODescriptionBySMS(body,{phone: telPartner, text: poDesc, poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Pedido enviado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postSendSMSToClient(po, telClient, text){
      const poNum = po.id;
      const previous = ""
      const parameters = telClient
      var today = new Date();
      var body = {
        acaoId: 29,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: text,
        daId: null,
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: null,
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.SendSMSToClient(body,{phone: telClient, text: text, poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Mensagem enviada com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.seeClienteInfo(poNum, ''), this.getSMSMessagesByCliente(po.element.customerPhone)
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    postCreateVoucher(po, value){
      var phone = (po.element.customerPhone).replace(/\s+/g, '').replace("+351", '')
      LogAcaoDataService.CreateSFClientVoucher({value: value, customerPhone: phone, customerName: po.element.deliveryCustomerName, userId: this.currentUser.id, poNum: po.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Voucher criado com sucesso!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.getSMSMessagesByCliente(po.element.customerPhone)
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados!",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    stepStringtoLog(steps){
      var str = ''
      steps.forEach(element => {
        if((element.Type == 'GotoPickupLocation' || element.Type == 1)){
          str = str + " -> Levantar - " + element.Key.split("-")[1]
        }
        else if((element.Type == 'GotoDeliveryLocation' || element.Type == 3)){
          str = str + " -> Entregar - " + element.Key.split("-")[1]
        }
      })
      return str;
    },
    stepOldStringtoLog(steps){
      var str = ''
      steps.forEach(element => {
        if((element.step.Type == 'GotoPickupLocation' || element.step.Type == 1)){
          str = str + " -> Levantar - " + element.step.Key.split("-")[1]
        }
        else if((element.step.Type == 'GotoDeliveryLocation' || element.step.Type == 3)){
          str = str + " -> Entregar - " + element.step.Key.split("-")[1]
        }
      })
      return str;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    toogleSeeCode(po, poNum){
      if(this.codaut != '' && this.codauttext != ''){
        this.postLogAuthorizationCode(po, this.currentUser.id, this.codaut, this.codauttext, poNum)
        this.seeCode = true
      }
    },
    seeClienteInfo(id, event){
      if(this.poInfoModal.content != ''){
        this.resetPOInfoModal()
      }
      PurchaseOrderDataService.GetPurchaseOrderById(id, { headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => (this.poInfo(this.formatPOData(response.data), '', event)))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    seePOAlertsInfo(poNum){
      this.seeHistorico = false;
      if(!this.showPOAlerts){
        this.getPOAlerts(poNum)
      }
      this.showPOAlerts = !this.showPOAlerts
    },
    getPOAlerts(poNum){
      AlertDataService.GetAllByPO({poNum: poNum},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.poAlerts = this.formatAlertData(response.data.result)))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },

    seeHistoricoInfo(id){
      this.showPOAlerts = false;
      if(!this.seeHistorico){
        this.gethistory(id)
      }
      this.seeHistorico = !this.seeHistorico
    },
    gethistory(poNum){
      LogAcaoDataService.GetLogAcaoByPO({poNum: poNum},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.history = this.formatHistory(response.data.result), this.showHist = false))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    popoverRest(phone) {
      return '<a href="tel:351'+phone?.replace('+351', '').replace(' ', '')+'" target="_blank" class="tel" title="Ligar"><i class="fa fa-phone" title="Ligar"></i>'+ phone + '</a>'
    },
    changeSteps(po){
      this.googleMapsLink(po, this.listDone)
    },
    googleMapsLink(po, steps){
      var str = ''
      steps.filter(ele => ele.step.Type != 'AcceptAssignment' && ele.step.Type != 0).forEach(element => str = str + "/"+element.step.Location.lat + "," + element.step.Location.lng)
      this.mapsLink = "https://www.google.pt/maps/dir"+str +"/data=!3m1!4b1!4m4!4m3!2m1!2b1!3e0"
      var str2 = ''
      steps.filter(ele => (ele.step.Type != 'AcceptAssignment' && ele.step.Type != 0 && !ele.step.IsCompleted) || (((ele.step.Type == 'GotoPickupLocation' || ele.step.Type == 'GotoDeliveryLocation') && (ele.groupedStep.filter(st => !st.IsCompleted).length > 0)))).forEach(element => (str2 = str2 + "/"+element.step.Location.lat + "," + element.step.Location.lng))
      //steps.filter(ele => ele.step.Type != 'AcceptAssignment' && ele.step.Type != 0 && !ele.step.IsCompleted).forEach(element => (str2 = str2 + "/"+element.step.Location.lat + "," + element.step.Location.lng))
      if(po.element.assignment.da.locationGpsLatitude != null && po.element.assignment.da.locationGpsLongitude != null){
        this.leftMapsLink = "https://www.google.pt/maps/dir" + "/" + po.element.assignment.da.locationGpsLatitude + "," + po.element.assignment.da.locationGpsLongitude + str2 + "/data=!3m1!4b1!4m5!4m4!2m2!1b1!2b1!3e0"
      }else{
        this.leftMapsLink = "https://www.google.pt/maps/dir" + str2 + "/data=!3m1!4b1!4m4!4m3!2m1!2b1!3e0"
      }
    },

    changeAlterarPedido(){
      var price = 0
      if(this.alterarPedido != '' && this.alterarPedido >= (this.currentOrderValue * (-1))){
        price = this.alterarPedido
      } else if(this.alterarPedido <(this.currentOrderValue * (-1))){
        this.alterarPedido = (this.currentOrderValue * (-1))
      }
      this.alterarPedidoTotal = parseFloat(this.currentOrderValue) + parseFloat(price)
      this.alterarPedidoTotal = this.round(this.alterarPedidoTotal)
    },
    changeAlterarPedidoTotal(){
      var price = this.currentOrderValue
      if(this.alterarPedidoTotal != '' && this.alterarPedidoTotal >= 0){
        price = this.alterarPedidoTotal
      } else if(this.alterarPedidoTotal < 0){
        this.alterarPedidoTotal = 0
      }
      this.alterarPedido = parseFloat(price) - this.currentOrderValue
      this.alterarPedido = this.round(this.alterarPedido)
    },
    changeAdicionarKms(){
      var kms = 0
      if(this.adicionarKms != '' && this.adicionarKms >= (this.currentDistance * (-1))){
        kms = this.adicionarKms
      } else if(this.adicionarKms <(this.currentDistance * (-1))){
        this.adicionarKms = (this.currentDistance * (-1))
      }
      this.adicionarKmsTotal = parseFloat(this.currentDistance) + parseFloat(kms)
      this.adicionarKmsTotal = this.round(this.adicionarKmsTotal)
    },
    changeAdicionarKmsTotal(){
      var kms = this.currentDistance
      if(this.adicionarKmsTotal != '' && this.adicionarKmsTotal >= 0){
        kms = this.adicionarKmsTotal
      } else if(this.adicionarKmsTotal < 0){
        this.adicionarKmsTotal = 0
      }
      this.adicionarKms = parseFloat(kms) - this.currentDistance
      this.adicionarKms = this.round(this.adicionarKms)
    },
    changeAlterarTimeWindowMHD(){
      var min = 0
      var hora = 0
      var dia = 0
      if(this.alterarTimeWindowMin != ''){
        min = this.alterarTimeWindowMin
      }
      if(this.alterarTimeWindowHora != ''){
        hora = this.alterarTimeWindowHora
      }
      if(this.alterarTimeWindowDia != ''){
        dia = this.alterarTimeWindowDia
      }
      this.alterarTimeWindow = parseFloat(min) + (parseFloat(hora) * 60) + (parseFloat(dia) * (60 * 24))
      var deliveryDate = new Date(this.currentDeliveryTimeWindowEnd)
      var newdate = new Date(deliveryDate.getTime() + parseFloat(this.alterarTimeWindow)*60000);
      var newdate2 = new Date(newdate.getTime() + 20*60000);
      var date = String(newdate.getDate()).padStart(2, '0') + "/" + String((newdate.getMonth()+1)).padStart(2, '0') + "/" + newdate.getFullYear();
      this.newDateAlterarTimeWindow = date + " " + String(newdate.getHours()).padStart(2, '0') + ":" + String(newdate.getMinutes()).padStart(2, '0') + " - " + String(newdate2.getHours()).padStart(2, '0') + ":" + String(newdate2.getMinutes()).padStart(2, '0');
    },
    changeAtivarCredito(){
      var availableToCredit = this.currentAmountDue + this.onlinePaymentsValue;
      if(availableToCredit > this.currentCreditAvailable)
      {
        if(this.ativarCredito > this.currentCreditAvailable){
            this.ativarCredito = this.currentCreditAvailable
        }
      } else
      {
        if(this.ativarCredito > availableToCredit){
            this.ativarCredito = availableToCredit
        }
      }
      if(this.ativarCredito < 0){
        this.ativarCredito = 0
      }
    },
    changeAddAmountDue(){
      if(this.addAmountDue < 0){
        this.addAmountDue = 0
      }
    },
    changeValor1(){
      if(this.valor1 < 0){
        this.valor1 = 0
      }
      if(this.valor2 != '' && this.valor1 > this.valor2){
        this.valor1 = this.valor2
      }
    },
    changeValor2(){
      if(this.valor2 < 0){
        this.valor2 = 0
      }
      if(this.valor1 != '' && this.valor2 < this.valor1){
        this.valor2 = this.valor1
      }
    },
    changeDevolutionValue(){
      // if(this.devolutionValue < 0){
      //   this.devolutionValue = 0
      // }
      // if(this.devolutionValue > this.currentOrderValue - this.currentDeliveryFee){
      //   this.devolutionValue = this.round(this.currentOrderValue  - this.currentDeliveryFee)
      // }
      if(parseFloat(this.devolutionValue) > (parseFloat(this.currentOrderValue) - parseFloat(this.currentDeliveryFee)) || this.devolutionValue < 0){
        this.devolutionValueState = false
      }else{
        this.devolutionValueState = null
      }
    },
    changeTxDeliverValue(){
      // if(this.txDeliverValue < 0){
      //   this.txDeliverValue = 0
      // }
      // if(this.txDeliverValue > this.currentDeliveryFee){
      //   this.txDeliverValue = this.currentDeliveryFee
      // }
      if(parseFloat(this.txDeliverValue) > parseFloat(this.currentDeliveryFee) || this.txDeliverValue < 0){
        this.txDeliverValueState = false
      }else{
        this.txDeliverValueState = null
      }
    },
    clienteTabClicked(email, phone){
      if(this.currentNumPedidos == ''){
        this.getNumPedidos(email, phone)
      }
      if(this.avgOrderValue == ''){
        this.getAvgOrderValue(email, phone)
      }
      if(this.complaintCliente == ''){
        this.getComplaintsByCliente(email, phone)
      }
      if(this.alertCliente == ''){
        this.getAlertsByCliente(phone)
      }
      if(this.smsMessagesCliente == ''){
        this.getSMSMessagesByCliente(phone)
      }
    },
    getAllActiveSuppliers(){
      RestauranteAssignedToZoneDataService.GetAllActiveSuppliers({}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.suppliers=response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getNumPedidos(email, phone){
      PurchaseOrderDataService.CountPedidosByCliente({email, phone},{ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => (this.currentNumPedidos = response.data))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getAvgOrderValue(email, phone){
      PurchaseOrderDataService.AvgOrderValue({email, phone},{ headers: {Authorization: 'Bearer ' + localStorage.getItem('user-token')}})
        .then(response => (this.avgOrderValue = response.data))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    openPedidosByCliente(email, phone){
      this.showComplaintCliente = false
      this.showAlertCliente = false
      this.showSMSMessagesCliente = false
      this.showOrdersClientAddressInfo = false
      if(!this.showPedidosCliente && this.pedidosCliente == ''){
        this.getPedidosByCliente(email, phone)
      }else{
        this.showPedidosCliente = !this.showPedidosCliente
      }
    },
    getPedidosByCliente(email, phone){
      PurchaseOrdersOCDataService.GetPedidosByCliente({phone, pageNumber: 1, pageSize: 20, sortOrder: 'estafeta_horaRest', sortDesc: false},{ Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.items=response.data.result.items, this.formatData('pedidosCliente'), this.showPedidosCliente = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    openOrdersClientAddressInfo(phone, lat, lon){
      this.showComplaintCliente = false
      this.showAlertCliente = false
      this.showSMSMessagesCliente = false
      this.showPedidosCliente = false
      if(!this.showOrdersClientAddressInfo && this.ordersClientAddressInfo == ''){
        this.getOrdersClientAddressInfo(phone, lat, lon)
      }else{
        this.showOrdersClientAddressInfo = !this.showOrdersClientAddressInfo
      }
    },
    getOrdersClientAddressInfo(phone, lat, lon){
      PurchaseOrdersOCDataService.GetOrdersClientAddressInfo({ phone, lat, lon},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.ordersClientAddressInfo = response.data.result, this.showOrdersClientAddressInfo = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatComplaintData(complaints){
      var newComplaints = []
      complaints.forEach(element => {
        var date = new Date(element.complaintDateTime)
        var hour = String(date.getHours()).padStart(2, '0');
        var min = String(date.getMinutes()).padStart(2, '0');
        var day = String(date.getDate()).padStart(2, '0');
        var mon = String(date.getMonth()+1).padStart(2, '0');
        var year = String(date.getFullYear());
        date = day + "/" + mon + "/" + year + " " + hour + ":" + min;
        newComplaints.push({
          element: element,
          id: element.id,
          newDelivery: element.newDelivery? "Sim":"Não",
          withPartnerDevolution: element.withPartnerDevolution? "Sim":"Não",
          sameDA: element.sameDA? "Sim":"Não",
          obsDA: element.obsDA,
          devolution: element.devolution? "Sim":"Não",
          register: element.register? "Sim":"Não",
          devolutionValue: element.devolutionValue > 0 ? this.round(element.devolutionValue) + "€" : "",
          devolutionTxDelivery: element.devolutionTxDelivery? "Sim":"Não",
          txDeliveryValue: element.devolutionTxDelivery? this.round(element.txDeliveryValue) : "",
          devolutionType: element.devolutionType == 'OnlineRefund'? 'Devolvido Online' : element.devolutionType == 'Credit'? 'Em Crédito' : element.devolutionType == 'CreditSupplier'? 'Crédito Neste Restaurante' : element.devolutionType,
          responsability: element.responsability == 'Supplier'? 'Restaurante' : element.responsability,
          poNum: element.poNum,
          totalCredit: element.totalCredit > 0 ? this.round(element.totalCredit) + "€" : "",
          creditAvailable: element.creditAvailable > 0 ? this.round(element.creditAvailable) + "€" + ( new Date(new Date(element.complaintDateTime).setMonth(new Date(element.complaintDateTime).getMonth()+3)) < new Date() ? " - Expirado" : "") : "",
          observations: element.observations,
          complaintDateTime: date,
          customerPhone: element.customerPhone,
          customerName: element.customerName,
          supplierId: element.supplierId,
          supplierName: element.supplierName,
          isReplica: element.isReplica? "Sim":"Não",
          _showDetails: true
        })
      })
      return newComplaints
    },
    openComplaintsByCliente(email, phone){
      this.showPedidosCliente = false
      this.showAlertCliente = false
      this.showSMSMessagesCliente = false
      this.showOrdersClientAddressInfo = false
      if(!this.showComplaintCliente && this.complaintCliente == ''){
        this.getComplaintsByCliente(email, phone)
        this.showComplaintCliente = true
      }else{
        this.showComplaintCliente = !this.showComplaintCliente
      }
    },
    getComplaintsByCliente(email, phone){
      ComplaintDataService.GetByClient({clientPhone: phone}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.complaintCliente=this.formatComplaintData(response.data.result)))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    formatCreditComplaintData(creditComplaints){
      var newCreditComplaints = []
      creditComplaints.forEach(element => {
        var date = new Date(element.creditComplaintDateTime)
        var hour = String(date.getHours()).padStart(2, '0');
        var min = String(date.getMinutes()).padStart(2, '0');
        var day = String(date.getDate()).padStart(2, '0');
        var mon = String(date.getMonth()+1).padStart(2, '0');
        var year = String(date.getFullYear());
        date = day + "/" + mon + "/" + year + " " + hour + ":" + min;
        newCreditComplaints.push({
          id: element.id,
          creditUsed: this.round(element.creditUsed) + "€",
          complaintId: element.complaintId,
          poNum: element.poNum,
          creditComplaintDateTime: date,
          state: element.cancelled? "Reposto" : "Executado"
        })
      })
      return newCreditComplaints
    },
    formatOnlinePaymentsData(onlinePayments){
      var newOnlinePayments = []
      onlinePayments.forEach(element => {
        var date = new Date(element.transactionDate)
        var hour = String(date.getHours()).padStart(2, '0');
        var min = String(date.getMinutes()).padStart(2, '0');
        var day = String(date.getDate()).padStart(2, '0');
        var mon = String(date.getMonth()+1).padStart(2, '0');
        var year = String(date.getFullYear());
        date = day + "/" + mon + "/" + year + " " + hour + ":" + min;
        newOnlinePayments.push({
          transactionDate: date,
          paymentType: element.paymentType,
          virtualAccountId: element.virtualAccountId,
          transactionType: element.transactionType == "DB"? "Pagamento" : "Devolução",
          value: this.round(element.value) + "€",
          availableValue: this.round(element.availableValue) + "€",
          status: element.status == "Success"? "Pago" : ( element.status == "Pending"? "Pendente" : "Cancelado"),
          source: element.source
        })
      })
      return newOnlinePayments
    },
    getCreditAvailableByCliente(phone, supplier){
      ComplaintDataService.GetCreditAvailableByClient({clientPhone: phone, supplierId: supplier}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.currentCreditAvailable=response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getPaymentsValueByPO(orderId){
      TransactionDataService.GetPaymentsValueByPO({orderId: orderId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.onlinePaymentsValue=response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getReceipt(poNum){
      this.show = true
      LogAcaoDataService.GetReceipt({poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.image = '', this.image = 'data:image/jpeg;base64,'.concat(this.image.concat(response.data.result)),this.show = false))
        .catch(error => this.show = false)
        .finally()
    },
    postCreditActivated(clientPhone, supplierId, creditUsed, poNum){
      ComplaintDataService.ActivateCredit({clientPhone, supplierId, creditUsed, poNum},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Crédito Registado!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
          this.$fire({
            title: "Erro",
            text: "Ocorreu um erro, verifique os dados! Verifique",
            type: "error",
            timer: 3000
          })
        })
        .finally(() => this.errored = false)
    },
    getCreditUsedByComplaint(complaintId){
      CreditComplaintDataService.GetByComplaint({complaintId: complaintId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.creditUsed=this.formatCreditComplaintData(response.data.result), this.isBusyCreditUsed=false, this.creditRetrieved = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getOnlinePayments(orderId){
      TransactionDataService.GetAllTransactionsByOrderId({orderId: orderId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.onlinePayments=this.formatOnlinePaymentsData(response.data.result), this.isBusyOnlinePayments=false, this.paymentsRetrieved = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getExtrato(poNum){
      ExtratoDataService.GetExtratoByPoNum({poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.extrato=response.data.result, this.isBusyExtrato=false, this.extratoRetrieved = true))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getPhonesByPayments(customerPhone){
      TransactionDataService.GetPhonesByPayments({customerPhone: customerPhone}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.phonesPayments=response.data.result, this.phonesPayments.includes(customerPhone)? null: this.phonesPayments.push(customerPhone)))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    existsDevolutionByPoNum(poNum){
      ComplaintDataService.ExistsDevolutionByPoNum({poNum},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.existsDevolution = response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.errored = false)
    },
    existsCallByPoNum(poNum){
      CallDataService.ExistsCallByPoNum({poNum},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (response.data.result != null? this.formatCallHour(response.data.result): ''))
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.errored = false)
    },
    formatCallHour(date){
      var today = new Date()
      var callDate = new Date(date)
      if(new Date(callDate.getTime() + 60000) > new Date(today)){
        this.existsCall = 'Chamada a decorrer'
      }else{
        this.existsCall = 'Última Chamada às ' + this.getHoursAndSecondsFormated(date)
      }
    },
    sumAssignmentDistances(poNum){
      LogAcaoDataService.SumAssignmentDistances({poNum},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.sumDistances = this.round(response.data.result)))
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.errored = false)
    },
    getDeliveryFeeFromLogs(poNum){
      LogAcaoDataService.GetDeliveryFeeFromLogs({poNum},{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (response.data.result >= 0? this.currentDeliveryFee = response.data.result : ''))
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.errored = false)
    },
    postAlert(po, clientSupplier, recurrent, description){
      var today = new Date();
      var date = today
      var hour = String(date.getHours()).padStart(2, '0');
      var min = String(date.getMinutes()).padStart(2, '0');
      var day = String(date.getDate()).padStart(2, '0');
      var mon = String(date.getMonth()+1).padStart(2, '0');
      var year = String(date.getFullYear());
      date = day + "/" + mon + "/" + year + " " + hour + ":" + min;
      var alert = {
        alertTypeId: clientSupplier? 2 : 1,
        reason: "<strong>(" + this.currentUser.userName + ") - " + date + ":</strong> " +description + "\n",
        recurrent: !recurrent,
        alertDate: today,
        createdById: this.currentUser.id,
        poNum: po.id,
        customerPhone: po.element.customerPhone,
        customerName: po.deliveryCustomerName,
        supplierId: clientSupplier? po.element.supplierId : null,
        supplierName: clientSupplier? po.element.supplierName : null,
        supplierZoneId: null,
        state: "Open"
      }
      const poNum = po.id;
      const previous = ""
      const parameters = ""
      var logAcao = {
        acaoId: 24,
        userId: this.currentUser.id,
        previous: previous,
        parameters: parameters,
        poNum: poNum,
        logDateTime: today,
        observations: description,
        daId: '',
        customerPhone: po.element.customerPhone,
        customerName: po.element.deliveryCustomerName,
        daUserName: '',
        supplierId: po.element.supplierId,
        supplierName: po.supplierName
      }
      LogAcaoDataService.LogAcaoNewAlert(logAcao, alert,{Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Alerta Registado!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.getAlertsByCliente(po.element.customerPhone)
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    formatAlertData(alerts){
      var newAlerts = []
      alerts.forEach(element => {
        var date = new Date(element.alertDate)
        var hour = String(date.getHours()).padStart(2, '0');
        var min = String(date.getMinutes()).padStart(2, '0');
        var day = String(date.getDate()).padStart(2, '0');
        var mon = String(date.getMonth()+1).padStart(2, '0');
        var year = String(date.getFullYear());
        date = day + "/" + mon + "/" + year + " " + hour + ":" + min;
        var completedDate = ''
        if(element.completedDate != null)
        {
          completedDate = new Date(element.completedDate)
          var hour1 = String(completedDate.getHours()).padStart(2, '0');
          var min1 = String(completedDate.getMinutes()).padStart(2, '0');
          var day1 = String(completedDate.getDate()).padStart(2, '0');
          var mon1 = String(completedDate.getMonth()+1).padStart(2, '0');
          var year1 = String(completedDate.getFullYear());
          completedDate = day1 + "/" + mon1 + "/" + year1 + " " + hour1 + ":" + min1;
        }
        newAlerts.push({
          id: element.id,
          alertTypeId: element.alertTypeId,
          alertType: element.alertType != null? element.alertType.alertName : '',
          recurrent: element.recurrent? 'Sim': 'Não',
          reason: element.reason,
          poNum: element.poNum,
          alertDate: date,
          customerPhone: element.customerPhone,
          customerName: element.customerName,
          supplierId: element.supplierId,
          supplierName: element.supplierName,
          zone: element.supplierZone != null? element.supplierZone.name : '',
          completedDate: completedDate,
          createdBy: element.createdBy != null? element.createdBy.userName: '',
          completedBy: element.completedBy != null? element.completedBy.userName: '',
          state: element.state,
          workingUserId: element.workingUserId,
          workingUser: element.workingUser != null? element.workingUser.userName: '',
          alertedPoNum: element.alertedPoNum,
          delay: element.delay == 0? '': element.delay + " min"
        })
      })
      return newAlerts
    },
    postAlertDelete(id, po){
      AlertDataService.DeleteAlert({Id: id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Alerta Eliminado!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.getAlertsByCliente(po.element.customerPhone), this.$root.$emit('bv::hide::modal', this.alertDescriptionModal.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    postAlertEdit(alertId, clientSupplier, recurrent, description, customerPhone){
      var today = new Date();
      var date = today
      var hour = String(date.getHours()).padStart(2, '0');
      var min = String(date.getMinutes()).padStart(2, '0');
      var day = String(date.getDate()).padStart(2, '0');
      var mon = String(date.getMonth()+1).padStart(2, '0');
      var year = String(date.getFullYear());
      date = day + "/" + mon + "/" + year + " " + hour + ":" + min;
      var alert = {
        id: alertId,
        alertTypeId: clientSupplier? 2 : 1,
        reason: "<strong>(" + this.currentUser.userName + ") - " + date + ":</strong> " +description + "\n",
        recurrent: !recurrent
      }
      AlertDataService.EditAlert(alert, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Alerta Editado!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.getAlertsByCliente(customerPhone), this.$root.$emit('bv::hide::modal', this.alertDescriptionModal.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    postComplaintDelete(complaint){
      ComplaintDataService.DeleteComplaint({Id: complaint.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Reclamação Eliminada!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          }), this.getComplaintsByCliente(complaint.customerPhone), this.$root.$emit('bv::hide::modal', this.creditUsedModal.id, '')
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    postComplaintEdit(id){
      var complaint = {
        id: id,
        newDelivery: this.newDeliver,
        withPartnerDevolution: this.withPartnerDevolution,
        sameDA: this.sameDA,
        obsDA: this.newDeliver? this.reclamacao : '',
        devolution: this.devolution == 'Devolution'? true : false,
        register: this.devolution == 'Devolution'? false : true,
        devolutionValue: this.devolution == 'Devolution'? this.devolutionValue : 0,
        devolutionTxDelivery: this.newDeliver ? true : (this.devolution == 'Devolution'? this.txDeliver : false),
        txDeliveryValue: ((this.devolution == 'Devolution' && this.txDeliver) || this.newDeliver)? this.txDeliverValue : 0,
        devolutionType: this.devolution == 'Devolution'? this.devolutionType : null,
        responsability: (this.newDeliver || this.txDeliver )? this.responsability : null,
        totalCredit:  this.devolutionType == "OnlineRefund"? 0 :(Math.round(((parseFloat(this.devolution == 'Devolution'? this.devolutionValue : 0) + parseFloat((this.devolution == 'Devolution' && this.txDeliver )? this.txDeliverValue : 0)) + Number.EPSILON) * 100) / 100) ,
        creditAvailable:  this.devolutionType == "OnlineRefund"? 0 :(Math.round(((parseFloat(this.devolution == 'Devolution'? this.devolutionValue : 0) + parseFloat((this.devolution == 'Devolution' && this.txDeliver )? this.txDeliverValue : 0)) + Number.EPSILON) * 100) / 100) ,
        observations: this.observation,
        isReplica: this.isReplica
      }

    },
    postAddressWrongCoords(po){
      LogAcaoDataService.AddressWrongCoords({poNum: po.element.poNum, orderId: po.element.orderId, userId: this.currentUser.id}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.$notifications.notify(
          {
            message: `<span>Aviso de coordenadas erradas registado!</span>`,
            icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success'
          })
        ))
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.errored = false)
    },
    openAlertsByCliente(phone){
      this.showPedidosCliente = false
      this.showComplaintCliente = false
      this.showSMSMessagesCliente = false
      this.showOrdersClientAddressInfo = false
      if(!this.showAlertCliente && this.alertCliente == ''){
        this.getAlertsByCliente(phone)
        this.showAlertCliente = true
      }else{
        this.showAlertCliente = !this.showAlertCliente
      }
    },
    getAlertsByCliente(phone){
      AlertDataService.GetAllByClient({customerPhone: phone}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.alertCliente=this.formatAlertData(response.data.result)))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    openSMSMessagesByCliente(phone){
      this.showPedidosCliente = false
      this.showComplaintCliente = false
      this.showAlertCliente = false
      this.showSMSMessagesCliente = false
      this.showOrdersClientAddressInfo = false
      if(!this.showSMSMessagesCliente && this.smsMessagesCliente == ''){
        this.getSMSMessagesByCliente(phone)
        this.showSMSMessagesCliente = true
      }else{
        this.showSMSMessagesCliente = !this.showSMSMessagesCliente
      }
    },
    getSMSMessagesByCliente(phone){
      LogAcaoDataService.GetSMSMessagesByCliente({phone: phone}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.smsMessagesCliente=response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getVoucherByPO(poNum){
      DiscountDataService.GetVoucherByPO({poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.voucher=response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },
    getVoucherIdByPO(poNum){
      DiscountDataService.GetVoucherIdByPO({poNum: poNum}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.voucherId=response.data.result))
        .catch(error => {
          console.log(error)
        })
        .finally()
    },

    otherPhonesClicked(supplierId){
      if(this.otherPhones == ''){
        this.getPartnerContacts(supplierId)
      }
    },
    getPartnerContacts(supplierId){
      this.showOtherPhones = false
      StoreFrontDataService.GetPartnerContacts({supplierId: supplierId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (this.otherPhones=response.data.result, this.showOtherPhones = true))
        .catch(error => {
          console.log(error)
          this.showOtherPhones = true
        })
        .finally()
    },

    sendAutoCallToPartner(supplierId){
      this.doingCall = true
      CallDataService.SendAutoCallToPartner({supplierId: supplierId}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (setTimeout(() => {this.doingCall = false}, 60000)))
        .catch(error => {
          console.log(error)
          this.doingCall = false
        })
        .finally()
    },
    sendAutoCallToPartnerPhone(supplierId, phone){
      this.doingCall = true
      CallDataService.SendAutoCallToPartnerPhone({supplierId: supplierId, phone: phone}, {Authorization: 'Bearer ' + localStorage.getItem('user-token')})
        .then(response => (setTimeout(() => {this.doingCall = false}, 60000)))
        .catch(error => {
          console.log(error)
          this.doingCall = false
        })
        .finally()
    },

    chooseMBWAYPhone(phone){
      this.telMB = phone
    },
    smsTypeChanged(value, po){
      switch(value){
        case "Pedido Atrasado":
          this.smsToClientText = 'Ola Sr(a) '+po.element.deliveryCustomerName+', tentamos entrar em contacto mas sem sucesso. Informamos que o seu pedido sofreu um atraso, e estara na morada indicada dentro de minutos. Pedimos desculpa pelo sucedido. Obrigada. Para + info: 221451010'
          break;
        case "Pedido saiu do Restaurante":
          this.smsToClientText = 'Ola Sr(a) '+po.element.deliveryCustomerName+', informamos que o seu pedido acabou de sair do '+po.element.supplierName+'. Obrigada. Para + info: 221451010'
          break;
        case "Informacao da entrega":
          this.getClientDistanceInfo(po)
          break;
        case "Tentativa de Contacto":
          this.smsToClientText = 'Ola Sr(a) '+po.element.deliveryCustomerName+', o seu pedido do '+po.element.supplierName+' esta suspenso, pois carece de alteracoes. Tentamos entrar em contacto mas sem sucesso. Agradecemos que logo que possivel ligue para o n. 221451010. Obrigada.'
          break;
        default:
          this.smsToClientText = ''
          break;
      }
    },
    telPartnerSelectBoxChanged(value){
      this.telPartner = this.telPartnerSelectBox
      if(this.telPartner != ''){
        if(this.telPartner.startsWith('2') || this.telPartner.startsWith('+3512') || this.telPartner.startsWith('3512')) {
          this.telState = false
          return false
        }
        this.telState = true

        return true
      }else{
        this.telState = null
      }
    },
    verifyTel(){
      if(this.telClient != '' || this.telPartner != ''){
        if(this.telClient.startsWith('2') || this.telClient.startsWith('+3512') || this.telClient.startsWith('3512') || this.telPartner.startsWith('2') || this.telPartner.startsWith('+3512') || this.telPartner.startsWith('3512')) {
          this.telState = false
          return false
        }
        this.telState = true

        return true
      }else{
        this.telState = null
      }
    },

    round(value) {
      return Number(Math.round(value+'e'+2)+'e-'+2).toFixed(2)
    },
    getHoursAndSecondsFormated(date){
      var dt = new Date(date)
      var str = dt.getHours().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + ":" +
                dt.getMinutes().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + ":" +
                dt.getSeconds().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
      return str
    },
    getDateFormated(date){
      var dt = new Date(date)
      var str = dt.getDate().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" +
                (dt.getMonth()+1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" + dt.getFullYear()
      return str
    },
    getDateWithHoursAndSecondsFormated(date){
      var dt = new Date(date)
      var str = dt.getDate().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" +
                (dt.getMonth()+1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + "/" + dt.getFullYear() + " " +
                dt.getHours().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + ":" +
                dt.getMinutes().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                }) + ":" +
                dt.getSeconds().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
      return str
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.lineType === 'Pagamento') return 'text-green'
      else if (item.lineType === 'Voucher' || item.lineType === 'Credito' || item.lineType === 'Devolução') return 'text-red'
    }

  },


};
</script>
<style>

</style>
